/**
 * 模拟考试
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/exam/exam.module.scss';
import {
  IExamStoreState,
  getExamList,
  getPractiseList,
  examStore,
  IExamItem,
  getPractiseTopicList,
  getExamTopicList
} from '../../redux/exam.reducer';
import Navigate from '../../components/common/navigate/navigate';
import { Toast } from 'antd-mobile';

export interface IExamProps {
  history: any,
  exam: IExamStoreState,
  getExamList: any,
  getPractiseList: any,
  getPractiseTopicList: any,
  getExamTopicList: any,
  examStore: IExamStoreState
}

export interface IExamState {
  examTabs: IExamItem[],
  practiseTabs: IExamItem[],
  examListFromCurrTabs: any[],
  practiseListFromCurrTabs: any[],
  currSelectedExamIdx: number
  currSelectedPractiseIdx: number
}

class Exam extends React.Component<IExamProps, IExamState> {
  constructor(props: IExamProps) {
    super(props);

    this.state = {
      examTabs: [],
      currSelectedExamIdx: 0,
      examListFromCurrTabs: [],
      currSelectedPractiseIdx: 0,
      practiseTabs: [],
      practiseListFromCurrTabs: [],
    }
  }

  static getDerivedStateFromProps(props: IExamProps, state: IExamState) {
    let exam = props.exam;
    const examList = exam.examList || [];
    const practiseList = exam.practiseList || [];
    let examListFromCurrTabs = [];
    if (examList.length && examList.length >= state.currSelectedExamIdx + 1) {
      examListFromCurrTabs = examList[state.currSelectedExamIdx].children || [];
    }

    let practiseListFromCurrTabs = [];
    if (practiseList.length && practiseList.length >= state.currSelectedPractiseIdx + 1) {
      practiseListFromCurrTabs = practiseList[state.currSelectedPractiseIdx].children || [];
    }

    // let practiseListFromCurrTabs = practiseList[state.currSelectedPractiseIdx].children||[]
    return {
      examTabs: examList,
      practiseTabs: practiseList,
      examListFromCurrTabs,
      practiseListFromCurrTabs
    }
  }

  componentDidMount() {
    this.props.getExamList().then((res: any) => {

    })
    this.props.getPractiseList()
  }

  /**
   * 点击模拟考试列表tabs
   * @param index 当前选中的tabItem在数组中的下标
   */
  handleClickExamTab(index: number) {
    if (!sessionStorage._t) {
      this.props.history.push('/n');
      return;
    }

    let examTabs = this.state.examTabs || []
    let examListFromCurrTabs = [];
    if (examTabs.length && examTabs.length >= index + 1) {
      console.log(examTabs)
      examListFromCurrTabs = examTabs[index].children || [];
    }
    this.setState({
      currSelectedExamIdx: index,
      examListFromCurrTabs
    })
  }

  /**
  * 点击练习列表tabs
  * @param index 当前选中的tabItem在数组中的下标
  */
  handleClickPractiseTab(index: number) {

    let practiseTabs = this.state.practiseTabs || []
    let practiseListFromCurrTabs = [];
    if (practiseTabs.length && practiseTabs.length >= index + 1) {
      practiseListFromCurrTabs = practiseTabs[index].children || [];
    }

    this.setState({
      currSelectedPractiseIdx: index,
      practiseListFromCurrTabs
    })
  }

  /**
   * 点击快速练习 跳转到练习测试题
   * 快速练习不用验证登录状态
   */
  handleClickPractiseItem(practiseId: string) {
    // if (!sessionStorage._t) {
    //   this.props.history.push('/n');
    //   return;
    // }

    this.props.getPractiseTopicList(practiseId).then((res: any) => {
      if (res.success) {
        this.props.history.push(`/exam/practise_details/${practiseId}`);
      } else {
        Toast.info(res.message);
      }
    }).catch(()=>{
      Toast.fail("获取试题失败")
    });
  }

  /**
   * 点击考试item 跳转到考试详情
   */
  handleClickExamItem(examId: string) {
    if (!sessionStorage._t) {
      this.props.history.push('/n');
      return;
    }

    this.props.getExamTopicList(examId).then((res: any) => {
      if (res.success) {
        this.props.history.push(`/exam/exam_details/${examId}`);
      } else {
        Toast.info(res.message);
      }
    }).catch(()=>{
      Toast.fail("获取试题失败")
    });
  }

  public render() {
    const { exam } = this.props;
    const examTabs = this.state.examTabs || [];
    const practiseTabs = this.state.practiseTabs || [];
    // const examList = exam.examList || [];
    // const practiseList = exam.practiseList || [];
    const currSelectedExamIdx = this.state.currSelectedExamIdx
    const currSelectedPractiseIdx = this.state.currSelectedPractiseIdx
    const examListFromCurrTabs = this.state.examListFromCurrTabs || []
    const practiseListFromCurrTabs = this.state.practiseListFromCurrTabs || []
    return (
      <div className={styles.container}>
        <header>
          <h1>例题展示</h1>
          <h2>模擬試験</h2>
        </header>
        <div className={styles.practise}>
          <h1>快速练习</h1>
          <div className={styles.explain}>
            <p>说明：</p>
            <pre>将题目分类,仔细推敲各个题型其解题的方法和技巧，把问题吃透，强化优势，弥补劣势。夯实基础，系统知识，明确重点，突破难点。</pre>
          </div>


          <div className={styles.exam_type}>
            {
              practiseTabs.map((item: IExamItem, index: number) => {
                return <button
                  key={item.id}
                  onClick={this.handleClickPractiseTab.bind(this, index)}
                  className={currSelectedPractiseIdx === index ? styles.active : ""}
                >{item.name}</button>
              })
            }
          </div>
          <ul>
            {
              practiseListFromCurrTabs.map((item: IExamItem, index: number) => {
                return <li
                  key={item.id}
                  onClick={this.handleClickPractiseItem.bind(this, item.id)}
                >
                  <span>{item.name}</span>
                  <span>>></span>
                </li>
              })
            }
          </ul>


          {/* <div className={styles.exam_type}>
            {
              examTabs.map((item: IExamItem, index: number) => {
                return <button
                  key={item.id}
                  onClick={this.handleClickExamTab.bind(this, index)}
                  className={currSelectedExamIdx === index ? styles.active : ""}
                >{item.name}</button>
              })
            }
          </div>
          <ul>
            {
              examListFromCurrTabs.map((item: IExamItem, index: number) => {
                return <li
                  key={item.id}
                  onClick={this.handleClickExamItem.bind(this, item.id)}
                >
                  <span>{item.name}</span>
                  <span>>></span>
                </li>
              })
            }
          </ul> */}
        </div>
        <div className={styles.exam}>
          <h1>例题展示</h1>
          <div className={styles.explain}>
            <p>说明：</p>
            <pre>{`提高对考试期间时间的掌握，合理安排时间。
将已掌握的和未掌握的相结合
整体检验复习情况；对知识的综合应用情况进行训练；试卷的结构、题型也应该有了较深层次地把握。`}</pre>
          </div>

          <div className={styles.exam_type}>
            {
              examTabs.map((item: IExamItem, index: number) => {
                return <button
                  key={item.id}
                  onClick={this.handleClickExamTab.bind(this, index)}
                  // onClick={this.handleClickExamItem.bind(this, item.id)}
                  className={currSelectedExamIdx === index ? styles.active : ""}
                >{item.name}</button>
              })
            }
          </div>
          <ul>
            {
              // 2020年01月05日22:36:41 需求修改 弃用二级菜单
              // 2020年01月06日23:48:19 需求修改 重新使用二级菜单
              examListFromCurrTabs.map((item: IExamItem, index: number) => {
                return <li
                  key={item.id}
                  onClick={this.handleClickExamItem.bind(this, item.id)}
                >
                  <span>{item.name}</span>
                  <span>>></span>
                </li>
              })
            }
          </ul>

          {/* <div className={styles.exam_type}>
            {
              practiseTabs.map((item: IExamItem, index: number) => {
                return <button
                  key={item.id}
                  onClick={this.handleClickPractiseTab.bind(this, index)}
                  className={currSelectedPractiseIdx === index ? styles.active : ""}
                >{item.name}</button>
              })
            }
          </div>
          <ul>
            {
              practiseListFromCurrTabs.map((item: IExamItem, index: number) => {
                return <li
                  key={item.id}
                  onClick={this.handleClickPractiseItem.bind(this, item.id)}
                >
                  <span>{item.name}</span>
                  <span>>></span>
                </li>
              })
            }
          </ul> */}
        </div>
        <Navigate></Navigate>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    exam: state.examStore,
  }
}

const mapDispatchToProps = {
  getExamList,
  getPractiseList,
  getPractiseTopicList,
  getExamTopicList
}

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
