/**
 * 课程介绍
 */
import * as React from 'react';
import { Tabs, Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import $ from './../../util/requst';
import styles from './../../styles/course/course_intro.module.scss';
import 'video.js/dist/video-js.css';
import { ICourseItem, getCourseDetails, ICourseStoreState } from '../../redux/course.reducer';
import { ITeacherItem } from '../../redux/teachers.reducer';
import Navigate from '../../components/common/navigate/navigate';
import videojs from 'video.js'


const COURSE_COLOR_LIST = ["#7059E6", "#FFCC00", "#00CC00", "#669933", "#00BBCC", "#EE55F8"]

export interface ICourseIntroProps {
  history: any,
  match: any,
  getCourseDetails: any
  course: ICourseStoreState
}

export interface ICourseIntroState extends ICourseItem {
  isFullScreen: boolean
}

class CourseIntro extends React.Component<ICourseIntroProps, ICourseIntroState> {
  private courseId: string;
  private videoPlayer: any;
  private videoPlayerNode: any;
  constructor(props: ICourseIntroProps) {
    super(props);
    this.courseId = props.match.params.courseId;
    this.state = {
      courseDis: "",
      courseType1: "",
      courseType2: "",
      coverImageUrl: "",
      id: "",
      overview: "",
      price: "",
      program: "",
      status: 0,
      teacherIds: [],
      timeTypes: "",
      title: "",
      totalLength: "",
      videoCourseDis: [],
      videoCourseTeacher: [],
      videoCourseTimeTypes: [],
      videoTeachers: [],
      buyState: 1, //  是否已经购买 0 未购买 1 已购买
      isFullScreen: false
    }
  }

  componentDidMount() {
    Toast.loading('加载中...', 0, () => {
      console.log('Load complete !!!');
    });

    this.props.getCourseDetails(this.courseId).finally(() => {
      Toast.hide();
    })

    if (!sessionStorage._t) {
      this.setState({ buyState: 0 });
      return;
    }

    // 检查是否已经购买过该课程， 判断是否显示购买按钮
    this.checkPayment().then((res: any) => {
      if (res.success) {
        this.setState({ buyState: 0 });
      }
    }).catch((err: any) => {
      let strErr = err + "";
      if (strErr.indexOf("403") !== -1) {
        this.setState({ buyState: 0 })
      }
    });
  }

  componentWillUnmount() {
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
  }

  /**
   * 检查是否能够购买该课程
   */
  checkPayment() {
    return $.post({
      url: '/back_videoCourse/state',
      data: {
        id: this.courseId
      }
    })
  }

  /**
   * 点击跳转到购买页面
   */
  jumpToPayment() {
    if (!sessionStorage.getItem('_t')) {
      this.props.history.push(`/n`)
      return;
    }

    this.checkPayment().then((res: any) => {
      if (res.success) {
        this.props.history.push(`/course/payment/${this.courseId}`)
      } else {
        Toast.info(res.message);
      }
    }).catch((err: any) => {
      let strErr = err + "";
      if (strErr.indexOf("403") !== -1) {
        Toast.info("登录信息是失效，请重新登录");
        this.props.history.push("/n");
        sessionStorage.clear();
        return;
      }
    })
  }

  /**
   * 点击播放按钮，若播放第一条视频，则不验证用户是否购买，
   * 否则验证用户是否购买，若已购买则跳转播放 否则不允许播放
   * @param src string url
   * @param index  number index
   */
  handleClickPlay(src: string, index: number) {
    if (!src) {
      Toast.info("当前节次暂未关联课程");
      return;
    }

    if (index === 0) {
      const encodeUrl = encodeURIComponent(src);
      this.props.history.push(`/course/course_details/${encodeUrl}`)
      return;
    }

    // 检查用户是否购买课程，若购买成功则跳转到对应页面
    this.checkPayment().then((res: any) => {
      // 已购买该课程
      if (!res.success) {
        const encodeUrl = encodeURIComponent(src);
        this.props.history.push(`/course/course_details/${encodeUrl}`)
      } else {
        Toast.info('未购买该课程!');
      }
    }).catch((err: any) => {
      let strErr = err + "";
      if (strErr.indexOf("403") !== -1) {
        Toast.info("登录信息是失效，请重新登录");
        this.props.history.push("/n");
        sessionStorage.clear();
        return;
      }
    })
  }


  public render() {
    const { courseDetails } = this.props.course
    let videoCourseDis = courseDetails.videoCourseDis || []
    let videoCourseTimeTypes = courseDetails.videoCourseTimeTypes || []
    let videoCourseTeacher = courseDetails.videoCourseTeacher || []
    return (
      <div className={styles.container}>
        <div className={styles.banner}>
          <img src={courseDetails.coverImageUrl} alt="banner" />
        </div>
        <div className={styles.courseInfo}>
          <div className={styles.title}>
            <h1>{courseDetails.title}</h1>
            <p>￥{courseDetails.price}</p>
          </div>
          <p className={styles.courseStatus}>
            <span>课程回数：（{courseDetails.totalLength}回）</span>
            <span>{courseDetails.status === 0 ? "更新中" : "已完结"}</span>
          </p>
          <div className={styles.courseOverview}>
            <p>课程概述：</p>
            <pre>{courseDetails.overview}</pre>
          </div>
          {
            this.state.buyState === 0
              ? <button onClick={this.jumpToPayment.bind(this)}>购买课程</button>
              : null
          }

        </div>
        <div className={styles.tabsContainer}>
          <Tabs
            tabBarInactiveTextColor={"#999"}
            initialPage={0}
            tabBarTextStyle={{
              fontSize: 14,
              fontWeight: 'bold',
            }}
            tabBarUnderlineStyle={{
              border: 0,
              height: 0
            }}
            tabs={[
              { title: <p>课程介绍</p> },
              { title: <p>课程表</p> },
              { title: <p>课程分配</p> }
            ]}
          >
            {/* 课程介绍 */}
            <div className={styles.courseIntro}>
              <div className={styles.courseOutline}>
                <h1>课程大纲</h1>
                <pre>{courseDetails.program}</pre>
              </div>
              <div className={styles.teacher}>
                <h1>课程讲师</h1>
                {
                  videoCourseTeacher.length
                    ?
                    <div className={styles.teacherList}>
                      {
                        videoCourseTeacher.map((item: ITeacherItem) => {
                          return (<div key={item.id} onClick={() =>{
                            this.props.history.push({
                              pathname:`/teachersDetail`,
                              state:{
                                item:item
                              }
                            })
                          }}>
                            <img src={item.headImageUrl} alt={item.nick} />
                            <div className={styles.content}>
                              <h2>{item.nick}</h2>
                              <p>{item.university}</p>
                            </div>
                          </div>)
                        })
                      }
                    </div>
                    : <p>暂无讲师介绍</p>
                }
              </div>
            </div>

            {/* 课程表 */}
            <div className={styles.timetable}>
              <h1>课程表</h1>
              <ul>
                {
                  videoCourseDis.map((item: any, index: number) => {
                    return <li key={item.id}>
                      <p className={styles.lesson}>第{index + 1}节</p>
                      <p className={styles.name}>{item.disName}</p>
                      <p
                        className={styles.icon}
                        style={{ display: item.videoUrl === null ? 'none' : 'inline-block' }}
                        onClick={this.handleClickPlay.bind(this, item.videoUrl, index)}
                      >
                        <i
                          className="iconfont iconplay"
                        // onClick={this.handleClickPlay.bind(this, item.videoUrl)}
                        ></i>
                        {/* <span>即将发布</span> */}
                      </p>
                    </li>
                  })
                }
              </ul>
              {/* <button>查看更多</button> */}
            </div>
            {/* 课程分配 */}
            <div className={styles.coursePlan}>
              <h1>课程时间分配</h1>
              <ul className={styles.planList}>
                {
                  videoCourseTimeTypes.map((item: any, index: number) => {
                    // const totalLength = parseInt(this.state.totalLength)
                    const totalLength = 50
                    const timeLength = parseInt(item.timeLength)
                    const progess = timeLength / totalLength
                    const n = index - (Math.ceil((index + 1) / 6) - 1) * 6;
                    const backgroundColor = COURSE_COLOR_LIST[n];
                    return <li
                      key={item.id}
                      className={styles.planItem}
                    >
                      <div className={styles.info}>
                        <p>{item.typeName}</p>
                        <p>{
                          // item.typeName
                        }</p>
                        <p>{item.timeLength}分钟</p>
                      </div>
                      <div className={styles.progressContainer}>
                        <div className={styles.progress} style={{ width: `${(function(){
                          let totalMinute = 0
                          videoCourseTimeTypes.map((it:any) => {
                            totalMinute = totalMinute + Number(it.timeLength)
                          })
                          let time = Number(item.timeLength)/totalMinute
                          return Number(time*100).toFixed()
                        }())}%`, backgroundColor }}></div>
                      </div>
                    </li>
                  })
                }
              </ul>
            </div>
          </Tabs>
        </div>
        <Navigate></Navigate>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    course: state.course
  }
}

const mapDispatchToProps = {
  getCourseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseIntro);
