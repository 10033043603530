/**
 * 网站导航组件
 */
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './navigate.module.scss';

interface INavigateProps extends RouteComponentProps {
  type?: 'default' | 'white',
  // history?:any
}

// type PropsType = RouteComponentProps<INavigateProps> & {

// }


const Navigate: React.FC<INavigateProps> = (props) => {
  const { type } = props;
  let className = type === 'white' ? 'white' : ''
  return (
    <div className={`${styles.container} ${styles[className]}`}>
      <div className={styles.title}>
        <h1>网站导航</h1>
        <h2>WEBSITE NAVIGATION</h2>
      </div>

      <ul className={styles.navLists}>
        <li onClick={() => props.history.push(`/`)}>首页</li>
        <li onClick={() => props.history.push(`/pass_rate`)}>学校推荐</li>
        <li onClick={() => props.history.push(`/teachers`)}>师资阵容</li>
        <li onClick={() => props.history.push('/trainees')}>合格学员</li>
        <li onClick={() => props.history.push('/exam')}>例题展示</li>
        <li onClick={() => props.history.push(`/course`)}>精品课程</li>
        <li onClick={() => props.history.push(`/contact`)}>联系我们</li>
        <li onClick={() => {
          if (!sessionStorage._t) {
            props.history.push(`/n`);
            return;
          }
          props.history.push(`/center`);
        }
        }>个人中心</li>
      </ul>
    </div>
  );
}

export default withRouter(Navigate);

