/**
 * 最新动态数据
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_NEWS_LIST = 'GET_ALL_NEWS_LIST';
type GET_ALL_NEWS_LIST = typeof GET_ALL_NEWS_LIST;

export interface INewsItem {
  dynamicContent: string
  dynamicImage: string
  id: string
  outLink: string
  releaseDate: any
  title: string
}

export interface INewsStoreState {
  newsList: INewsItem[]
}


const initState: INewsStoreState = {
  newsList: []
}

export function news(state = initState, action: any): INewsStoreState {
  switch (action.type) {
    case GET_ALL_NEWS_LIST:
      return { ...state, newsList: action.newsList }
  }
  return state;
}

/**
 * 获取全部优质学校列表
 */
export function getAllNewsList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_dynamic/getDynamic',
      data: {
        pager: 1,
        size: 100
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_NEWS_LIST, newsList: data.list || [] })
      }
    })
  }
}