/**
 * 我的模考
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/my_exam.module.scss';
import { Button } from 'antd-mobile';
import {
  getNormalExamList,
  saveExamData
} from './../../redux/mycenter.reducer';

export interface IMyExamProps {
  history: any,
  getNormalExamList: any
  saveExamData: any
}

export interface IMyExamState {
  examList: any[]
}
class MyExam extends React.Component<IMyExamProps, IMyExamState> {
  constructor(props: IMyExamProps) {
    super(props);

    this.state = {
      examList: []
    }
  }

  componentDidMount() {
    this.props.getNormalExamList().then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ examList: res.data.list });
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.myCourseContainer} myCourseContainer`}>
        <div className={styles.header}>
          <p>我的模考</p>
          <div className={styles.tableHeaderWrap}>
            <p className={styles.tableHeader}>模考时间</p>
            <p className={styles.tableHeader}>模考类型</p>
            <p className={styles.tableHeader}>模考得分</p>
          </div>
        </div>
        <ul className={styles.examListWrap}>
          {
            this.state.examList.map(item => {
              return (
                <li key={item.recordid} className={styles.examInfo} onClick={() => { this.props.history.push('/center/exam_result'); this.props.saveExamData(item) }}>
                  <p>{item.endtime}</p>
                  <p>{item.paperstype}</p>
                  <p>{item.score}</p>
                </li>
              )
            })
          }
        </ul>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn'>确定</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
  }
}

const mapDispatchToProps = {
  getNormalExamList,
  saveExamData
}
export default connect(mapStateToProps, mapDispatchToProps)(MyExam);