/**
 * 学院详情 学部详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/pass_rate/faculty.module.scss';
import { ISchool, ISchoolsStoreState } from '../../redux/school.reducer';

export interface IFacultyProps {
  history: any,
  match: any,
  schools: ISchoolsStoreState
}

export interface IFacultyState {
  deptName: string
  examContent: string
  examDate: string
  examMenu: any
  facultyType: number
  id: string
  qualifiedContent: string
  qualifiedDate: string
  teachers: any
  wishCondition: string
  wishDate: string
}

class Faculty extends React.Component<IFacultyProps, IFacultyState> {
  private schoolId: string;
  private facultyId: string;
  constructor(props: IFacultyProps) {
    super(props);
    console.log(props)
    this.schoolId = props.match.params.schoolId
    this.facultyId = props.match.params.facultyId
    this.state = {
      deptName: "",
      examContent: "",
      examDate: "",
      examMenu: "",
      facultyType: 1,
      id: "",
      qualifiedContent: "",
      qualifiedDate: "",
      teachers: [],
      wishCondition: "",
      wishDate: "",
    }
  }

  componentDidMount() {
    let schoolList = this.props.schools.schoolList;
    try {
      let school: ISchool = schoolList.filter((item: ISchool) => {
        return item.id == this.schoolId;
      })[0]

      if (school && school.collegeFaculty) {
        let faculty = school.collegeFaculty.filter((item: any, index: number) => {
          return item.facultyType === 1 && item.id == this.facultyId;
        })

        if (faculty.length) {
          faculty = faculty[0]
          this.setState({ ...faculty })
        } else {
          throw "没有查询到当前学部";
        }
      } else {
        throw "没有查询到当前学部";
      }

    } catch (error) {
      this.props.history.push(`/pass_rate`);
      return;
    }
  }

  public render() {
    return (
      <div className={styles.container}>
        <div className={styles.baseInfo}>
          <h1>{this.state.deptName}</h1>
          <p>
            <span>出愿时间：</span>
            <span>{this.state.wishDate}</span>
          </p>
          <p>
            <span>出愿条件：</span>
            <span>{this.state.wishCondition}</span>
          </p>
          <p>
            <span>考试时间：</span>
            <span>{this.state.examDate}</span>
          </p>
          <p>
            <span>合格发表：</span>
            <span>{this.state.qualifiedDate}</span>
          </p>
        </div>

        <div className={styles.exam}>
          <h2>考试内容</h2>
          <p>{this.state.examContent}</p>
        </div>
        <div className={styles.achievement}>
          <h2>往年合格实绩：</h2>
          <p>{this.state.qualifiedContent}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schools: state.schools
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Faculty);
