/**
 * 教师-账户安全
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button, Toast } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/t_account_safety.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import { teacherChangePwd } from './../../../redux/mycenter.reducer';

const Item: React.ElementType = List.Item;

export interface IT_AccountSafetyProps {
  history: any
  teacherChangePwd: any
}

export interface IT_AccountSafetyState {
  oldPassword: string;
  newPassword: string;
  newPasswordCheck: string;
}

class T_AccountSafety extends React.Component<IT_AccountSafetyProps, IT_AccountSafetyState> {
  constructor(props: IT_AccountSafetyProps) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordCheck: '',
    }
  }

  submitChangePwd = () => {
    if (this.state.newPassword !== this.state.newPasswordCheck) {
      Toast.info('两次密码输入不一致')
    } else {
      let data = {
        oldpassword: this.state.oldPassword,
        newpassword: this.state.newPasswordCheck
      };
      this.props.teacherChangePwd(data).then(
        (res: any) => {
          if (res.code === 200) {
            Toast.info('修改成功');
            this.props.history.push('/center');
          } else {
            Toast.info(res.message);
          }
        }
      )
    }
  }



  public render() {
    return (
      <div className={`${styles.nModifyPhoneContainer} tAccountSafety`}>
        <div className={styles.nickNameWrap}>
          <List>
            <Item><InputItem className={styles.captchaInput}
              type='password'
              value={this.state.oldPassword}
              onChange={(value) => { this.setState({ oldPassword: value }) }}
              placeholder='请输入旧密码'></InputItem></Item>
            <Item><InputItem className={styles.captchaInput}
              type='password'
              value={this.state.newPassword}
              onChange={(value) => { this.setState({ newPassword: value }) }}
              placeholder='请输入新密码'></InputItem></Item>
            <Item><InputItem className={styles.captchaInput}
              type='password'
              value={this.state.newPasswordCheck}
              onChange={(value) => { this.setState({ newPasswordCheck: value }) }}
              placeholder='请确认新密码'></InputItem></Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn'
            onClick={this.submitChangePwd}
          >确定</Button>
        </div>
        <Navigate type='white'></Navigate>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
  }
}

const mapDispatchToProps = {
  teacherChangePwd,
}

export default connect(mapStateToProps, mapDispatchToProps)(T_AccountSafety);

