/**
 * 全部老师数据
 */
import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_TEACHER_LIST = 'GET_ALL_TEACHER_LIST';
type GET_ALL_TEACHER_LIST = typeof GET_ALL_TEACHER_LIST;

export interface ITeacherItem {
  accountNonLocked: boolean
  courseListShowName: string
  coursechoose: any
  createTime: any
  experience: any
  head_image_url: string
  headImageUrl:string
  id: string
  indexComm: boolean
  name: string
  nick: string
  password: string
  phone: string
  says: string
  sex: number
  university: string
  username: string
  video_name: string
  video_url: string
  weixinCode: string
}

export interface ITeachersStoreState {
  teacherList: ITeacherItem[]
}


const initState: ITeachersStoreState = {
  teacherList: []
}

export function teachers(state = initState, action: any): ITeachersStoreState {
  switch (action.type) {
    case GET_ALL_TEACHER_LIST:
      return { ...state, teacherList: action.teacherList }
  }
  return state;
}

/**
 * 获取首页师资阵容列表
 */
export function getAllTeacherList(pager:any,size:any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacher/teacherList',
      data: {
        pager: pager,
        size: size,
        indexComm: 1
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_TEACHER_LIST, teacherList: data.list || [] })
      }
    })
  }
}
