/**
 * 支付页面
 */
import * as React from 'react';
import { connect } from 'react-redux';
import $ from './../../util/requst';
import { Checkbox, Toast } from 'antd-mobile';
import styles from './../../styles/course/payment.module.scss';
import { ICourseItem } from '../../redux/course.reducer';

export interface IPaymentProps {
  history: any
  match: any
}

// export interface IPaymentState {
// }
export interface IPaymentState extends ICourseItem {
  payType: number
}

class Payment extends React.Component<IPaymentProps, IPaymentState> {
  private courseId: string;
  constructor(props: IPaymentProps) {
    super(props);
    this.courseId = props.match.params.courseId;
    this.state = {
      payType: 0, // 默认0  微信支付  0 微信 1 支付宝
      courseDis: "",
      courseType1: "",
      courseType2: "",
      coverImageUrl: "",
      id: "",
      overview: "",
      price: "",
      program: "",
      status: 0,
      teacherIds: [],
      timeTypes: "",
      title: "",
      totalLength: "",
      videoCourseDis: [],
      videoCourseTeacher: [],
      videoCourseTimeTypes: [],
      videoTeachers: [],
      buyState:0
    }
  }
  componentDidMount() {
    this.getCourseDetails()
  }

  handlePay = () => {
    let { title, id, payType, overview, price } = this.state;
    if (payType === 0) {
      this.wxPay({
        out_trade_no: "",
        total_fee: Number(price),
        body: title,
        product_id: id,
        trade_type: "",
        openid: ""
      })
    } else {
      let alipayParams = {
        outTradeNo: '',
        // totalAmount: this.state.price,
        totalAmount: Number(price),
        subject: title,
        body: title,
        storeId: id,
        productCode: ''
      };

      this.aliPay(alipayParams)
    }
  }


  wxPay(data: any) {
    Toast.loading('请稍后~', 0, () => {
      console.log('Load complete !!!');
    });
    return $.post({
      url: '/wxpay/h5',
      data: data
    }).then((res: any) => {
      Toast.hide();
      if (typeof res === 'string') {
        document.write(res);
        return;
      }
      // document.write(res)
      if (res.success) {
        let data = res.data;
        if (data.result_code === 'SUCCESS') {
          window.location.href = data.mweb_url;
        } else {
          // this.props.history.push()
          Toast.info("微信支付异常！")
        }
      }

    }).catch((err: any) => {
      // document.write(err)
    })
  }

  aliPay(data: any) {
    Toast.loading('请稍后~', 0, () => {
      console.log('Load complete !!!');
    });
    return $.post({
      url: '/alipay/wappay',
      data: data
    }).then((res: any) => {
      Toast.hide();
      document.write(res)
    })
  }

  getCourseDetails() {
    return $.post({
      url: '/back_videoCourse/getVideoCourseById',
      data: {
        id: this.courseId
      }
    }).then((res: any) => {
      if (res.success && res.data) {
        this.setState({ ...res.data });
      }
    })
  }

  public render() {
    return (
      <div className={styles.container}>
        <header>
          <div className={styles.pic}><img src={this.state.coverImageUrl} alt="" /></div>
          <div className={styles.content}>
            <h1>{this.state.title}</h1>
            {/* <p>2019.1~2019.5.3（50课时）</p> */}
            <p>课程总时长（{this.state.totalLength}）课时</p>
            <p className={styles.price}>￥{this.state.price}</p>
          </div>
        </header>

        <div className={styles.paymentList}>
          <div className={styles.paymentItem}>
            <p className={styles.weixin}>
              <span className="iconfont iconbig-WeChat"></span>
              <span>微信支付</span>
            </p>
            <Checkbox
              onChange={() => this.setState({ payType: 0 })}
              checked={this.state.payType === 0}
            />
          </div>
          <div className={styles.paymentItem}>
            <p className={styles.alipay}>
              <span className="iconfont iconz-alipay"></span>
              <span>支付宝支付</span>
            </p>
            <Checkbox
              onChange={() => this.setState({ payType: 1 })}
              checked={this.state.payType === 1}
            />
          </div>
        </div>
        <div className={styles.paymentButton}>
          <button onClick={this.handlePay}>支付</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
