/**
 * 我的课程
 */

import * as React from 'react';
import { connect } from 'react-redux';
import './../../styles/center/antd_modify.scss';
import styles from './../../styles/center/my_course.module.scss';
// import COURSE from './../../static/img/个人中心/img1.png';
import {
  getClassList
} from './../../redux/myCourse.resucer';
import { Toast } from 'antd-mobile';
import Navigate from '../../components/common/navigate/navigate';

export interface IMyCourseProps {
  getClassList: any,
  history:any
}

export interface IMyCourseState {
  classList: []
}

class MyCourse extends React.Component<IMyCourseProps, IMyCourseState> {
  constructor(props: IMyCourseProps) {
    super(props);

    this.state = {
      classList: []
    }
  }

  componentDidMount() {
    Toast.loading('请稍后~', 0, () => {
      console.log('Load complete !!!');
    });
    this.props.getClassList().then(
      (res: any) => {
        Toast.hide();
        if (res.code === 200 && res.data) {
          let data = res.data;
          this.setState({ classList: data });
          console.log(data);
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.myCourseContainer} myCourseContainer`}>
        <div className={styles.header}>
          <p>我的课程</p>
        </div>
        <ul className={styles.courseListWrap}>
          {this.state.classList.map((item: any) => {
            return (
              <li
                onClick={()=>{
                  this.props.history.push(`/course/intro/${item.id}`)
                }}
                className={styles.course}
                key={item.id}
              >
                {/* <div className={styles.imgWrap}> */}
                <img src={item.coverImageUrl} alt="error" />
                {/* </div> */}
                <div className={styles.courseInfo}>
                  <h2 className={styles.courseTitle}>{item.title}</h2>
                  <p className={styles.courseDate}>课程总时长 {item.totalLength} 课时</p>
                  <p className={styles.courseIntro}>{item.overview}</p>
                </div>
                <span className={`${styles.courseStatus} ${item.status === 0 ? styles.updating : ''}`}>{item.status === 0 ? '更新中' : '已完结'}</span>
              </li>
            )
          })}
        </ul>
        <Navigate />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  console.log(state)
  return {
    classList: state.course.classList
  }
}

const mapDispatchToProps = {
  getClassList
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCourse);
