/**
 * 微信公众号
 */
import * as React from 'react';
import QRcode from './../../static/img/个人中心/二维码.jpg';
import styles from './../../styles/center/weixin.module.scss';

export interface IWeiXinProps {
}

export interface IWeiXinState {
}

export default class WeiXin extends React.Component<IWeiXinProps, IWeiXinState> {
  constructor(props: IWeiXinProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className={styles.container}>
        <h3>微信公众平台</h3>
        <img src={QRcode} alt="error"/>
      </div>
    );
  }
}
