/**
 *  用户登录信息
 */
import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_EXAM_LIST = "GET_EXAM_LIST"
type GET_EXAM_LIST = "GET_EXAM_LIST"

const GET_PRACTISE_LIST = "GET_PRACTISE_LIST"
type GET_PRACTISE_LIST = "GET_PRACTISE_LIST"

const GET_PRACTISE_TOPIC_LIST = "GET_PRACTISE_TOPIC_LIST"
type GET_PRACTISE_TOPIC_LIST = "GET_PRACTISE_TOPIC_LIST"

const GET_PRACTISE_TOPIC_RESULT = "GET_PRACTISE_TOPIC_RESULT"
type GET_PRACTISE_TOPIC_RESULT = "GET_PRACTISE_TOPIC_RESULT"

const SET_PRACTISE_ANWSER_OPTION = "SET_PRACTISE_ANWSER_OPTION"
type SET_PRACTISE_ANWSER_OPTION = "SET_PRACTISE_ANWSER_OPTION"

const GET_EXAM_TOPIC_LIST = "GET_EXAM_TOPIC_LIST"
type GET_EXAM_TOPIC_LIST = "GET_EXAM_TOPIC_LIST"

const GET_EXAM_TOPIC_RESULT = "GET_EXAM_TOPIC_RESULT"
type GET_EXAM_TOPIC_RESULT = "GET_EXAM_TOPIC_RESULT"

/**
 * 模拟考试试卷item
 */
export interface IExamItem {
  children: any[]
  childrenArray: any
  childrenNameList: string
  classifyType: number
  id: string
  name: string
}

/**
 * 模拟练习试卷item
 */
export interface IPractiseItem {
  children: any[]
  childrenArray: any
  childrenNameList: string
  classifyType: number
  id: string
  name: string
}

/**
 * 模拟练习单个题目item
 */
export interface IPractiseTopicItem {
  analysis: string
  answer: string
  createtime: string
  id: string
  onetype: string
  optiona: string
  optionb: string
  optionc: string
  optiond: string
  papersid: string
  result: boolean
  stem: string
  submit: string
}

export interface IExamTopicItem extends IPractiseTopicItem { }

export interface IPractiseTopicResult {
  paperid: string
  total: number
  awser: number
  notawser: number
  ok: number
  mistake: number
  score: number
  time: string
  starttime: string
  endtime: string
  title: string
  questions: IPractiseTopicItem[]
}

export interface IExamTopicResult extends IPractiseTopicResult {
  title: string
}

export interface IExamStoreState {
  examList: IExamItem[] // 考试科目以及场次列表
  practiseList: IPractiseItem[] // 练习科目以及场次列表
  examTopicList: IExamTopicItem[]  // 考试题目列表
  examTopicResult: IExamTopicResult  // 考试题目列表
  practiseTopicList: IPractiseTopicItem[] // 练习题目列表
  practiseTopicResult: IPractiseTopicResult // 练习题目结果
}

const initState: IExamStoreState = {
  examList: [],
  practiseList: [],
  examTopicList: [],
  examTopicResult: {
    paperid: "",
    total: 0,
    awser: 0,
    notawser: 0,
    ok: 0,
    mistake: 0,
    score: 0,
    time: "",
    starttime: "",
    endtime: "",
    title: "",
    questions: []
  },
  practiseTopicList: [],
  practiseTopicResult: {
    paperid: "",
    total: 0,
    awser: 0,
    notawser: 0,
    ok: 0,
    mistake: 0,
    score: 0,
    time: "",
    starttime: "",
    endtime: "",
    title: "",
    questions: []
  }
}

export function examStore(state = initState, action: any): IExamStoreState {
  switch (action.type) {
    case GET_EXAM_LIST:
      return { ...state, examList: action.examList }
    case GET_EXAM_TOPIC_LIST:
      return { ...state, examTopicList: action.examTopicList }
    case GET_EXAM_TOPIC_RESULT:
      return { ...state, examTopicResult: action.examTopicResult }
    case GET_PRACTISE_LIST:
      return { ...state, practiseList: action.practiseList }
    case GET_PRACTISE_TOPIC_LIST:
      return { ...state, practiseTopicList: action.practiseTopicList }
    case GET_PRACTISE_TOPIC_RESULT:
      return { ...state, practiseTopicResult: action.practiseTopicResult }

  }
  return state;
}

/**
 * 获取考试和场次列表接口
 * 需求修改 暂时停用
 * 2020年01月06日23:50:36 需求修改 改回使用该接口
 */

export function getExamList(params: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_classify/findClassify',
      data: {
        classifyType: 2
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_EXAM_LIST, examList: data })
      }
      return res;
    })
  }
}

/**
 * 获取考试和场次列表接口
 * 2020年01月05日23:49:57 需求修改使用该接口获取课表数据
 * 2020年01月06日23:50:12 需求修改 弃用该接口 改回原来
 */

// export function getExamList(params: any) {
//   return (dispatch: Dispatch) => {
//     return $.post({
//       url: '/back_papers/paperList',
//       data: {
//         pager: 1,
//         size: 10000
//       }
//     }).then((res: any) => {
//       let data = res.data;
//       if (res.success && data) {
//         dispatch({ type: GET_EXAM_LIST, examList: data.list })
//       }
//       return res;
//     })
//   }
// }

/**
 * 获取考试题目列表
 * @param params 
 */
export function getExamTopicList(examId: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_exam/examitemList',
      data: {
        id: examId,
        pager: 1,
        size: 100,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.questions) {
        dispatch({ type: GET_EXAM_TOPIC_LIST, examTopicList: data.questions })
        setTimeout(() => {
          dispatch({ type: GET_EXAM_TOPIC_RESULT, examTopicResult: {} })
        }, 200)
      }
      return res;
    })
  }
}

/**
 * 模拟考试交卷
 */
export function examSubmit() {
  return (dispatch: Dispatch, getState: any) => {
    let examTopicList = getState().examStore.examTopicList;
    return $.postJSON({
      url: '/back_exam/practiceSubmit',
      data: examTopicList
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.questions) {
        setTimeout(() => {
          dispatch({ type: GET_EXAM_TOPIC_LIST, examTopicList: [] })
        }, 200)

        dispatch({ type: GET_EXAM_TOPIC_RESULT, examTopicResult: data })
      }
      return res;
    })
  }
}

/**
 * 设置当前考试题目选项
 * @param questionIndex 当前考试题目index
 * @param submit 答案 A B C D选项
 */
export function setExamAnwser(questionIndex: number, submit: string) {
  return (dispatch: Dispatch, getState: any) => {
    let examTopicList = getState().examStore.examTopicList;
    examTopicList[questionIndex].submit = submit;
    dispatch({ type: GET_EXAM_TOPIC_LIST, examTopicList })
  }
}









/**
 * 获取练习科目和场次列表
 */
export function getPractiseList(params: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_classify/findClassify',
      data: {
        classifyType: 1
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_PRACTISE_LIST, practiseList: data })
      }
      return res;
    })
  }
}

/**
 * 获取练习题目列表
 * @param params 
 */
export function getPractiseTopicList(practiseId: string) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_exam/examList',
      data: {
        id: practiseId,
        pager: 1,
        size: 100,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.list) {
        dispatch({ type: GET_PRACTISE_TOPIC_LIST, practiseTopicList: data.list })

        setTimeout(() => {
          dispatch({ type: GET_PRACTISE_TOPIC_RESULT, practiseTopicResult: {} })
        }, 200)
      }
      return res;
    })
  }
}

/**
 * 设置当前考试题目选项
 * @param questionIndex 当前考试题目index
 * @param submit 答案 A B C D选项
 */
export function setPractiseAnwser(questionIndex: number, submit: string) {
  return (dispatch: Dispatch, getState: any) => {
    let practiseTopicList = getState().examStore.practiseTopicList;
    practiseTopicList[questionIndex].submit = submit;
    dispatch({ type: GET_PRACTISE_TOPIC_LIST, practiseTopicList })
  }
}

export function practiseSubmit() {
  return (dispatch: Dispatch, getState: any) => {
    let practiseTopicList = getState().examStore.practiseTopicList;
    return $.postJSON({
      url: '/back_exam/practiceSubmit',
      data: practiseTopicList
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.questions) {
        setTimeout(() => {
          dispatch({ type: GET_PRACTISE_TOPIC_LIST, practiseTopicList: [] })
        }, 200)

        dispatch({ type: GET_PRACTISE_TOPIC_RESULT, practiseTopicResult: data })
      }
      return res;
    })
  }
}
