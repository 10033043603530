/**
 * 师资阵容列表
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/teachers/teachers.module.scss';
import Teacher from './../../static/img/defaultTeacher.png';
import Navigate from '../../components/common/navigate/navigate';
import { PullToRefresh } from 'antd-mobile'
// import Teacher from './../../static/img/teacher.png';
import $ from './../../util/requst';
import ReactDOM from 'react-dom'
import { ITeachersStoreState, getAllTeacherList, ITeacherItem } from '../../redux/teachers.reducer';


export interface ITeachersProps {
  history: any,
  match: any,
  teachers: ITeachersStoreState,
  getAllTeacherList: any
}

export interface ITeachersState {
  height: any,
  refreshing: boolean,
  pager: number,
  endHieght: number,
  size: number,
  data: any
}

class Teachers extends React.Component<ITeachersProps, ITeachersState> {
  constructor(props: ITeachersProps) {
    super(props);

    this.state = {
      height: document.documentElement.clientHeight,
      endHieght: 0,
      refreshing: false,
      pager: 1,
      size: 10,
      data: []
    }
  }
  getAllTeacherList(pager: any, size: any) {
    return $.post({
      url: '/back_teacher/teacherList',
      data: {
        pager: pager,
        size: size
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        this.setState({
          data: this.state.data.concat(data.list)
        })
      }
    })
  }
  componentDidMount() {
    // @ts-ignore
    const hei = this.state.height - ReactDOM.findDOMNode(this.ptr).offsetTop - 70;
    setTimeout(() => {
      this.setState({
        endHieght: hei
      })
    this.getAllTeacherList(1, 10)
  }, 0);
  }

  public render() {
    // const pathname = '/teachersDetail';
    // const { teachers } = this.props;
    // const teacherList: ITeacherItem[] = teachers.teacherList
    return (
      // @ts-ignore
      <div className={styles.container} ref={e => (this.scroll = e)}>
        <div className={styles.header}>
          <h1>师资阵容</h1>
          <h2>実力講師陣</h2>
        </div>
        <PullToRefresh
          damping={100}
          getScrollContainer={() => undefined}
          indicator={{ activate: '加载更多...', finish: '加载完成', deactivate: '上拉加载更多' }}
          direction='up'
          style={
            {
              // touchAction: 'none',
              height: this.state.endHieght,
              overflow: 'auto',
            }
          }
          // @ts-ignore
          ref={el => this.ptr = el}
          distanceToRefresh={70}
          refreshing={this.state.refreshing}
          onRefresh={() => {
            this.setState({ refreshing: true, pager: this.state.pager + 1 });
            this.getAllTeacherList(this.state.pager, this.state.size)
            setTimeout(() => {
              this.setState({ refreshing: false });
            }, 1000);
          }}
        >

          <ul className={styles.list}>
            {
              this.state.data.map((item: ITeacherItem, index: number) => {
                // 主讲课程， 只显示一门课
                const major = item.courseListShowName ? item.courseListShowName.split(",")[0] : null
                return (<li
                  onClick={() =>{
                    this.props.history.push({
                      pathname:`teachersDetail`,
                      state:{
                        item:item
                      }
                    })

                  }}
                  key={item.id}
                >
                  <div
                    className={styles.imgContainer}
                  >
                    <img
                      onError={(e: any) => {
                        e.target.src = Teacher;
                      }}
                      src={item.headImageUrl ? item.headImageUrl : Teacher}
                      alt={item.name}
                    />
                  </div>
                  <h2>{item.name}</h2>
                  <p>
                    <span>{item.university}</span>
                  </p>
                    <span>主讲：{major}</span>
                </li>)
              })
            }
          </ul>
        </PullToRefresh>
        <Navigate />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    teachers: state.teachers
  }
}

const mapDispatchToProps = {
  getAllTeacherList
}

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);