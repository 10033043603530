import React from 'react';
import BaseRouter from './router';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import allReducers from './redux/index.reducer';

import 'minireset.css';
import 'antd-mobile/dist/antd-mobile.less';
import './static/iconfont/iconfont.css';
import './styles/common.scss';

const persistConfig = {
  key: 'root',
  storage: storage,
  version: 1
  // stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};

const myPersistReducer = persistReducer(persistConfig, allReducers)


// 1、创建 store
/**
 * 创建redux store
 */
const store = createStore(
  myPersistReducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
    // __DEV__ ? : f => f
  ));

const persistor = persistStore(store)

const App: React.FC = () => {
  return (
    <Provider
      store={store}
    >
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <BaseRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;
