/**
 * 点名详情
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/rollcall.module.scss';
import { List, Picker, Button, Toast } from 'antd-mobile';
import './../../styles/center/antd_modify.scss';
import {
  getCourseStudent,
  mergeCourseStudeng
} from './../../redux/mycenter.reducer';

const Item: React.ElementType = List.Item;

export interface IRollCallProps {
  history: any
  lession: any
  getCourseStudent: any
  mergeCourseStudeng: any
}

export interface IRollCallState {
  sValue: any[],
  courseStudetsList: any[]
  courseForm: any[]
}

class RollCall extends React.Component<IRollCallProps, IRollCallState> {
  constructor(props: IRollCallProps) {
    super(props);

    this.state = {
      sValue: [[1]],
      courseStudetsList: [],
      courseForm: []
    }
  }
  componentDidMount() {
    this.props.getCourseStudent(this.props.lession.id).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ courseStudetsList: res.data });
          let arr: any[] = [];
          let courseArr: any[] = [];
          res.data.map((item: any) => {
            arr.push([item.comeStatus]);
            courseArr.push({
              id: item.id,
              comeStatus: item.comeStatus
            });
          });
          this.setState({ sValue: arr, courseForm: courseArr });
        } else {
          Toast.info(res.message);
        }
      }
    );
  }
  inputChange(v: string, index: number, item: any) {
    let arr = JSON.parse(JSON.stringify(this.state.sValue));
    let courseArr = JSON.parse(JSON.stringify(this.state.courseForm));
    courseArr[index] = {
      id: item.id,
      comeStatus: v[0]
    };
    arr[index] = v;
    this.setState({ sValue: arr, courseForm: courseArr });
  }

  submit = () => {
    let data = {
      courseStudentId: JSON.stringify(this.state.courseForm),
      courseId: this.props.lession.id
    }
    this.props.mergeCourseStudeng(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('提交成功');
          this.props.history.push('/center/course_management');
        } else {
          Toast.info(res.message);
        }
      }
    );
  }
  public render() {
    const colorStyle = {
      fontSize: '12px',
      fontFamily: 'PingFang-SC-Medium',
      fontWeight: 500,
    };
    const choseList = [
      {
        label:
          (<div key="1">
            <span style={{ ...colorStyle, color: 'rgba(237, 125, 51, 1)' }}>迟到</span>
          </div>),
        value: 1,
      },
      {
        label:
          (<div key="2">
            <span style={{ ...colorStyle, color: 'rgba(243, 39, 39, 1)' }}>缺勤</span>
          </div>),
        value: 2,
      },
      {
        label:
          (<div key="3">
            <span style={{ ...colorStyle, color: 'rgba(118, 198, 213, 1)' }}>请假</span>
          </div>),
        value: 3,
      },
      {
        label:
          (<div key="4">
            <span style={{ ...colorStyle, color: 'rgba(43, 176, 121, 1)' }}>已到</span>
          </div>),
        value: 4,
      },
    ];
    return (
      <div className={`${styles.rollcallContainer} rollcallContainer`} >
        <div className={styles.header}>
          <h1>{this.props.lession.title}</h1>
          <div className={styles.dateWrap}>
            {/* <p className={styles.date}>2019-03-15&nbsp;&nbsp;&nbsp;&nbsp;14:00~16:00</p> */}
            <p className={styles.date}>{this.props.lession.beginsdate}</p>
            {/* <p className={styles.time}>14:00~16:00</p> */}
          </div>
        </div>
        <div className={styles.content}>
          {
            this.state.courseStudetsList.map(
              (item, index) => {
                return (
                  <List key={item.id}>
                    <Picker
                      data={choseList}
                      title="点到情况"
                      extra="请选择点到情况"
                      cols={1}
                      value={this.state.sValue[index]}
                      onChange={(v: any) => { this.inputChange(v, index, item) }}
                    >
                      <Item arrow="down">{item.studentName}</Item>
                    </Picker>
                  </List>
                )
              }
            )
          }
        </div>
        <div className={styles.buttonWrap}>
          <Button type="primary" inline className='fullBtn' onClick={this.submit}>提交</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lession: state.myCenter.lession
  }
}

const mapDispatchToProps = {
  getCourseStudent,
  mergeCourseStudeng
}

export default connect(mapStateToProps, mapDispatchToProps)(RollCall);
