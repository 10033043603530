/**
 *  我的课程
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_CLASS_LIST = "GET_CLASS_LIST"
type GET_CLASS_LIST = "GET_CLASS_LIST"

export interface IClassList {
  classList: []
}

export interface CourseStoreState {
  classList: []
}

const initState: IClassList = {
  classList: []
}

export function myCourse(state = initState, action: any): CourseStoreState {
  switch (action.type) {
    case GET_CLASS_LIST:
      return { ...state, classList: action.classList }
    default:
      return state
  }
}
/**
 * 获取用户信息接口
 */
export function getClassList(isTeacher: Boolean) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_student/getOwnVideoCourse',
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_CLASS_LIST, classList: data })
      }
      return res;
    })
  }
}
