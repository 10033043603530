import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/exam_analyse.module.scss';
import './../../../styles/center/antd_modify.scss';
import { Button, Toast } from 'antd-mobile';
import {
  getExamDetails,
} from './../../../redux/mycenter.reducer';


export interface IExamAnalyseProps {
  history: any
  getExamDetails: any
  exam: any
}
export interface IExamAnalyseState {
  activeAnswerCard: boolean
  examDetailsList: any[]
  activeIndex: number
}

class ExamAnalyse extends React.Component<IExamAnalyseProps, IExamAnalyseState> {
  constructor(props: IExamAnalyseProps) {
    super(props);

    this.state = {
      activeAnswerCard: false,
      examDetailsList: [{
        analysis: '',
        answer: '',
        createtime: '',
        id: 0,
        onetype: null,
        optiona: '',
        optionb: '',
        optionc: '',
        optiond: '',
        papersid: 4,
        result: true,
        stem: '',
        submit: 'A',
        subtype: 80,
        subtypestr: null,
        type: '',
        typestr: '',
        user: '',
        isRight: false,
        isSubmit: false
      }],
      activeIndex: 0
    }
  }

  componentDidMount() {
    this.props.getExamDetails(this.props.exam.recordid).then(
      (res: any) => {
        if (res.code === 200) {
          let data = res.data;
          data.map((item: any) => {
            if (item.answer === item.submit) {
              item.isRight = true;
            }
            if (item.submit) {
              item.isSubmit = true;
            }
          });
          this.setState({ examDetailsList: data })
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  public render() {
    let examDetails = this.state.examDetailsList[this.state.activeIndex] ? this.state.examDetailsList[this.state.activeIndex] : {};
    return (
      <div className={styles.examAnalyseContainer}>
        <div className={styles.title}>
          <p>(单选题)</p>
          <p><span className={styles.currentQuestionNumber}>1</span>/{this.state.examDetailsList.length}</p>
        </div>
        <div className={styles.question}>
          <p className={styles.questionContent}>{examDetails.stem}</p>
          <div className={styles.options}>
            <ul>
              <li className={`${styles.option} ${examDetails.answer === 'A' ? styles.rightOption : ''} ${(examDetails.answer !== 'A' && examDetails.submit === 'A') ? styles.wrongOption : ''}`}>
                <p className={styles.optionSign}>A</p>
                <p className={styles.optionContent}>{examDetails.optiona}</p>
              </li>
              <li className={`${styles.option} ${examDetails.answer === 'B' ? styles.rightOption : ''} ${(examDetails.answer !== 'B' && examDetails.submit === 'B') ? styles.wrongOption : ''}`}>
                <p className={styles.optionSign}>B</p>
                <p className={styles.optionContent}>{examDetails.optionb}</p>
              </li>
              <li className={`${styles.option} ${examDetails.answer === 'C' ? styles.rightOption : ''} ${(examDetails.answer !== 'C' && examDetails.submit === 'C') ? styles.wrongOption : ''}`}>
                <p className={styles.optionSign}>C</p>
                <p className={styles.optionContent}>{examDetails.optionc}</p>
              </li>
              <li className={`${styles.option} ${examDetails.answer === 'D' ? styles.rightOption : ''} ${(examDetails.answer !== 'D' && examDetails.submit === 'D') ? styles.wrongOption : ''}`}>
                <p className={styles.optionSign}>D</p>
                <p className={styles.optionContent}>{examDetails.optiond}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.separatorLine}></div>
        <div className={styles.answer}>
          <p style={{ paddingBottom: '.20rem' }}>正确答案：<span>{examDetails.answer}</span></p>
          <p>答案解析：<span>{examDetails.analysis}</span></p>
        </div>
        <div className={styles.handle}>
          <Button className="ghostButton" type="ghost" style={{ minWidth: '34.67%' }} inline onClick={() => this.setState({ activeAnswerCard: true })}>答题卡</Button>
          <Button className="ghostButton" type="ghost" style={{ minWidth: '34.67%' }} inline onClick={() => window.history.back()}>返回查看答题结果</Button>
        </div>
        <div className={`${styles.answerCardWrap} ${this.state.activeAnswerCard ? styles.active : ''}`}>
          <div className={styles.whiteBlock} onClick={() => this.setState({ activeAnswerCard: false })}></div>
          <div className={styles.answerCard}>
            <h3>答题卡</h3>
            <ul className={styles.opsiontsWrap}>
              {
                this.state.examDetailsList.map((item, index) => {
                  return (
                    <li className={styles.options} key={item.id} onClick={() => { this.setState({ activeIndex: index }) }}>
                      <p className={`${item.isRight ? '' : styles.errorOption} ${item.isSubmit ? '' : styles.warningOption}`}>{index + 1}</p>
                    </li>
                  )
                })
              }
              {/* <li className={styles.options}>
                <p className={styles.errorOption}>1</p>
              </li>
              <li className={styles.options}>
                <p className={styles.warningOption}>1</p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    exam: state.myCenter.exam
  }
}

const mapDispatchToProps = {
  getExamDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamAnalyse);