/**
 * 我的消息 详情
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/msg_details.module.scss';
// import msgImg from './../../../static/img/个人中心/my_btn_Message-push_banner.png'

export interface IMsgDetailsProps {
  message: any
}

export interface IMsgDetailsState {
}
class MsgDetails extends React.Component<IMsgDetailsProps, IMsgDetailsState> {
  constructor(props: IMsgDetailsProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    console.log(this.props)
  }

  public render() {
    return (
      <div className={styles.msgDetailsContainer}>
        <div className={styles.msgDetailsContent}>
          <h1>{this.props.message.title}</h1>
          <p className={styles.date}>{this.props.message.pushDate}</p>
          <p className={styles.msgInfo}>{this.props.message.message}</p>
          {/* <a className={styles.msgHref} href="">点击购买</a>
          <div className={styles.imgWrap}>
            <img src={msgImg} alt="error" />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    message: state.myCenter.message
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MsgDetails);

