/**
 * 咨询列表
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/consult_list.module.scss'
import './../../../styles/center/antd_modify.scss';
import { List, Toast } from 'antd-mobile';
import {
  getConsultList,
  saveConsultData
} from './../../../redux/mycenter.reducer';

export interface IConsultListProps {
  history: any
  getConsultList: any
  userInfo: any
  saveConsultData: any
}

export interface IConsultListState {
  getHasReplyed: boolean
  consultList: any
}

class ConsultList extends React.Component<IConsultListProps, IConsultListState> {
  constructor(props: IConsultListProps) {
    super(props);

    this.state = {
      getHasReplyed: false,
      consultList: []
    }
  }

  handleCheckReplyList = () => {

  }

  handleGetConsultDetails = (item: any) => {
    this.props.saveConsultData(item);
    if (this.state.getHasReplyed) {
      this.props.history.push(`/consult/consult_details/${item.id}`);
    } else {
      this.props.history.push('/center/consult_reply');
    }
  }

  componentDidMount() {
    this.props.getConsultList().then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ consultList: res.data });
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  toggleConsultList(status: boolean) {
    this.setState({ getHasReplyed: status });
    this.props.getConsultList().then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ consultList: res.data });
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.consultListContainer} consultListContainer`}>
        <div className={styles.header}>
          <p className={`${styles.waitReply} ${this.state.getHasReplyed ? '' : styles.active}`} onClick={() => { this.toggleConsultList(false) }}>待回复</p>
          <p className={`${styles.hasReplyed} ${this.state.getHasReplyed ? styles.active : ''}`} onClick={() => { this.toggleConsultList(true) }}>已回复</p>
        </div>
        <div className={styles.content}>
          <List>
            <List.Item className="consultContent" extra="咨询时间">咨询内容</List.Item>
            {
              this.state.consultList.map((item: any) => {
                return (<List.Item key={item.id} extra={item.conDate} onClick={() => this.handleGetConsultDetails(item)}>{item.question}</List.Item>)
              })
            }
            {/* <List.Item className="consultContent" extra="咨询时间">咨询内容</List.Item>
            <List.Item extra="2019.1.1 17:00" onClick={() => this.handleGetConsultDetails()}>老师，您好！我想报考明治大...</List.Item>
            <List.Item extra="2019.1.1 17:00">老师，您好！我想报考明治大...</List.Item>
            <List.Item extra="2019.1.1 17:00">老师，您好！我想报考明治大...</List.Item>
            <List.Item extra="2019.1.1 17:00">老师，您好！我想报考明治大...</List.Item> */}
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getConsultList,
  saveConsultData
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultList);
