/**
 * 费用报销
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/reimbursement.module.scss';
import './../../styles/center/antd_modify.scss';
import {
  uploadFile,
  postTeacherReim,
  getReimType,
} from './../../redux/mycenter.reducer';
import config from './../../config/app.config';
import { List, Picker, TextareaItem, InputItem, Button, ImagePicker, Toast } from 'antd-mobile';

export interface IReimbursementProps {
  uploadFile: any
  postTeacherReim: any
  getReimType: any
  userInfo: any
  history: any
}

export interface IReimbursementState {
  files: [],
  fileName: string
  type: any
  totalMoney: any
  says: any
  reimTypeList: any
}

class Reimbursement extends React.Component<IReimbursementProps, IReimbursementState> {
  constructor(props: IReimbursementProps) {
    super(props);

    this.state = {
      files: [],
      fileName: '',
      type: '',
      totalMoney: '',
      says: '',
      reimTypeList: [],
    }
  }

  componentDidMount() {
    this.props.getReimType().then(
      (res: any) => {
        if (res.code === 200) {
          let reimType: any[] = [];
          res.data.map((item: any) => {
            reimType.push({
              value: item.id,
              label: item.typename
            })
          });
          this.setState({ reimTypeList: reimType });
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  onChange = (files: any) => {
    this.setState({
      files,
    });
    let data = {
      file: files[0].file
    };
    this.props.uploadFile(data).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ fileName: res.data })
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  submit = () => {
    let data = {
      type: this.state.type[0],
      totalMoney: this.state.type.totalMoney,
      invoiceImage: this.state.fileName ? `${config.cdnService}${this.state.fileName}` : '',
      says: this.state.says,
      teacherId: this.props.userInfo.id,
    };
    this.props.postTeacherReim(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('提交成功');
          this.props.history.push('/center');
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  public render() {
    const data = [
      {
        label: 'test',
        value: 1
      }
    ];
    return (
      <div className={`${styles.reimbursementContainer} reimbursementContainer`}>
        <div className={styles.header}>
          <h1>费用报销</h1>
        </div>
        <div className={styles.content}>
          <List className="moneyAndPic">
            <List.Item extra="元">
              <InputItem className="phoneInput" placeholder="请输入发票金额"
                value={this.state.totalMoney}
                onChange={(value) => { this.setState({ totalMoney: value }) }}
              />
            </List.Item>
            <List.Item className="invoiceUpload">
              请上传发票图片
              <ImagePicker

                length="6"
                files={this.state.files}
                onChange={this.onChange}
                disableDelete
                // onImageClick={(index, fs) => console.log(index, fs)}
                selectable={this.state.files.length < 1}
              // onAddImageClick={this.onAddImageClick}
              />
            </List.Item>
          </List>
          <List>
            <Picker data={this.state.reimTypeList} cols={1} extra=" "
              value={this.state.type}
              onChange={(value) => { this.setState({ type: value }) }}>
              <List.Item arrow="down">请选择报销类型</List.Item>
            </Picker>
            <TextareaItem
              autoHeight
              rows={5}
              placeholder="请输入报销说明"
              value={this.state.says}
              onChange={(value) => { this.setState({ says: value }) }}
            />
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type="primary" inline className="fullBtn" onClick={this.submit}>提交</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  uploadFile,
  postTeacherReim,
  getReimType,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reimbursement);
