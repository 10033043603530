/**
 *  用户登录信息
 */
import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_USER_INFO = "GET_USER_INFO"
type GET_USER_INFO = "GET_USER_INFO"

export interface IUser {
  accountNonExpired: boolean
  accountNonLocked: boolean
  createtime: string
  credentialsNonExpired: boolean
  enabled: boolean
  id: string
  locak: string
  name: string
  radio: number
  username: string
}
export interface IUserStoreState {
  token: string,
  user: IUser
}

const initState: IUserStoreState = {
  token: '',
  user: {
    accountNonExpired: false,
    accountNonLocked: false,
    createtime: "",
    credentialsNonExpired: false,
    enabled: false,
    id: "",
    locak: "",
    name: "",
    radio: 1,
    username: "",
  }
}

export function user(state = initState, action: any): IUserStoreState {
  switch (action.type) {
    case GET_USER_INFO:
      return { ...state, ... action.userInfo }
  }
  return state;
}

/**
 * 用户登录接口
 * @param params 用户登录参数 username password radio
 * @radio  1学生普通登录 2老师普通登录 3后台普通登录 4验证码登录
 */
export function login(params:any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/login',
      data: {
        ...params
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_USER_INFO, userInfo: data})
      }
      return res;
    })
  }
}