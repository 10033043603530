/**
 * 学校列表 学校推荐 优质学院
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/pass_rate/university_list.module.scss';
import Navigate from '../../components/common/navigate/navigate';
import {
  getAllSchoolList,
  ISchool,
  ISchoolsStoreState
} from './../../redux/school.reducer';

// import University from './../../static/img/university.png';

export interface IUniversityListProps {
  history: any,
  match: any,
  getAllSchoolList: any,
  schools: ISchoolsStoreState
}

export interface IUniversityListState {
}

class UniversityList extends React.Component<IUniversityListProps, IUniversityListState> {
  constructor(props: IUniversityListProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getAllSchoolList();
    console.log(this.props)
  }

  public render() {
    const pathname = this.props.match.url;
    const { schools } = this.props;
    const schoolList: ISchool[] = schools.schoolList;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>学校推荐</h1>
          <h2>推薦校</h2>
        </div>
        <ul className={styles.list}>
          {
            schoolList.map((item: ISchool, index: number) => {
              const coverUrl = item.collegeImage.length > 0 ? item.collegeImage[0].imageUrl : item.emblem_url
              return (<li
                onClick={() => this.props.history.push(`${pathname}/intro/${item.id}`)}
                key={item.id}
              >
                <div>
                  <img src={coverUrl} alt={item.name} />
                </div>
                <h2>{item.name}</h2>
              </li>)
            })
          }
        </ul>
        <Navigate />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schools: state.schools
  }
}

const mapDispatchToProps = {
  getAllSchoolList
}

export default connect(mapStateToProps, mapDispatchToProps)(UniversityList);
