/**
 * 支付结果
 */
import * as React from 'react';
import $ from './../../util/requst';
import styles from './../../styles/course/payment_result.module.scss';
import { Toast } from 'antd-mobile';

export interface IPaymentResultProps {
  history: any
  match: any,
  location: any
}

export interface IPaymentResultState {
  paymentRes: boolean
  isShowRes: boolean
}

export default class PaymentResult extends React.Component<IPaymentResultProps, IPaymentResultState> {
  // private paymentRes: boolean;
  constructor(props: IPaymentResultProps) {
    super(props);
    this.state = {
      paymentRes: false,
      isShowRes: false,
    }
    // this.paymentRes = true;
  }

  componentDidMount() {
    let search = this.props.location.search;
    try {
      if (!search) {
        throw "支付失败！";
      }

      if (search.length < 100 && search.indexOf("out_trade_no") !== -1) {
        let out_trade_no = search.split("out_trade_no=")[1];
        this.checkWxPayResult(out_trade_no);
        Toast.loading('正在查询支付结果~', 0, () => {
          console.log('Load complete !!!');
        });
      } else {
        this.setState({
          paymentRes: true,
          isShowRes: true
        });
      }
    } catch (error) {
      console.log(error)
      this.setState({
        paymentRes: false,
        isShowRes: true
      });
    }
  }

  checkWxPayResult(outTradeNo: string) {
    return $.post({
      url: '/wxpay/wxstatus',
      data: {
        outTradeNo: outTradeNo
      }
    }).then((res: any) => {
      Toast.hide();
      let data = res.data;
      let payRes = false;
      if (res.success && data) {
        if (data.trade_state_desc === '支付成功') {
          payRes = true;
        }

        this.setState({
          paymentRes: payRes,
          isShowRes: true
        })
      }
    })
  }

  handleClick() {
    this.props.history.push('/center');
  }

  public render() {
    const result = this.state.paymentRes;
    const isShowRes = this.state.isShowRes;
    // if (!isShowRes) {
    //   return <div></div>;
    // }
    return (
      <div className={styles.container}>
        {
          isShowRes
            ? result
              ? <div className={styles.success}>
                <div>
                  <span className="iconfont iconchenggong"></span>
                  <h1>支付成功</h1>
                </div>
                <p>温馨提示：感谢用户的支持，我们将尽快为您发货，可在<a
                  onClick={this.handleClick.bind(this)}
                  href="#"
                >个人-我的课程</a> 观看课程内容</p>
              </div>
              : <div className={styles.falid}>
                <div>
                  <span className="iconfont iconku"></span>
                  <h1>支付失败</h1>
                </div>
                <p>温馨提示：订单会在30分钟后自动取消，请用户重新支付，谢谢您的支持。</p>
                <button onClick={() => this.props.history.push("/course")}>重新支付</button>
              </div>
            : null
        }
      </div>
    );
  }
}
