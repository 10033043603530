/**
 * 咨询列表
 */
import * as React from 'react';
import { Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import styles from './../../styles/consult/consult.module.scss';
import Navigation from './../../components/common/navigate/navigate'
import { IConsultStoreState, IConsultItem, getAllConsultList, saveCurrentConsult } from '../../redux/consult.reducer';

export interface IConsultListProps {
  match: any,
  history: any,
  consults: IConsultStoreState,
  getAllConsultList: any
  saveCurrentConsult: any
}

export interface IConsultListState {
}

class ConsultList extends React.Component<IConsultListProps, IConsultListState> {
  constructor(props: IConsultListProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getAllConsultList()
  }

  public render() {
    const pathname = this.props.match.url
    const { consults } = this.props;
    const consultList: IConsultItem[] = consults.consultList || [];
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>咨询解答</h1>
          <h2>Q&A</h2>
          <div>
            <button
              onClick={() => {
                if (!sessionStorage._t) {
                  Toast.info("咨询前请先登录", 1, () => {
                    this.props.history.push('/n');
                  });
                  return;
                }
                this.props.history.push(`${pathname}/consulting/:default`)
              }}
            >我要咨询</button>
          </div>
        </div>
        {
          consultList.length
            ? <ul>
              {
                consultList.map((item: IConsultItem, index) => {
                  return <li
                    key={index}
                    onClick={() => {
                      this.props.saveCurrentConsult(item);
                      this.props.history.push(`${pathname}/consult_details/${item.id}`);
                    }}
                  >{index + 1}.{item.question}</li>
                })

              }
            </ul>
            : <div className={styles.noData}>
              <p>暂无咨询数据~</p>
            </div>
        }

        <Navigation type="white" />
      </div >
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    consults: state.consults
  }
}

const mapDispatchToProps = {
  getAllConsultList,
  saveCurrentConsult
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultList);
