
/**
 * 课程学习模块 路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";
import Course from './course';
import CourseIntro from './course_intro';
import Payment from './payment';
import PaymentResult from './payment_result';
import CourseDetails from './course_details';


const PassRate: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      {/* 课程列表 */}
      <Route path={`${pathname}`} exact component={Course} />
      {/* 课程介绍 */}
      <Route path={`${pathname}/intro/:courseId`} component={CourseIntro} />
      {/* 付款界面 */}
      <Route path={`${pathname}/payment/:courseId`} component={Payment} />
      <Route path={`${pathname}/course_details/:videoId`} component={CourseDetails} />
      {/* 付款结果 */}
      <Route path={`${pathname}/result`} component={PaymentResult} />
    </Switch>
  );
}

export default PassRate;