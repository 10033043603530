/**
 * 讲师详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Toast } from 'antd-mobile';
import $ from './../../util/requst';
import Teacher from './../../static/img/defaultTeacher.png';
import styles from './../../styles/teachers/teacher_details.module.scss';
import { ITeachersStoreState, ITeacherItem } from '../../redux/teachers.reducer';

export interface ITeacherDetailsProps {
  history: any,
  match: any,
  teachers: ITeachersStoreState
}

export interface ITeacherDetailsState extends ITeacherItem {
  consultationList: any[]
}

class TeacherDetails extends React.Component<ITeacherDetailsProps, ITeacherDetailsState> {
  private teacherId: string;
  private teacherItem: any
  private classList: any
  constructor(props: ITeacherDetailsProps) {
    super(props);
    this.teacherId = this.props.history.location.state.item.id
    this.teacherItem = this.props.history.location.state.item
    if(this.teacherItem.courseListShowName){
      let arr  = this.teacherItem.courseListShowName.split(',')
      this.classList = arr.filter(function(s: any){
        return s && s.trim()
      })
    }

    this.state = {
      accountNonLocked: true,
      courseListShowName: "",
      coursechoose: "",
      createTime: "",
      experience: "",
      head_image_url: "",
      headImageUrl: "",
      id: "",
      indexComm: true,
      name: "",
      nick:"",
      password: "",
      phone: "",
      says: "",
      sex: 0,
      university: "",
      username: "",
      video_name: "",
      video_url: "",
      weixinCode: "",
      consultationList: []
    }
  }

  componentDidMount() {
    try {
      let teacherList = this.props.teachers.teacherList;
      if (teacherList && this.teacherId) {
        this.getConsultation(this.teacherId)
        let teacher = teacherList.filter((item: ITeacherItem) => {
          return item.id == this.teacherId;
        })

        if (teacher.length) {
          this.setState({ ...teacher[0] })
          return;
        }
      }
      throw "数据异常";
    } catch (error) {
      // this.props.history.push(`/teachers`);
    }
  }

  /**
   * 获取当前教师的咨询列表
   */
  getConsultation(teacherId: string) {
    return $.post({
      url: '/back_consultation/getConsultation',
      data: {
        pager: 1,
        size: 8,
        teacherId: teacherId,
        pushPublic: true,
        askStatus: 1,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.list) {
        this.setState({ consultationList: data.list })
      }
    })
  }

  public render() {
    const consultationList = this.state.consultationList || []
    const courseListShowName = this.state.courseListShowName;
    const courseList = courseListShowName ? courseListShowName.split(",") : [];

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.base}>
            <img
              onError={(e: any) => {
                e.target.src = Teacher;
              }}
              src={this.teacherItem.headImageUrl ? this.teacherItem.headImageUrl : Teacher}
              alt={this.teacherItem.nick}
            />
            <div>
              <h1>{this.teacherItem.nick}</h1>
              <p>{this.teacherItem.university}</p>
            </div>
          </div>
          <h2>担任课程</h2>
          <div className={styles.courseList}>
            {

              this.classList.length > 0 && this.classList.map((item: string, index: number) => {
                return <p key={item + index}>{item}</p>
              })
            }
          </div>
        </div>

        <ul className={styles.teacher_details}>
          <li>
            <div>
              <h1>讲师寄语 / </h1>
              <h2>Lecturer's Message</h2>
            </div>
            <p>{this.teacherItem.says}</p>
          </li>
          <li>
            <div>
              <h1>讲师经历 / </h1>
              <h2>Lecturer experience</h2>
            </div>
            <p>{this.teacherItem.experience}</p>
          </li>
          {
            this.teacherItem.video_url
              ?
              <li>
                <div>
                  <h1>关于讲师 / </h1>
                  <h2>About Lecturers</h2>
                </div>
                <div className={styles.videoContainer}>

                  <video

                    controls
                    src={this.teacherItem.video_url}
                  ></video>
                </div>

              </li>
              : null
          }
        </ul>
        <div className={styles.teacher_consult}>
          <div>
            <h1>讲师咨询</h1>
            <button
              onClick={() => {
                if (!sessionStorage._t) {
                  Toast.info("咨询前请先登录", 1, () => {
                    this.props.history.push('/n');
                  });
                  return;
                }
                this.props.history.push(`/consult/consulting/${this.teacherId}`)
              }}
            >我要咨询</button>
          </div>
          <ul>
            {
              consultationList.map((item, index: number) => {
                return <li
                  key={item.id}
                  onClick={() => {
                    this.props.history.push(`/consult/consult_details/${item.id}`)
                  }}
                >{index + 1}、{item.question}</li>
              })
            }
          </ul>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    teachers: state.teachers
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDetails);
