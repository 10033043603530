/**
 * 最新动态路由配置
 */
import * as React from 'react';
import {  Route, Switch } from "react-router-dom";
import News from "./news";
import NewsDetails from "./news_details";

const NewsFrame: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      <Route path={`${pathname}`} exact component={News} />
      <Route path={`${pathname}/:newsId`} component={NewsDetails} />
    </Switch>
  );
}

export default NewsFrame;