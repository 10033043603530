/**
 * 动态详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/news/new_details.module.scss';

// import Details from './../../static/img/news_detail.png'
import { INewsStoreState, INewsItem } from '../../redux/news.reducer';

export interface INewsDetailsProps {
  history: any,
  match: any,
  news: INewsStoreState
}

export interface INewsDetailsState extends INewsItem {
}

class NewsDetails extends React.Component<INewsDetailsProps, INewsDetailsState> {
  private newsId: string;
  constructor(props: INewsDetailsProps) {
    super(props);
    this.newsId = props.match.params.newsId;
    this.state = {
      dynamicContent: "",
      dynamicImage: "",
      id: "",
      outLink: "",
      releaseDate: [],
      title: "",
    }
  }

  componentDidMount() {
    try {
      let newsList = this.props.news.newsList;
      if (newsList && this.newsId) {
        let newsItem = newsList.filter((item: INewsItem) => {
          return item.id == this.newsId;
        })

        if (newsItem.length) {
          this.setState({ ...newsItem[0] })
          return;
        }
      }
      throw "数据异常";
    } catch (error) {
      this.props.history.push(`/news`);
    }
  }

  public render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{this.state.title}</h1>
        <p className={styles.time}>发布时间：2018-12-25</p>
        {/* <div className={styles.pic}>
          <img src={this.state.dynamicImage} alt="新闻背景" />
        </div> */}
        <div
          dangerouslySetInnerHTML={{
            __html:this.state.dynamicContent
          }}
          className={styles.content}
        >
          {/* {this.state.dynamicContent} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);