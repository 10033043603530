/**
 * 最新动态
 */
import * as React from 'react';
import { connect } from 'react-redux';
import Navigate from './../../components/common/navigate/navigate';
// import NewsPic from './../../static/img/news.png';
import styles from './../../styles/news/news.module.scss';
import { INewsStoreState, getAllNewsList, INewsItem } from '../../redux/news.reducer';

export interface INewsProps {
  history: any,
  match: any,
  news: INewsStoreState,
  getAllNewsList: any
}

export interface INewsState {
}

class News extends React.Component<INewsProps, INewsState> {
  constructor(props: INewsProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getAllNewsList()
  }

  public render() {
    console.log(this.props)
    const pathname = this.props.match.url
    const { news } = this.props;
    const newsList: INewsItem[] = news.newsList
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>最新动态</h1>
          <h2>最新イベント</h2>
        </div>
        <ul className={styles.listContainer}>
          {
            newsList.map((item: INewsItem, index) => {
              return <li
                onClick={() => this.props.history.push(`${pathname}/${item.id}`)}
                key={item.id}
                className={styles.listItem}
              >
                <div className={styles.pic}>
                  <img src={item.dynamicImage} alt="新闻图片" />
                </div>
                <div className={styles.content}>
                  <h1>{item.title}</h1>
                  <p>发布时间:&nbsp;&nbsp;2019年07月21日</p>
                </div>
              </li>
            })
          }
        </ul>
        <Navigate type="white" />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = {
  getAllNewsList
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
