/**
 * 教师简介
 * 只有教师用户有这个页面
 */
import * as React from 'react';
import { connect } from 'react-redux';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/capacity_assess.module.scss';
import {
  uploadFile,
  updateUserInfo
} from './../../../redux/mycenter.reducer';
import { List, TextareaItem, Button, ImagePicker, Toast } from 'antd-mobile';
import config from './../../../config/app.config';

const Item: React.ElementType = List.Item;

export interface ITeacherIntrosProps {
  userInfo: any
  updateUserInfo: any
  uploadFile: any
  history: any
}

export interface ITeacherIntrosState {
  files: any
  fileName: string
  says: string
  experience: string
}

class TeacherIntros extends React.Component<ITeacherIntrosProps, ITeacherIntrosState> {
  constructor(props: ITeacherIntrosProps) {
    super(props);

    this.state = {
      files: [],
      fileName: '',
      experience: '',
      says: ''
    }
  }

  componentDidMount() {
    this.setState({ says: this.props.userInfo.says, experience: this.props.userInfo.experience, fileName: this.props.userInfo.video_name })
  }
  onChange = (files: any, type: any) => {
    this.setState({
      files,
    });
    let data = {
      file: files[0].file
    };
    this.props.uploadFile(data).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ fileName: res.data })
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  submitTeacherIntros = () => {
    let data = JSON.parse(JSON.stringify(this.props.userInfo));
    data.video_url = `${config.service}video/${this.state.fileName}`;
    data.video_name = this.state.fileName;
    data.says = this.state.says;
    data.experience = this.state.experience;
    this.props.updateUserInfo(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('修改成功');
          this.props.history.push('/center');
        } else {
          Toast.info(res.message);
        }
      }
    );

  }

  public render() {
    return (
      <div style={{ backgroundColor: 'rgba(246, 249, 249, 1)' }}>
        <List>
          <TextareaItem className={styles.textArea} rows={5} placeholder="填写讲师寄语"
            value={this.state.says}
            onChange={(value: any) => { this.setState({ says: value }) }}
          />
          <div style={{ height: '9px', backgroundColor: 'rgba(246, 249, 249, 1)' }}></div>
          <TextareaItem className={styles.textArea} rows={5} placeholder="填写讲师经历"
            value={this.state.experience}
            onChange={(value: any) => { this.setState({ experience: value }) }} />
          <div style={{ height: '9px', backgroundColor: 'rgba(246, 249, 249, 1)' }}></div>
          <Item>{this.state.fileName}</Item>
          <ImagePicker
            accept='video/*'
            multiple={false}
            length={1}
            onChange={this.onChange}
          // capture="camera"
          ></ImagePicker>
        </List>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={this.submitTeacherIntros}>确定</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  uploadFile,
  updateUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherIntros);

