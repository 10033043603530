/**
 * 意见反馈
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/feedback.module.scss';
import './../../styles/center/antd_modify.scss';
import { List, Picker, TextareaItem, InputItem, Button, Toast } from 'antd-mobile';
import { submitFeedBack } from './../../redux/mycenter.reducer';

export interface IFeedbackProps {
  history: any
  submitFeedBack: any
  userInfo: any
}

export interface IFeedbackState {
  feedtype: any
  otherdes: string
  phone: string
}

class Feedback extends React.Component<IFeedbackProps, IFeedbackState> {
  constructor(props: IFeedbackProps) {
    super(props);

    this.state = {
      feedtype: '1',
      otherdes: '',
      phone: ''
    }
  }

  submitFeedback = () => {
    const FEEDBACK_TYPE = sessionStorage.getItem('isTeacher') === 'true' ? 1 : 2;
    let data = {
      feedtype: this.state.feedtype,
      otherdes: this.state.otherdes,
      phone: this.state.phone,
      feedbackType: FEEDBACK_TYPE,
      persionId: this.props.userInfo.id
    }
    this.props.submitFeedBack(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('反馈成功！');
          this.props.history.push('/center')
        }
      }
    );
  }

  public render() {
    const data = [
      {
        label: '功能异常',
        value: '1'
      }, {
        label: '用户体验',
        value: '2'
      }, {
        label: '新功能建议',
        value: '3'
      }, {
        label: '其他',
        value: '4'
      },
    ];
    return (
      <div className={`${styles.feedbackContainer} feedbackContainer`}>
        <div className={styles.header}>
          <h1>意见反馈</h1>
        </div>
        <div className={styles.content}>
          <List>
            <Picker data={data} cols={1} extra=" " value={this.state.feedtype} onChange={(value: any) => { this.setState({ feedtype: value }) }}>
              <List.Item arrow="down">请选择反馈类型</List.Item>
            </Picker>
            <TextareaItem
              autoHeight
              rows={5}
              placeholder="请输入反馈内容"
              value={this.state.otherdes}
              onChange={(value: any) => { this.setState({ otherdes: value }) }}
            />
          </List>
          <List>
            <List.Item>
              <InputItem className="phoneInput" placeholder="请输入您的手机号"
                value={this.state.phone}
                onChange={(value: any) => { this.setState({ phone: value }) }} />
            </List.Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type="primary" inline className="fullBtn" onClick={this.submitFeedback}>提交</Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  submitFeedBack
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
