/**
 * 勤务管理
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/service_management.module.scss';
import {
  getAllTeacherCourseList,
  sureCourse
} from './../../redux/mycenter.reducer';
import { Button, Toast, Checkbox } from 'antd-mobile';

export interface IServiceManagementProps {
  getAllTeacherCourseList: any
  sureCourse: any
  userInfo: any
  history: any
}

export interface IServiceManagementState {
  lessionsList: any[]
  checkedCourseIds: string
}

class ServiceManagement extends React.Component<IServiceManagementProps, IServiceManagementState> {
  constructor(props: IServiceManagementProps) {
    super(props);

    this.state = {
      lessionsList: [],
      checkedCourseIds: ''
    }
  }

  componentDidMount() {
    this.props.getAllTeacherCourseList(this.props.userInfo.id).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ lessionsList: res.data });
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  handleChangeCourse = (e: any, id: string) => {
    let checked: Boolean = e.target.checked;
    if (checked) {
      let checkedCourseIds = `${this.state.checkedCourseIds}${id},`;
      this.setState({ checkedCourseIds: checkedCourseIds });
    } else {
      let ids: string[] = this.state.checkedCourseIds.split(',');
      let index: number = ids.indexOf(id);
      ids.splice(index, 1);
      this.setState({ checkedCourseIds: ids.join(',') });
    }
  }

  submit = () => {
    if (this.state.checkedCourseIds === '') {
      return Toast.info('请至少选择一项');
    }
    this.props.sureCourse(this.state.checkedCourseIds).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('提交成功');
          this.props.history.push('/center');
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  public render() {
    return (
      <div className={styles.serviceManagementContainer}>
        <div className={styles.header}>
          <h1>勤务管理</h1>
        </div>
        <div className={styles.content}>
          <ul>
            {
              this.state.lessionsList.map((item) => {
                return (
                  <li className={styles.course} key={item.id}>
                    <Checkbox className={styles.checkbox}
                      onChange={(e: any) => { this.handleChangeCourse(e, item.id) }}></Checkbox>
                    <div>
                      <h3>{item.title}</h3>
                      <p>{item.beginsdate}</p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className={styles.buttonWrap}>
          <Button type="primary" inline className='fullBtn' onClick={this.submit}>提交</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getAllTeacherCourseList,
  sureCourse
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceManagement);