// import React, { Component } from 'react';
import axios from 'axios'
import config from '../config/app.config';

interface Request {
  url: string,
  data: any,
  method: 'POST' | 'GET'
}
interface $ {
  // post:(req:Request)=>Promise
}

const $: any = {}

$.token = '';
$.instance = axios.create({
  baseURL: config.service,
  timeout: 20000,
});


/**
 * 通用post接口请求
 * @param {*} param0 
 */
$.post = function (params: Request) {
  if (sessionStorage.getItem("_t")) {
    $.instance.defaults.headers.common['Authorization'] = sessionStorage.getItem("_t");
  }

  let { method = 'POST', url, data } = params;
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key])
  }
  return $.instance({
    method: method,
    url: url,
    data: formData,
  }).then((res: any) => {
    if (res) {
      return res.data
    }
    throw '接口调用异常';
  })
}

$.postJSON = function (params: Request) {
  if (sessionStorage.getItem("_t")) {
    $.instance.defaults.headers.common['Authorization'] = sessionStorage.getItem("_t");
  }

  let { method = 'POST', url, data } = params;

  return $.instance({
    method: method,
    url: url,
    data: data,
  }).then((res: any) => {
    if (res) {
      return res.data
    }
    throw '接口调用异常';
  })
}

/**
 * 通用get接口请求
 * @param {*} param0 
 */
$.get = function (params: Request) {
  if (sessionStorage.getItem("_t")) {
    $.instance.defaults.headers.common['Authorization'] = sessionStorage.getItem("_t");
  }

  let { method = 'GET', url, data } = params;
  return $.instance({
    method: method,
    url: url,
    params: data
  }).then((res: any) => {
    if (res) {
      return res.data
    }
    throw '接口调用异常';
  })
}

export default $;

