/**
 * 课程详情-播放
 */
import * as React from 'react';
import { Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import $ from './../../util/requst';
import styles from './../../styles/course/course_details.module.scss';
import { ICourseItem, ICourseStoreState } from '../../redux/course.reducer';
// import { ITeacherItem } from '../../redux/teachers.reducer';

export interface ICourseIntroProps {
  history: any,
  match: any,
  course: ICourseStoreState
}

export interface ICourseIntroState extends ICourseItem {
  isFullScreen: boolean
}

class CourseDetails extends React.Component<ICourseIntroProps, ICourseIntroState> {
  private videoSrc: string;
  private videoPlayer: any;
  private videoPlayerNode: any;
  constructor(props: ICourseIntroProps) {
    super(props);
    this.videoSrc = decodeURIComponent(props.match.params.videoId);
  }

  componentDidMount() {
    // if (!sessionStorage._t) {
    //   this.props.history.push('/n')
    //   return;
    // }

    if (this.videoPlayerNode) {
      this.videoPlayerNode.setAttribute('controlslist', 'nodownload')
      this.videoPlayerNode.oncontextmenu = () => false;
    }
  }


  // 点击视频播放按钮
  handleClickPlay(src: string, index: number) {
    if (!src) {
      Toast.info("当前节次暂未关联课程");
      return;
    }

    if (index === 0) {
      this.videoPlayerNode.src = src;
      this.videoPlayerNode.play()
      return;
    }

    this.checkPayment().then((res: any) => {
      if (!res.success) {
        this.videoPlayerNode.src = src;
        this.videoPlayerNode.play()
      } else {
        Toast.info('未购买该课程!');
      }
    }).catch((err: any) => {
      let strErr = err + "";
      if (strErr.indexOf("403") !== -1) {
        Toast.info("登录信息是失效，请重新登录");
        this.props.history.push("/n");
        sessionStorage.clear();
        return;
      }
    })

  }

  /**
 * 检查是否能够购买该课程
 */
  checkPayment() {
    return $.post({
      url: '/back_videoCourse/state',
      data: {
        id: this.props.course.courseDetails.id
      }
    })
  }


  public render() {
    console.log(this.props)
    const { courseDetails } = this.props.course
    let videoCourseDis = courseDetails.videoCourseDis || []
    return (
      <div className={styles.container}>
        <div className={styles.videoContainer}>
          <video
            ref={node => this.videoPlayerNode = node}
            // className="video-js"

            src={this.videoSrc}
            controls
          ></video>
        </div>

        <div className={styles.courseInfo}>
          <div className={styles.title}>
            <h1>{courseDetails.title}</h1>
            <p>￥{courseDetails.price}</p>
          </div>
          <p className={styles.courseStatus}>
            <span>课程总时长：（{courseDetails.totalLength}课时）</span>
            <span>{courseDetails.status === 0 ? "更新中" : "已完结"}</span>
          </p>
          <div className={styles.courseOverview}>
            <p>课程概述：</p>
            <pre>{courseDetails.overview}</pre>
          </div>
        </div>
        <div className={styles.timetable}>
          <h1>课程表</h1>
          <ul>
            {
              videoCourseDis.map((item: any, index: number) => {
                return <li key={item.id}>
                  <p className={styles.lesson}>第{index + 1}节</p>
                  <p className={styles.name}>{item.disName}</p>
                  <p
                    className={styles.icon}
                    style={{ display: item.videoUrl === null ? 'none' : 'inline-block' }}
                    onClick={this.handleClickPlay.bind(this, item.videoUrl, index)}
                  >
                    <i
                      className="iconfont iconplay"
                    // onClick={this.handleClickPlay.bind(this, item.videoUrl)}
                    ></i>
                    {/* <span>即将发布</span> */}
                  </p>
                </li>
              })
            }
          </ul>
          {/* <button>查看更多</button> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    course: state.course
  }
}

const mapDispatchToProps = {
  // getCourseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);