/**
 * 快速练习详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/exam/practise_details.module.scss';
import './../../styles/center/antd_modify.scss';
import { Button, Toast } from 'antd-mobile';
import { IExamStoreState, setPractiseAnwser, IPractiseTopicItem, practiseSubmit } from '../../redux/exam.reducer';

export interface IPractiseDetailsProps {
  history: any,
  setPractiseAnwser: any,
  practiseSubmit: any,
  exam: IExamStoreState
}

export interface IPractiseDetailsState {
  activeAnswerCard: boolean,
  currentQuestionIndex: number,
  questionSize: number,
  // answer: string
}

class PractiseDetails extends React.Component<IPractiseDetailsProps, IPractiseDetailsState> {
  constructor(props: IPractiseDetailsProps) {
    super(props);

    this.state = {
      questionSize: props.exam.practiseTopicList.length,
      activeAnswerCard: false,
      currentQuestionIndex: 0,
    }
  }

  componentDidMount() {
    try {
      console.log(this.props.exam.practiseTopicList)
      if(!this.props.exam.practiseTopicList.length){
        throw "未获取到数据";
      }
    } catch (error) {
      this.props.history.push("/exam")
    }
   
  }

  // 点击答题选项
  handleClickAnswer(answer: string) {
    // this.setState({ answer })
    this.props.setPractiseAnwser(this.state.currentQuestionIndex, answer)
  }

  // 点击下一题
  handleClickNextButton() {
    let { currentQuestionIndex } = this.state;
    let { practiseTopicList } = this.props.exam;
    if (currentQuestionIndex + 1 < practiseTopicList.length) {
      this.setState({ currentQuestionIndex: currentQuestionIndex + 1 })
    }
  }

  // 点击上一题
  handleClickPrevButton() {
    let { currentQuestionIndex } = this.state;
    // let { practiseTopicList } = this.props.exam;
    if (currentQuestionIndex + 1 > 1) {
      this.setState({ currentQuestionIndex: currentQuestionIndex - 1 })
    }
  }

  /**
   * 交卷
   */
  handleSubmit() {
    // return $.postJSON({
    //   url: '/back_exam/practiceSubmit',
    //   data: this.props.exam.practiseTopicList
    // }).then((res: any) => {
    //   let data = res.data;
    //   Toast.info(res.message)
    //   if (res.success && data) {
    //     this.props.history.push('/exam/practise_result')
    //   }
    // })
    Toast.loading('正在提交，请稍后', 0, () => {
      console.log('Load complete !!!');
    });
    this.props.practiseSubmit().then((res:any) => {
      Toast.info(res.message);
      if (res.success && res.data) {
        this.props.history.push('/exam/practise_result');
      }
    }).finally(()=>{
      Toast.hide();
    })
  }

  public render() {
    console.log(this.props)
    const { currentQuestionIndex, questionSize } = this.state;
    const practiseTopicList = this.props.exam.practiseTopicList||[]
    let answer = practiseTopicList.length?practiseTopicList[currentQuestionIndex].submit:""
    if(!practiseTopicList.length) return null;
    return (
      <div className={styles.examAnalyseContainer}>
        <div className={styles.title}>
          <p>(单选题)</p>
          <p><span className={styles.currentQuestionNumber}>{currentQuestionIndex + 1}</span>/{questionSize}</p>
        </div>
        <div className={styles.question}>
          <p className={styles.questionContent}>{practiseTopicList[currentQuestionIndex].stem}</p>
          <div className={styles.options}>
            <ul>
              <li
                className={`${styles.option} ${answer === "A" ? styles.chosenOption : ""}`}
                onClick={this.handleClickAnswer.bind(this, "A")}
              >
                <p className={styles.optionSign}>A</p>
                <p className={styles.optionContent}>{practiseTopicList[currentQuestionIndex].optiona}</p>
              </li>
              <li
                className={`${styles.option} ${answer === "B" ? styles.chosenOption : ""}`}
                onClick={this.handleClickAnswer.bind(this, "B")}
              >
                <p className={styles.optionSign}>B</p>
                <p className={styles.optionContent}>{practiseTopicList[currentQuestionIndex].optionb}</p>
              </li>
              <li
                className={`${styles.option} ${answer === "C" ? styles.chosenOption : ""}`}
                onClick={this.handleClickAnswer.bind(this, "C")}
              >
                <p className={styles.optionSign}>C</p>
                <p className={styles.optionContent}>{practiseTopicList[currentQuestionIndex].optionc}</p>
              </li>
              <li
                className={`${styles.option} ${answer === "D" ? styles.chosenOption : ""}`}
                onClick={this.handleClickAnswer.bind(this, "D")}
              >
                <p className={styles.optionSign}>D</p>
                <p className={styles.optionContent}>{practiseTopicList[currentQuestionIndex].optiond}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.handle}>
          <Button
            disabled={currentQuestionIndex + 1 === 1}
            type="ghost"
            className="ghostButton"
            inline
            onClick={this.handleClickPrevButton.bind(this)}
          >上一题</Button>
          <Button
            type="ghost"
            className="ghostButton"
            inline
            onClick={() => this.setState({ activeAnswerCard: true })}
          >答题卡</Button>
          <Button
            disabled={currentQuestionIndex + 1 === practiseTopicList.length}
            onClick={this.handleClickNextButton.bind(this)}
            type="ghost"
            className="ghostButton"
            inline
          >下一题</Button>
        </div>
        <div className={styles.handedPaper}>
          <Button
            type="primary"
            onClick={this.handleSubmit.bind(this)}>交卷</Button>
        </div>
        {/* 答题卡 */}
        <div className={`${styles.answerCardWrap} ${this.state.activeAnswerCard ? styles.active : ''}`}>
          <div className={styles.whiteBlock} onClick={() => this.setState({ activeAnswerCard: false })}></div>
          <div className={styles.answerCard}>
            <h3>答题卡</h3>
            <ul className={styles.opsiontsWrap}>
              {
                practiseTopicList.map((item: IPractiseTopicItem, index: number) => {
                  return <li
                    key={item.id}
                    onClick={() => {
                      this.setState({
                        currentQuestionIndex: index,
                        activeAnswerCard: false
                      })
                    }}
                    className={styles.options}
                  >
                    <p className={item.submit ? styles.submit : ""}>{index + 1}</p>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    exam: state.examStore,
  }
}

const mapDispatchToProps = {
  setPractiseAnwser,
  practiseSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(PractiseDetails);