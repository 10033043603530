/**
 * 讲师咨询详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import $ from './../../util/requst';
import styles from './../../styles/consult/consult_details.module.scss';

export interface IConsultDetailsProps {
  match: any
  currentConsult: any
}

export interface IConsultDetailsState {
  question: string,
  askMessage: string
}

class ConsultDetails extends React.Component<IConsultDetailsProps, IConsultDetailsState> {
  private consultId: string;
  constructor(props: IConsultDetailsProps) {
    super(props);
    this.consultId = props.match.params.consultId;
    this.state = {
      question: "",
      askMessage: ""
    }
  }

  componentDidMount() {
    console.log(this.props.currentConsult);
    this.getConsultDetails()
  }

  getConsultDetails() {
    return $.post({
      url: '/back_consultation/getConsultationById',
      data: {
        id: this.consultId
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        this.setState({ ...data })
      }
    })
  }

  public render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{this.state.question}</h1>
        <p className={styles.content}>{this.state.askMessage}</p>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentConsult: state.consults.currentConsult
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultDetails);
