
/**
 * 师资阵容路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";

import Teachers from './teachers';
import TeacherDetails from './teacher_details';

const TeachersFrame: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      <Route path={`${pathname}`} exact component={Teachers} />
      <Route path={`/teachersDetail`} component={TeacherDetails} />
    </Switch>
  );
}

export default TeachersFrame;