/**
 * 课程管理
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/course_management.module.scss';
import { Toast } from 'antd-mobile';
import {
  getLessionsList,
  saveLessionData,
} from './../../redux/mycenter.reducer';
import SearchInput from './../../components/common/searchinput/search_input';

export interface ICourseManagementProps {
  history: any
  getLessionsList: any
  saveLessionData: any
  userInfo: any
}

export interface ICourseManagementState {
  lessionsList: any[]
}

class CourseManagement extends React.Component<ICourseManagementProps, ICourseManagementState> {
  constructor(props: ICourseManagementProps) {
    super(props);

    this.state = {
      lessionsList: []
    };
  }

  handleOnSubmit = (value: string) => {
    let data = {
      id: this.props.userInfo.id,
      title: value
    };
    this.props.getLessionsList(data).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ lessionsList: res.data });
        } else {
          Toast.info(res.data);
        }
      }
    );
  }

  componentDidMount() {
    let data = {
      id: this.props.userInfo.id,
    };
    this.props.getLessionsList(data).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({ lessionsList: res.data });
        } else {
          Toast.info(res.data);
        }
      }
    );
  }


  public render() {
    return (
      <div className={styles.courseManagementContainer}>
        <div className={styles.header}>
          <h1>课程管理</h1>
          <div className={styles.searchInputWrap}>
            <SearchInput placeholder="输入你要搜索的关键词" onSubmit={(value) => this.handleOnSubmit(value)}></SearchInput>
          </div>
        </div>
        <div className={styles.courseManagementContent}>
          <ul>
            {
              this.state.lessionsList.map(item => {
                return (
                  <li className={styles.course}
                    key={item.id}
                    onClick={() => { this.props.history.push('/center/rollcall'); this.props.saveLessionData(item) }}>
                    <h2>{item.title}</h2>
                    {/* <p className={styles.schoolArea}>东京校区&nbsp;&nbsp;&nbsp;&nbsp;東京都新宿区市谷田町2-17八重洲市谷ビル</p> */}
                    <p className={styles.schoolArea}>{item.address}</p>
                    {/* <p>上课时间：2019-03-15&nbsp;&nbsp;&nbsp;&nbsp;14:00~16:00</p> */}
                    <p>上课时间：{item.beginsdate}</p>
                  </li>
                )
              })
            }
            {/* <li className={styles.course} onClick={() => this.props.history.push('/center/rollcall')}>
              <h2>日语基础</h2>
              <p className={styles.schoolArea}>东京校区&nbsp;&nbsp;&nbsp;&nbsp;東京都新宿区市谷田町2-17八重洲市谷ビル</p>
              <p>上课时间：2019-03-15&nbsp;&nbsp;&nbsp;&nbsp;14:00~16:00</p>
            </li>
            <li className={styles.course} onClick={() => this.props.history.push('/center/rollcall')}>
              <h2>日语基础</h2>
              <p className={styles.schoolArea}>东京校区&nbsp;&nbsp;&nbsp;&nbsp;東京都新宿区市谷田町2-17八重洲市谷ビル</p>
              <p>上课时间：2019-03-15&nbsp;&nbsp;&nbsp;&nbsp;14:00~16:00</p>
            </li>
            <li className={styles.course} onClick={() => this.props.history.push('/center/rollcall')}>
              <h2>日语基础</h2>
              <p className={styles.schoolArea}>东京校区&nbsp;&nbsp;&nbsp;&nbsp;東京都新宿区市谷田町2-17八重洲市谷ビル</p>
              <p>上课时间：2019-03-15&nbsp;&nbsp;&nbsp;&nbsp;14:00~16:00</p>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getLessionsList,
  saveLessionData,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagement);
