
/**
 * 顶尖大学合格率路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";
import UniversityList from './university_list';
import Intro from './intro';
import Faculty from './faculty';
import Major from './major';


const PassRate: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      {/* 学校列表 */}
      <Route path={`${pathname}`} exact component={UniversityList} />
      {/* 学校介绍 */}
      <Route path={`${pathname}/intro/:schoolId`} component={Intro} />
      {/* 学部介绍 */}
      <Route path={`${pathname}/faculty/:schoolId/:facultyId`} component={Faculty} />
      {/* 大学院介绍 */}
      <Route path={`${pathname}/major/:schoolId/:facultyId`} component={Major} />
    </Switch>
  );
}

export default PassRate;