import $ from './../util/requst';
import {Dispatch} from 'redux'

const GET_CAMPUS = 'GET_CAMPUS'
type GET_CAMPUS = typeof GET_CAMPUS
const initState: any = {
    campusList: []
}

export function addressDetail(state = initState, action: any): any {
    switch (action.type) {
        case GET_CAMPUS:
            return {...state, campusList: action.campusList}
    }
    return state;
}

export function getAllcampusList() {
    return (dispatch: Dispatch) => {
        return $.post({
            url: '/back_information/getCampus',
            data: {}
        }).then((res: any) => {
            let data = res.data;
            if (res.success && data) {
                dispatch({type: GET_CAMPUS, campusList: data || []})
            }
        })
    }
}
