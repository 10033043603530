
/**
 * 模拟考试 路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";
import Exam from './exam';
import PractiseDetails from './practise_details';
import PractiseResult from './practise_result';
import PracitseAnalysis from './practise_analysis';
import ExamDetails from './exam_details';
import ExamResult from './exam_result';
import ExamAnalysis from './exam_analysis';

// import Teachers from './teachers';
// import TeacherDetails from './teacher_details';

const ExamFrame: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      <Route path={`${pathname}`} exact component={Exam} />
      {/* 快速练习详情 */}
      <Route path={`${pathname}/practise_details/:practiseId`} component={PractiseDetails} />
      {/* 快速练习结果 */}
      <Route path={`${pathname}/practise_result`} component={PractiseResult} />
      {/* 快速练习解析 */}
      <Route path={`${pathname}/practise_analysis/:questionIndex`} component={PracitseAnalysis} />
      {/* 模拟考试详情 */}
      <Route path={`${pathname}/exam_details/:examId`} component={ExamDetails} />
      {/* 模拟考试结果 */}
      <Route path={`${pathname}/exam_result`} component={ExamResult} />
      {/* 模拟考试解析 */}
      <Route path={`${pathname}/exam_analysis/:questionIndex`} component={ExamAnalysis} />
    </Switch>
  );
}

export default ExamFrame;