/**
 * 个人中心 路由配置  Fram
 */
import * as React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import MyCenter from './mycenter';
import Person from './personal/person';
import T_Person from './personal/t_person';
import About from './about';
import ModifyName from './personal/modify_name';
import ModifyWX from './personal/modify_wx';
import N_AccountSafety from './personal/n_account_safety';
import N_ModifyPhone from './personal/n_modify_phone';
import TeacherIntros from './personal/teacher_intros';
import T_AccountSafety from './personal/t_account_safety';
import T_ModifyPhone from './personal/t_modify_phone';
import CapacityAssess from './capacity_assess';
import CourseManagement from './course_management';
import Feedback from './feedback';
import MyCourse from './my_course';
import MyExam from './my_exam';
import Reimbursement from './reimbursement';
import RollCall from './rollcall';
import ServiceManagement from './service_management';
import WeiXin from './weixin';
import ExamResult from './exam/exam_result';
import ExamAnalyse from './exam/exam_analyse';
import MsgList from './message/msg_list';
import MsgDetails from './message/msg_details';
import FsqsDetails from './consulting/faqs_details';
import ConsultReply from './consulting/consult_reply';
import ConsultList from './consulting/consult_list';
import ConsultDetails from './consulting/consult_details';

export interface IMycenterFramProps {
  match: any
}

export default class MycenterFram extends React.Component<IMycenterFramProps> {
  public render() {
    const pathname: string = this.props.match.path;
    return (
      <Switch>
        <Route path={`${pathname}/`} exact component={MyCenter} />
        <Route path={`${pathname}/modify_name`}  component={ModifyName} />
        <Route path={`${pathname}/modify_wx`}  component={ModifyWX} />
        <Route path={`${pathname}/n_as`}  component={N_AccountSafety} />
        <Route path={`${pathname}/t_as`}  component={T_AccountSafety} />
        <Route path={`${pathname}/n_mp`}  component={N_ModifyPhone} />
        <Route path={`${pathname}/t_mp`}  component={T_ModifyPhone} />
        <Route path={`${pathname}/teacher_intros`} component={TeacherIntros} />
        <Route path={`${pathname}/personal`} component={Person} />
        <Route path={`${pathname}/T_Person`} component={T_Person} />
        <Route path={`${pathname}/about`}  component={About} />
        <Route path={`${pathname}/capacity_assess`}  component={CapacityAssess} />
        <Route path={`${pathname}/course_management`}  component={CourseManagement} />
        <Route path={`${pathname}/feedback`}  component={Feedback} />
        <Route path={`${pathname}/my_course`}  component={MyCourse} />
        <Route path={`${pathname}/my_exam`}  component={MyExam} />
        <Route path={`${pathname}/reimbursement`}  component={Reimbursement} />
        <Route path={`${pathname}/rollcall`}  component={RollCall} />
        <Route path={`${pathname}/service_management`}  component={ServiceManagement} />
        <Route path={`${pathname}/weixin`}  component={WeiXin} />
        <Route path={`${pathname}/exam_result`}  component={ExamResult} />
        <Route path={`${pathname}/exam_analyse`}  component={ExamAnalyse} />
        <Route path={`${pathname}/msg_list`}  component={MsgList} />
        <Route path={`${pathname}/msg_details`}  component={MsgDetails} />
        <Route path={`${pathname}/faqs_details`}  component={FsqsDetails} />
        <Route path={`${pathname}/consult_reply`}  component={ConsultReply} />
        <Route path={`${pathname}/consult_list`}  component={ConsultList} />
        {/* <Route path={`${pathname}/consult_details`}  component={ConsultDetails} /> */}
        <Redirect to={`${pathname}/`}  />
      </Switch>
    );
  }
}
