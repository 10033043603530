/**
 * 普通用户修改手机号
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button, Toast } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/n_modify_phone.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import {
  updateUserInfo,
  getCaptcha,
  confirmCaptcha
} from './../../../redux/mycenter.reducer';

const Item: React.ElementType = List.Item;


export interface IN_ModifyPhoneProps {
  userInfo: any
  history: any
  updateUserInfo: any
  getCaptcha: any
  confirmCaptcha: any
}

export interface IN_ModifyPhoneState {
  phoneNumber: string,
  captcha: string,
  captchaTip: string,
  captchaLock: boolean
}

class N_ModifyPhone extends React.Component<IN_ModifyPhoneProps, IN_ModifyPhoneState> {
  constructor(props: IN_ModifyPhoneProps) {
    super(props);

    this.state = {
      phoneNumber: '13960830321',
      captcha: '',
      captchaTip: '获取验证码',
      captchaLock: false
    }
  }

  componentDidMount() {
    this.setState({ phoneNumber: this.props.userInfo.phone })
  }

  getCaptcha = () => {
    if (this.state.captchaLock) {
      return
    }
    this.props.getCaptcha({ phone: this.state.phoneNumber }).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('获取成功');
          this.setState({ captchaLock: true });
          let time = 60;
          let timer: NodeJS.Timer | null = setInterval(() => {
            this.setState({ captchaTip: `${time}秒后再试` });
            time -= 1;
            if (time === -1) {
              window.clearTimeout(Number(timer));
              this.setState({ captchaLock: false });
              this.setState({ captchaTip: `获取验证码` });
            }
          }, 1000);
        }
      }
    );
  }

  handleChangePhone = (value: string) => {
    this.setState({ phoneNumber: value });
  }

  submitEditPhone = () => {
    let confirmData = {
      phone: this.state.phoneNumber,
      code: this.state.captcha
    };
    this.props.confirmCaptcha(confirmData).then(
      (res: any) => {
        if (res.code === 200) {
          let data = this.props.userInfo;
          data.phone = this.state.phoneNumber;
          this.props.updateUserInfo(data).then(
            (res: any) => {
              if (res.code === 200) {
                Toast.info('修改成功');
                this.props.history.push('/center');
              }
            }
          );
        } else {
          Toast.info('验证码错误');
        }
      }
    );
  }


  public render() {
    return (
      <div className={`${styles.nModifyPhoneContainer} nModifyPhoneContainer`}>
        <div className={styles.nickNameWrap}>
          <List>
            <Item extra={<p className={styles.captchaBtn}><span onClick={this.getCaptcha}>{this.state.captchaTip}</span></p>}><span>手机号码</span><InputItem className={styles.phoneNumberInput}
              placeholder='请输入手机号码'
              value={this.state.phoneNumber}
              onChange={this.handleChangePhone}></InputItem></Item>
            <Item><InputItem className={styles.captchaInput}
              value={this.state.captcha}
              placeholder='请输入您的手机验证码'
              onChange={(value) => this.setState({ captcha: value })}></InputItem></Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={this.submitEditPhone}>确定</Button>
        </div>
        <Navigate type='white'></Navigate>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  updateUserInfo,
  confirmCaptcha,
  getCaptcha
}

export default connect(mapStateToProps, mapDispatchToProps)(N_ModifyPhone);
