/**
 * 我要咨询
 */
import * as React from 'react';
import { Button,Toast } from 'antd-mobile';
import styles from './../../styles/consult/consulting.module.scss';
import $ from './../../util/requst';


export interface IConsultingProps {
  match: any,
  history:any
}

export interface IConsultingState {
  content: string
}

export default class Consulting extends React.Component<IConsultingProps, IConsultingState> {
  private teacherId: string;
  constructor(props: IConsultingProps) {
    super(props);
    this.teacherId = props.match.params.teacherId;
    this.state = {
      content: ""
    }
  }

  handleConsult() {
    return $.post({
      url: '/back_consultation/merge',
      data: {
        question: this.state.content,
        teacherId: this.teacherId,
        pushPublic: true,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success) {
        // this.setState({ consultationList: data.list })
        Toast.info('提交成功！')
        this.props.history.goBack()
      }
    })
  }

  onChange(e: any) {
    let value = e.target.value;
    this.setState({
      content: value
    })
  }

  public render() {
    return (
      <div className={styles.container}>
        <textarea
          name="咨询"
          cols={300}
          rows={10}
          maxLength={50}
          placeholder="请输入您咨询的内容"
          value={this.state.content}
          onChange={this.onChange.bind(this)}
        ></textarea>
        <Button
          type="primary"
          onClick={this.handleConsult.bind(this)}
        >提交</Button>
      </div>
    );
  }
}
