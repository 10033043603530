import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import LoginFram from './pages/login/login_fram';
import MycenterFram from './pages/mycenter/mycenter_fram';
import T_Login from './pages/login/t_login';
import N_Login from './pages/login/n_login';
import Register from './pages/login/register';
import Forget from './pages/login/forget';
import Binding from './pages/login/binding';
import Home from './pages/home/home';
import NewsFrame from './pages/news/frame';
import Contact from './pages/contact/contact';
import ConsultFrame from './pages/consult/frame';
import PassRateFrame from './pages/pass_rate/frame';
import TeachersFrame from './pages/teachers/frame';
import CourseFrame from './pages/course/frame';
import TraineesFrame from './pages/trainees/frame';
import TraineesDetail from './pages/trainees/traineesDetail';
import ExamFrame from './pages/exam/frame';
import TeacherDetails from './pages/teachers/teacher_details';
import Address from './pages/address/index'


export default function BaseRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/n" component={N_Login} />
        <Route path="/t" component={T_Login} />
        <Route path="/register" component={Register} />
        <Route path="/forget" component={Forget} />
        <Route path="/binding" component={Binding} />
        <Route path="/news" component={NewsFrame} />
        <Route path="/contact" component={Contact} />
        <Route path="/consult" component={ConsultFrame} />
        <Route path="/center" component={MycenterFram} />
        <Route path="/pass_rate" component={PassRateFrame} />
        <Route path="/teachers" component={TeachersFrame} />
        <Route path="/course" component={CourseFrame} />
        <Route path="/trainees" component={TraineesFrame} />
        <Route path="/traineesDetail" component={TraineesDetail} />
        <Route path="/exam" component={ExamFrame} />
        <Route path='/teachersDetail' component={TeacherDetails} />
        <Route path='/address' component={Address} />
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

// /**
//  * 教师路由配置
//  */
// const Teacher: React.FC = (props: any) => {
//   const pathname: string = props.match.path;
//   console.log(props,`${pathname}/login`)
//   return (
//     <Switch>
//       <Route path={`${pathname}/login`} component={LoginTeacher} />
//       <Redirect to={`${pathname}/login`} />
//     </Switch>
//   );
// }

// /**
//  * 普通用户路由配置
//  */
// const Normal: React.FC = (props: any) => {

//   const pathname: string = props.match.path;
//   return (
//     <Switch>
//       <Route path={`${pathname}/login`} component={LoginNormal} />
//       <Redirect to={`${pathname}/login`} />
//     </Switch>
//   );
// }