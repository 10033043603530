/**
 *  个人信息
 */

// import { Dispatch } from 'redux';
// import $ from './../util/requst';

const UPDATE_USER_NAME = "UPDATE_USER_NAME"
type UPDATE_USER_NAME = "UPDATE_USER_NAME"

export interface IUserInfo {
  userName: string,
  phone: string,
  wxCode: string,
  headImageUrl: string,
  nick: string,
  sex: string,
  description: string
}

export interface MyCenterStoreState {
  userInfo: IUserInfo
}

const initState: MyCenterStoreState = {
  userInfo: {
    userName: '',
    phone: '',
    wxCode: '',
    headImageUrl: '',
    nick: '',
    sex: '',
    description: ''
  }
}

export function person(state = initState, action: any): MyCenterStoreState {
  // console.log(arguments)
  switch (action.type) {
    case UPDATE_USER_NAME:
      return { ...state, userInfo: action.userInfo }
    default:
      return state
  }
}
/**
 * 修改名称、昵称
 */
