/**
 * 修改昵称
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/modify_name.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import { updateUserInfo } from './../../../redux/mycenter.reducer'

const Item: React.ElementType = List.Item;

export interface IModifyNameProps {
  userInfo: any,
  updateUserInfo: any
  history: any,
}

export interface IModifyNameState {
  userName: string;
  isTeacher: boolean
}

class ModifyName extends React.Component<IModifyNameProps, IModifyNameState> {
  constructor(props: IModifyNameProps) {
    super(props);

    this.state = {
      userName: '蜡笔小新',
      isTeacher: false
    }
  }

  componentDidMount() {
    const IS_TEACHER = sessionStorage.getItem('isTeacher') === 'true';
    this.setState({ isTeacher: IS_TEACHER });
    this.setState({ userName: this.props.userInfo.nick });
  }

  handleChangeName = (value: string) => {
    this.setState({ userName: value });
  }

  submitNameChange = () => {
    let userInfo = JSON.parse(JSON.stringify(this.props.userInfo));
    userInfo.nick = this.state.userName;
    this.props.updateUserInfo(userInfo).then(
      (res: any) => {
        if (res.code === 200) {
          this.props.history.push('/center');
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.modifyNameContainer} modifyNameContainer`}>
        <div className={styles.nickNameWrap}>
          <List>
            <Item extra={<InputItem 
            placeholder={`请输入${this.state.isTeacher ? '姓名' : '用户昵称'}`}
            value={this.state.userName}
             onChange={this.handleChangeName}></InputItem>}>{this.state.isTeacher ? '姓名' : '用户昵称'}</Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={this.submitNameChange}>确定</Button>
        </div>
        <Navigate type='white'></Navigate>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  updateUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyName);
