/**
 * 大学 学校介绍
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'antd-mobile';
import styles from './../../styles/pass_rate/intro.module.scss';
// import Xmdx from './../../static/img/xmdx.png';
import Navigate from '../../components/common/navigate/navigate';
import { ISchoolsStoreState, ISchool } from '../../redux/school.reducer';


const DOT_STYLE: object = {
  backgroundColor: '#F6CDAE',
  width: 10,
  height: 10
}

const ACTIVE_STYLE: object = {
  backgroundColor: '#77C7D6',
  width: 10,
  height: 10
}

export interface IIntroProps {
  history: any,
  match: any,
  schools: ISchoolsStoreState
}

export interface IIntroState {
  school: any
}

class Intro extends React.Component<IIntroProps, IIntroState> {
  private schoolId: string;
  constructor(props: IIntroProps) {
    super(props);

    this.state = {
      school: {
      }
    }

    this.schoolId = props.match.params.schoolId;
  }

  componentDidMount() {
    let schoolList = this.props.schools.schoolList;
    if (this.schoolId && schoolList.length) {
      let school: ISchool = schoolList.filter((item: ISchool) => {
        return item.id == this.schoolId;
      })[0]
      this.setState({ school })
    } else {
      this.props.history.push(`/pass_rate`);
      return;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  public render() {
    const school: ISchool = this.state.school
    const banners = school.collegeImage || [
      { id: 1, imageUrl: '' },
      { id: 2, imageUrl: '' },
    ];          //默认值解决无法autoplay问题
    const collegeFaculty = school.collegeFaculty || []

    return (
      <div className={styles.container}>
        <Carousel
          autoplay
          infinite
          dotStyle={DOT_STYLE}
          dotActiveStyle={ACTIVE_STYLE}
          beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
          afterChange={index => console.log('slide to', index)}
        >
          {
            banners.map((item: any) => {
              return <div
                style={{ height: 185 }}
                key={item.id}
              >
                <img
                  className={styles.bannerItem}
                  src={item.imageUrl}
                  alt="大学展示图"
                  key={item.id}
                />
              </div>

            })
          }
        </Carousel>
        <div className={styles.intro}>
          <div className={styles.header}>
            <div>
              <img src={school.emblem_url} alt="校徽" />
              <div className={styles.name}>
                <h1>{school.name}</h1>
                <h2>{school.english_name}</h2>
              </div>
            </div>
            <a href={school.internet}>院校官方地址</a>
          </div>

          <ul className={styles.details}>
            <li>
              <span>建校时间：</span>
              <span>{school.create_time}</span>
            </li>
            <li>
              <span>院校类型：</span>
              <span>{school.type_name}</span>
            </li>
            <li>
              <span>院校性质：</span>
              <span>{school.nature_name}</span>
            </li>
            <li>
              <span>是否教育部认证：</span>
              <span>{school.attestation ? "获得认证" : "未获得认证"}</span>
            </li>
            <li>
              <span>学生人数：</span>
              <span>{school.student_num}人</span>
            </li>
            <li>
              <span>学位课程：</span>
              <span>{school.degree_course}</span>
            </li>
            <li>
              <span>国际生比例：</span>
              <span>{school.isr}</span>
            </li>
            <li>
              <span>地理位置：</span>
              <span>{school.address}</span>
            </li>
            <li>
              <span>雅思要求：</span>
              <span>{school.ielts_requires}</span>
            </li>
            <li>
              <span>托福要求</span>
              <span>{school.toeel_requires}</span>
            </li>
          </ul>
        </div>
        <div className={styles.otherInfo}>
          <div>
            <div className={styles.title}>
              <h1>学校概况 / </h1>
              <h2>General Situation of Schools</h2>
            </div>

            <p>{school.overview}</p>
          </div>

          <div>
            <div className={styles.title}>
              <h1>教育理念 / </h1>
              <h2>Educational Ideas</h2>
            </div>
            <p>{school.philosophy}</p>
          </div>

          <div className={styles.faculty}>
            <div className={styles.title}>
              <h1>学部 / </h1>
              <h2>Ministry of Education</h2>
            </div>

            <ul>
              {
                collegeFaculty.map((item: any) => {
                  return item.facultyType === 1
                    ? <li
                      key={item.id}
                      onClick={() => this.props.history.push(`/pass_rate/faculty/${school.id}/${item.id}`)}
                    >
                      <h1>{item.deptName}</h1>
                      <h2>{item.examContent}</h2>
                    </li>
                    : null
                })
              }
            </ul>
          </div>

          <div className={styles.major}>
            <div className={styles.title}>
              <h1>大学院 / </h1>
              <h2>Major College</h2>
            </div>
            <ul>
              {
                collegeFaculty.map((item: any) => {
                  return item.facultyType === 2
                    ? <li
                      key={item.id}
                      onClick={() => this.props.history.push(`/pass_rate/major/${school.id}/${item.id}`)}
                    >
                      <h1>{item.deptName}</h1>
                      <h2>{item.examContent}</h2>
                    </li>
                    : null
                })
              }
            </ul>
          </div>
        </div>
        <Navigate />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schools: state.schools
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Intro);