/**
 * 修改微信账号
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/modify_name.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import { updateUserInfo, } from './../../../redux/mycenter.reducer'

const Item: React.ElementType = List.Item;


export interface IModifyWXProps {
  userInfo: any,
  updateUserInfo: any
  history: any,
}

export interface IModifyWXState {
  wxCode: string;
}

class ModifyWX extends React.Component<IModifyWXProps, IModifyWXState> {
  constructor(props: IModifyWXProps) {
    super(props);

    this.state = {
      wxCode: 'SNGN_315'
    }
  }
  componentDidMount() {
    this.setState({ wxCode: this.props.userInfo.wxCode });
  }

  handleChangeWxCode = (value: string) => {
    this.setState({ wxCode: value });
  }

  submitWxCodeChange = () => {
    let userInfo = JSON.parse(JSON.stringify(this.props.userInfo));
    userInfo.wxCode = this.state.wxCode;
    this.props.updateUserInfo(userInfo).then(
      (res: any) => {
        if (res.code === 200) {
          this.props.history.push('/center');
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.modifyNameContainer} modifyNameContainer`}>
        <div className={styles.nickNameWrap}>
          <List>
            <Item extra={<InputItem
              placeholder='请输入微信账号'
              value={this.state.wxCode} onChange={this.handleChangeWxCode}></InputItem>}>微信账号</Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn'
            onClick={this.submitWxCodeChange}
          >确定</Button>
        </div>
        <Navigate type='white'></Navigate>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  updateUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyWX);
