/**
 * 课程分类
 */
import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_COURSE_TYPE = 'GET_COURSE_TYPE';
type GET_COURSE_TYPE = typeof GET_COURSE_TYPE;

const GET_COURSE_LIST = 'GET_COURSE_LIST';
type GET_COURSE_LIST = typeof GET_COURSE_LIST;

const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
type GET_COURSE_DETAILS = typeof GET_COURSE_DETAILS;

export interface ICourseSubType {
  children: any
  childrenArray: any
  childrenNameList: any
  classifyType: number
  id: string
  name: string
  parentId: string
}

export interface ICourseType {
  children: ICourseSubType[]
  childrenArray: any
  childrenNameList: string
  classifyType: number
  id: string
  name: string
  parentId: any
  image:string
}

export interface ICourseItem {
  courseDis: string
  courseType1: string
  courseType2: string
  coverImageUrl: string
  id: string
  overview: string
  price: string
  program: string
  status: number
  teacherIds: any
  timeTypes: string
  title: string
  totalLength: string
  videoCourseDis: any
  videoCourseTeacher: []
  videoCourseTimeTypes: any
  videoTeachers: any
  buyState: number
}

export interface ICourseStoreState {
  courseList: ICourseItem[]
  courseTypeList: ICourseType[]
  courseDetails: any
}


const initState: ICourseStoreState = {
  courseList: [],
  courseTypeList: [],
  courseDetails: {
    courseDis: "",
    courseType1: "",
    courseType2: "",
    coverImageUrl: "",
    id: "",
    overview: "",
    price: "",
    program: "",
    status: 0,
    teacherIds: [],
    timeTypes: "",
    title: "",
    totalLength: "",
    videoCourseDis: [],
    videoCourseTeacher: [],
    videoCourseTimeTypes: [],
    videoTeachers: [],
  }
}

export function course(state = initState, action: any): ICourseStoreState {
  switch (action.type) {
    case GET_COURSE_TYPE:
      return { ...state, courseTypeList: action.courseTypeList }
    case GET_COURSE_LIST:
      return { ...state, courseList: action.courseList }
    case GET_COURSE_DETAILS:
      return { ...state, courseDetails: action.courseDetails }
  }
  return state;
}

/**
 * 获取课程类型列表
 */
export function getAllCourseTypeList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_classify/findClassify',
      data: {
        classifyType: 3
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_COURSE_TYPE, courseTypeList: data || [] })
      }
    })
  }
}

/**
 * 获取课程列表
 */
export function getAllCourseList(courseType: string, courseSubType: string) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_videoCourse/getVideoCourse',
      data: {
        pager: 1,
        size: 100,
        courseType1: courseType,
        courseType2: courseSubType,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_COURSE_LIST, courseList: data.list || [] })
      }
    })
  }
}

/**
 * 获取课程详情
 */
export function getCourseDetails(courseId: string) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_videoCourse/getVideoCourseById',
      data: {
        id: courseId
      }
    }).then((res: any) => {
      if (res.success && res.data) {
        dispatch({ type: GET_COURSE_DETAILS, courseDetails: res.data })
      }
    })
  }
}


/**
 * 获取课程详情
 */
export function cleanCourseDetails(courseId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_COURSE_DETAILS, courseDetails: initState.courseDetails })
  }
}
