
/**
 * 合格学员 模块 路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";
import Trainees from './trainees';
import TraineesDetails from './trainees_details';

const TraineesFrame: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      {/* 课程列表 */}
      <Route path={`${pathname}`} exact component={Trainees} />
      {/* 课程介绍 */}
      <Route path={`${pathname}/:stuId`} component={TraineesDetails} />
    </Switch>
  );
}

export default TraineesFrame;