/**
 * 咨询列表
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/consult_reply.module.scss';
import { Button, Toast } from 'antd-mobile';
import {
  getConsultDetail,
  replyConsult,
} from './../../../redux/mycenter.reducer';

export interface IConsultDetailsProps {
  history: any
  getConsultDetail: any
  userInfo: any
  consult: any
  replyConsult: any
}

export interface IConsultDetailsState {
  messageDetails: any
  askMessage: string
}

class ConsultList extends React.Component<IConsultDetailsProps, IConsultDetailsState> {
  constructor(props: IConsultDetailsProps) {
    super(props);

    this.state = {
      messageDetails: {},
      askMessage: ''
    }
  }

  componentDidMount() {
    this.props.getConsultDetail(this.props.consult.id).then(
      (res: any) => {
        console.log(res)
        if (res.code === 200) {
          this.setState({ messageDetails: res.data })
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  submit = () => {
    let data = {
      id: this.props.consult.id,
      teacherId: this.props.userInfo.id,
      askMessage: this.state.askMessage
    };
    this.props.replyConsult(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('回复成功')
          this.props.history.push('/center/consult_list');
        } else {
          Toast.info(res.message)
        }
      }
    );
  }
  public render() {
    return (
      <div className={styles.faqsDetailsContainer}>
        <div className={styles.faq}>
          <div className={styles.question}>
            <h1>[咨询消息] {this.state.messageDetails.question}</h1>
            <p className={styles.date}>{this.state.messageDetails.conDate}</p>
            {/* <p className={styles.questionInfo}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic</p> */}
          </div>
          <div className={styles.answer}>
            <p>回答内容：</p>
            <textarea className={styles.textarea} placeholder="请输入咨询回答内容"
              value={this.state.askMessage}
              onChange={(e: any) => { this.setState({ askMessage: e.target.value }) }}
            />
          </div>
          <div className={styles.buttonWrap}>
            <Button type='primary' inline className='fullBtn'
              onClick={this.submit}
            >确认回复</Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  console.log(state)
  return {
    consult: state.myCenter.consult,
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getConsultDetail,
  replyConsult,
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultList);
