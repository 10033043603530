/**
 * 大学院详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/pass_rate/major.module.scss';
// import photo from './../../static/img/优质学校/photo.png';
import { ISchool, ISchoolsStoreState } from '../../redux/school.reducer';
import { ITeacherItem } from '../../redux/teachers.reducer';

export interface IMajorProps {
  history: any,
  match: any,
  schools: ISchoolsStoreState
}

export interface IMajorState {
  deptName: string
  examContent: string
  examDate: string
  examMenu: any
  facultyType: number
  id: string
  qualifiedContent: string
  qualifiedDate: string
  teachers: any
  wishCondition: string
  wishDate: string
}

class Major extends React.Component<IMajorProps, IMajorState> {
  private schoolId: string;
  private facultyId: string;
  constructor(props: IMajorProps) {
    super(props);
    this.schoolId = props.match.params.schoolId
    this.facultyId = props.match.params.facultyId
    this.state = {
      deptName: "",
      examContent: "",
      examDate: "",
      examMenu: "",
      facultyType: 1,
      id: "",
      qualifiedContent: "",
      qualifiedDate: "",
      teachers: [],
      wishCondition: "",
      wishDate: "",
    }
  }

  componentDidMount() {
    let schoolList = this.props.schools.schoolList;
    try {
      let school: ISchool = schoolList.filter((item: ISchool) => {
        return item.id == this.schoolId;
      })[0]
      // debugger
      if (school && school.collegeFaculty) {
        let faculty = school.collegeFaculty.filter((item: any, index: number) => {
          return item.facultyType === 2 && item.id == this.facultyId;
        })

        if (faculty.length) {
          faculty = faculty[0]
          this.setState({ ...faculty })
        } else {
          throw "没有查询到当前学部";
        }
      } else {
        throw "没有查询到当前学部";
      }

    } catch (error) {
      this.props.history.push(`/pass_rate`);
      return;
    }
  }

  public render() {
    const teachers = this.state.teachers || []
    return (
      <div className={styles.container}>
        <div className={styles.baseInfo}>
          <h1>{this.state.deptName}</h1>
          <p>
            <span>出愿时间：</span>
            <span>{this.state.wishDate}</span>
          </p>
          <p>
            <span>出愿条件：</span>
            <span>{this.state.wishCondition}</span>
          </p>
          <p>
            <span>考试时间：</span>
            <span>{this.state.examDate}</span>
          </p>
          <p>
            <span>合格发表：</span>
            <span>{this.state.qualifiedDate}</span>
          </p>
        </div>

        <div className={styles.exam}>
          <h2>考试内容</h2>
          <p>{this.state.examContent}</p>
        </div>
        <div className={styles.teacher}>
          <h2>推荐辅导老师：</h2>
          <ul>
            {
              teachers.length
                ? teachers.map((item: ITeacherItem) => {
                  return <li>
                    <div className={styles.pic}>
                      <img src={item.headImageUrl} alt={item.name} />
                    </div>
                    <div>
                      <h3>{item.name}</h3>
                      <p>{item.university}</p>
                    </div>
                  </li>
                }) : <p>暂无讲师介绍</p>
            }
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schools: state.schools
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Major);
