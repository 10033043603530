/**
 * 合格学员
 */
import * as React from 'react';
import { connect } from 'react-redux';
import {Toast} from 'antd-mobile';
import styles from './../../styles/trainees/trainees.module.scss';
// import Hgxy from './../../static/img/hgxy.png';
import Navigate from './../../components/common/navigate/navigate'
import { IStudentsStoreState, IStudentItem, getAllStudentList } from '../../redux/students.reducer';
import config from '../../config/app.config';

export interface ITraineesProps {
  history: any,
  match: any,
  students: IStudentsStoreState,
  getAllStudentList: any
}

export interface ITraineesState {
}

class Trainees extends React.Component<ITraineesProps, ITraineesState> {
  constructor(props: ITraineesProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.getAllStudentList()
  }

  public render() {
    const pathname = this.props.match.url;
    const { students } = this.props;
    const studentList: IStudentItem[] = students.studentList
    return (
      <div className={styles.container}>
        <header>
          <h1>合格学员</h1>
          <h2>合格者インタビュー</h2>
        </header>
        <ul>
          {
            studentList.map((item: IStudentItem, index) => {
              return <li
                key={item.id}
                className={styles.stuItem}
                onClick={() => {
                  if(item.interviewSet&&item.interviewSet.length > 0){
                    this.props.history.push({
                      pathname:`traineesDetail`,
                      state:{
                        item:item
                      }
                    })
                  }else{
                    Toast.info('该学员暂无访谈信息！')
                  }
                }}
              >
                <img src={config.cdnService + item.headImageUrl} alt={item.name} />
                <div className={styles.intro}>
                  <h3>{item.name}</h3>
                  <p>东稻{item.grade}届学员</p>
                </div>
                <p>{item.collegeName}-{item.collegeSubject}</p>
                <button>学员访谈</button>
              </li>
            })
          }
        </ul>
        <Navigate />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    students: state.students
  }
}

const mapDispatchToProps = {
  getAllStudentList
}

export default connect(mapStateToProps, mapDispatchToProps)(Trainees);