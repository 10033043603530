/**
 * 普通用户登录页
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Toast } from "antd-mobile";
import $ from './../../util/requst';
import styles from './../../styles/login/login.module.scss';
import { login } from '../../redux/user.reducer';
import logo from './../../static/img/logo.png';

interface IN_LoginState {
  loginType: number,
  account: string,
  pwd: string,
  phoneNum: string,
  captcha: string,
  time: number,
  allowSendMsg: boolean
}
export interface IN_LoginProps {
  history: any,
  login: any
}

class N_Login extends React.Component<IN_LoginProps, IN_LoginState> {
  private timer!: NodeJS.Timer;
  state: IN_LoginState = {
    loginType: 0,
    // account: '18628245665',
    // pwd: '123456',
    account: '',
    pwd: '',
    phoneNum: '',
    captcha: '',
    time: 60,
    allowSendMsg: true,
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  /**
   * 切换登录类型
   */
  toggleLoginType = (type: number) => {
    if (type === this.state.loginType) return;
    this.setState({ loginType: type })
  }

  /**
   * 点击登录
   */
  handleLogin = () => {
    sessionStorage.removeItem('_t')
    if (this.state.loginType === 0) {
      let { account, pwd } = this.state;
      if (!account || !pwd) {
        Toast.info('请输入用户名或密码！')
      }
      this._login(1, account, pwd);
      return;
    }
    let { phoneNum, captcha } = this.state;
    if (!phoneNum || !captcha) {
      Toast.info('请输入手机号和验证码！')
    }
    this._login(4, phoneNum, captcha);
  }

  /**
   * 点击发送验证短信
   */
  handleClickSendMsg() {
    let { phoneNum } = this.state;
    if (!phoneNum) {
      Toast.info('请输入手机号')
      return;
    }

    return $.post({
      url: '/sms/smsduanxin',
      data: {
        phone: phoneNum,
      }
    }).then((res: any) => {
      if (res.success) {
        Toast.info("发送成功");
        this.setState({ allowSendMsg: false });
        let time = this.state.time;
        this.timer = setInterval(() => {
          if (time === 0) {
            clearInterval(this.timer);
            this.setState({ allowSendMsg: true })
            return;
          }
          this.setState({ time: time-- })
        }, 1000);
      } else {
        Toast.info(res.message);
      }
    })
  }

  /**
   * 登录接口
   * @param radio string 1学生普通登录 2老师普通登录 3后台普通登录 4验证码登录
   * @param account 普通登录时 该字段为 用户账号。验证码登录时 该字段为手机号
   * @param pwd 普通登录时 该字段为 用户密码。验证码登录时 该字段为验证码
   */
  _login(radio: number, account: string, pwd: string) {
    return this.props.login({
      username: account,
      password: pwd,
      radio: radio
    }).then((res: any) => {
      if (res.success && res.data) {
        sessionStorage.isTeacher = false;
        sessionStorage._t = res.data.token;
        this.props.history.push('/')
      } else {
        throw res.message;
      }
    }).catch((err:any) => {
      Toast.info(err);
    })
  }

  /**
   * 受控更新 state内的account 和pwd
   * @param key string
   * @param e Object 
   */
  refState(key: string, e: any) {
    let obj: any = {}
    obj[key] = e.target.value;
    this.setState({ ...obj })
  }

  public render() {
    const { loginType, allowSendMsg, time } = this.state;
    return (
      <div className={styles.loginContainer}>
        <div className={styles.header}>
          <span
            onClick={() => this.props.history.push('/register')}
            className={styles.logon}
          >注册</span>
          <span
            onClick={() => this.props.history.push('/t')}
            className={styles.enteranceSwitch}
          >教师用户登录入口</span>
        </div>
        <div className={styles.logoWrap}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.loginWrap}>
          <div className={styles.warpBtn}>
            <p
              style={{
                color: loginType === 0 ? '#7DC9D7' : ''
              }}
              onClick={() => this.toggleLoginType(0)}
            >普通用户</p>
            <p
              style={{
                color: loginType === 1 ? '#7DC9D7' : ''
              }}
              onClick={() => this.toggleLoginType(1)}
            >快速登录</p>
          </div>
          {
            loginType === 0
              ? <div className={styles.formGroup}>
                {/* 普通登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入账号"
                    onChange={this.refState.bind(this, 'account')}
                    value={this.state.account}
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="password"
                    placeholder="请输入密码"
                    onChange={this.refState.bind(this, 'pwd')}
                    value={this.state.pwd}
                  />
                </div>
              </div>
              : <div className={styles.formGroup}>
                {/* 快速登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    onChange={this.refState.bind(this, 'phoneNum')}
                    value={this.state.phoneNum}
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入验证码"
                    onChange={this.refState.bind(this, 'captcha')}
                    value={this.state.captcha}
                  />
                  <button
                    disabled={!allowSendMsg}
                    onClick={this.handleClickSendMsg.bind(this)}
                  >{
                      allowSendMsg
                        ? "获取验证码"
                        : `${time}S`
                    }</button>
                </div>
              </div>
          }

          <div className={styles.btnContainer}>
            <Button
              type="primary"
              onClick={this.handleLogin}
            >登录</Button>
          </div>
        </div>
        {/* 忘记密码 和快速登录 toogle*/}
        {
          loginType === 0
            ? <div className={styles.forget}>
              <p onClick={() => this.props.history.push('/forget')}>忘记密码</p>
            </div>
            : null
            // <div className={styles.quickLogin}>
            //   <p>—&nbsp;其他登录方式&nbsp;—</p>
            //   <div className={styles.quickLoginBtnGroup}>
            //     <button >
            //       <i className="iconfont iconweixin1"></i>
            //     </button>
            //     <button>
            //       <i className="iconfont iconqq"></i>
            //     </button>
            //   </div>
            // </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    // teachers: state.teachers
  }
}

const mapDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapDispatchToProps)(N_Login);
