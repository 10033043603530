/**
 * 教师修改手机号
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button, Toast } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/modify_name.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import { updateUserInfo, } from './../../../redux/mycenter.reducer';

const Item: React.ElementType = List.Item;

export interface IT_ModifyPhoneProps {
  userInfo: any
  updateUserInfo: any
  history: any
}

export interface IT_ModifyPhoneState {
  phoneNumber: string
}

class T_ModifyPhone extends React.Component<IT_ModifyPhoneProps, IT_ModifyPhoneState> {
  constructor(props: IT_ModifyPhoneProps) {
    super(props);

    this.state = {
      phoneNumber: '15046461539'
    }
  }

  componentDidMount() {
    this.setState({ phoneNumber: this.props.userInfo.phone });
  }

  submitModyfyPhone = () => {
    let data = JSON.parse(JSON.stringify(this.props.userInfo));
    data.phone = this.state.phoneNumber;
    this.props.updateUserInfo(data).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('修改成功');
          this.props.history.push('/center');
        } else {
          Toast.info(res.message)
        }
      }
    );
  }

  public render() {
    return (
      <div className={`${styles.modifyNameContainer} modifyNameContainer`}>
        <div className={styles.nickNameWrap}>
          <List>
            <Item className='phoneInputWrap' extra={<InputItem className={styles.input}
              placeholder='请输入手机账号'
              value={this.state.phoneNumber}
              onChange={(value) => { this.setState({ phoneNumber: value }) }}></InputItem>}>手机账号</Item>
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={this.submitModyfyPhone}>确定</Button>
        </div>
        <Navigate type='white'></Navigate>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  updateUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(T_ModifyPhone);
