/**
 * 全部学生数据
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_STUDENT_LIST = 'GET_ALL_STUDENT_LIST';
type GET_ALL_STUDENT_LIST = typeof GET_ALL_STUDENT_LIST;

const GET_STUDENT_DETAIL = 'GET_STUDENT_DETAIL';
type GET_STUDENT_DETAIL = typeof GET_STUDENT_DETAIL;

export interface IStudentItem {
  collegeName: string
  collegeSubject: string
  grade: string
  headImageUrl: string
  id: string
  indexComm: boolean
  interview: boolean
  interviewArray: any
  interviewSet: any
  name: string
}

export interface IStudentsStoreState {
  studentList: IStudentItem[],
  studentDetail: any
}


const initState: IStudentsStoreState = {
  studentList: [],
  studentDetail: {}
}

export function students(state = initState, action: any): IStudentsStoreState {
  switch (action.type) {
    case GET_ALL_STUDENT_LIST:
      return { ...state, studentList: action.studentList }
    case GET_STUDENT_DETAIL:
      return { ...state, studentDetail: action.studentDetail }
  }
  return state;
}

/**
 * 获取全部优质学校列表
 */
export function getAllStudentList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_qualified/getStudentQualifiedList',
      data: {
        pager: 1,
        size: 100
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_STUDENT_LIST, studentList: data.list || [] })
      }
    })
  }
}
export function getStudentById (id: any){
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_qualified/getStudentQualifiedById',
      data: {
        id:id
      }
    }).then((res: any) => {
      if(res.success &&  res.data){
        dispatch({ type: GET_STUDENT_DETAIL, studentDetail:res.data || {} })
      }
    }) 
  }
} 