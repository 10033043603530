/**
 * 合格学员详情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/trainees/trainees_details.module.scss';
// import Hgxy from './../../static/img/hgxy.png';
import { IStudentsStoreState, IStudentItem } from '../../redux/students.reducer';

export interface ITraineesDetailsProps {
  history: any,
  match: any,
  students: IStudentsStoreState
}

export interface ITraineesDetailsState {
  interviewSet: any[]
}

class TraineesDetails extends React.Component<ITraineesDetailsProps, ITraineesDetailsState> {
  private stuId: string;
  constructor(props: ITraineesDetailsProps) {
    super(props);
    this.stuId = props.match.params.stuId;
    this.state = {
      interviewSet: []
    }
  }

  componentDidMount() {
    try {
      let studentList = this.props.students.studentList;
      if (studentList && this.stuId) {
        let student = studentList.filter((item: IStudentItem) => {
          return item.id === this.stuId;
        })

        if (student.length && student[0].interviewSet) {
          this.setState({ interviewSet: student[0].interviewSet })
          return;
        }
      }
      throw "数据异常";
    } catch (error) {
      this.props.history.push(`/trainees`);
    }
  }

  public render() {
    return (
      <div className={styles.container}>
        {
          this.state.interviewSet.map((item, index) => {
            return <div
              key={item.id}
            >
              <h1>访谈内容</h1>
              <div className={styles.pic}>
                <img src={item.imageUrl} alt="" />
              </div>
              <pre>{item.interview}</pre>
            </div>
          })
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    students: state.students
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineesDetails);