/**
 * 答题结果
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/exam_result.module.scss';
import './../../../styles/center/antd_modify.scss';
import { Button } from 'antd-mobile';



export interface IExamResultProps {
  history: any
  exam: any
}

export interface IExamResultState {
}

class About extends React.Component<IExamResultProps, IExamResultState> {
  constructor(props: IExamResultProps) {
    super(props);
  }



  public render() {
    return (
      <div className={styles.examResultContainer}>
        <div className={styles.header}>
          <div className={styles.titleDateWrap}>
    <h2 className={styles.title}>{this.props.exam.paperstype}</h2>
            <h3 className={styles.date}>{this.props.exam.endtime}</h3>
          </div>
    <p className={styles.total}>总分：<span className={styles.point}>&nbsp;{this.props.exam.score}&nbsp;</span>分</p>
        </div>
        <div className={styles.content}>
          <h3 className={styles.contentTitle}>综合情况</h3>
    <p className={styles.examHeadcount}>本次综合考试人数：{this.props.exam.summary.stutotal}人</p>
          <div className={styles.pointAnalyse}>
            <p>平均分：{this.props.exam.summary.avgscore}分</p>
            <p>最高分：{this.props.exam.summary.maxscore}分</p>
            <p>最低分：{this.props.exam.summary.minscore}分</p>
          </div>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={() => this.props.history.push('/center/exam_analyse')}>查看考试试题解析</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    exam: state.myCenter.exam
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
