/**
 * 全部学校数据
 */
import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_SCHOOL_LIST = 'GET_ALL_SCHOOL_LIST';
type GET_ALL_SCHOOL_LIST = typeof GET_ALL_SCHOOL_LIST;

export interface ISchool {
  address: string, // 地址
  attestation: boolean,
  collegeFaculty: any,
  collegeFacultyArray: any,
  collegeImage: any,
  collegeImageArray: any,
  create_time: string,
  degree_course: string,
  emblem_url: string,
  english_name: string,
  id: string,
  ielts_requires: string,
  internet: string,
  isr: string,
  name: string,
  nature_name: string,
  overview: string,
  philosophy: string,
  student_num: number,
  toeel_requires: string
  type_name: string
}

export interface ISchoolsStoreState {
  schoolList: ISchool[]
}


const initState: ISchoolsStoreState = {
  schoolList: []
}

export function schools(state = initState, action: any): ISchoolsStoreState {
  switch (action.type) {
    case GET_ALL_SCHOOL_LIST:
      return { ...state, schoolList: action.schoolList }
  }
  return state;
}

/**
 * 获取全部优质学校列表
 */
export function getAllSchoolList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_college/collegeList',
      data: {
        pager: 1,
        size: 100
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_SCHOOL_LIST, schoolList: data.list || [] })
      }
    })
  }
}