/**
 * 用户注册
 */
import * as React from 'react';
import { Button, Checkbox, Toast } from 'antd-mobile';
import $ from './../../util/requst';
import styles from './../../styles/login/login.module.scss';
import logo from './../../static/img/logo.png';
import { OnChangeParams } from 'antd-mobile/lib/checkbox/PropsType';


export interface IRegisterProps {
  history: any
}
interface IRegisterState {
  step: number, // 0 || 1 代表注册账号的两段步骤
  phoneNum: string,
  captcha: string,
  repPwd: string,
  pwd: string,
  allowRegister: boolean,
  time: number,
  allowSendMsg: boolean
}


export default class Register extends React.Component<IRegisterProps, IRegisterState> {
  private timer!: NodeJS.Timer;
  state: IRegisterState = {
    step: 0,
    phoneNum: "",
    captcha: "",
    pwd: "",
    repPwd: "",
    allowRegister: false,
    time: 60,
    allowSendMsg: true
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  handleClickNext = () => {
    let { phoneNum, captcha } = this.state;
    if (!phoneNum) {
      Toast.info('请输入手机号！');
      return;
    }

    if (!captcha) {
      Toast.info('请输入验证码！');
      return;
    }

    this.setState({
      step: 1
    })
  }

  /**
 * 点击发送验证短信
 */
  handleClickSendMsg() {
    let { phoneNum, allowRegister } = this.state;
    if (!allowRegister) {
      Toast.info("请勾选用户协议");
      return;
    }

    if (!phoneNum) {
      Toast.info('请输入手机号')
      return;
    }

    return $.post({
      url: '/sms/smsduanxin',
      data: {
        phone: phoneNum,
      }
    }).then((res: any) => {
      if (res.success) {
        Toast.info("发送成功");
        this.setState({ allowSendMsg: false });
        let time = this.state.time;
        this.timer = setInterval(() => {
          if (time === 0) {
            clearInterval(this.timer);
            this.setState({ allowSendMsg: true })
            return;
          }
          this.setState({ time: time-- })
        }, 1000);
      } else {
        Toast.info(res.message);
      }
    })
  }

  handleClickRegister() {
    let { phoneNum, pwd, repPwd, captcha } = this.state;
    if (!phoneNum) {
      Toast.info('请输入手机号！');
      return;
    }

    if (!pwd || !repPwd) {
      Toast.info('请输入密码！');
      return;
    }

    if (pwd !== repPwd) {
      Toast.info('两次密码输入不一致！');
      return;
    }

    return $.post({
      url: '/back_student/studentPasswordUpdate',
      data: {
        phone: phoneNum,
        password: pwd,
        code: captcha,
        type: 1,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success) {
        Toast.info("注册成功！")
        this.props.history.goBack();
      } else {
        Toast.info(res.message)
      }
    })
  }

  /**
 * 受控更新 state内的account 和pwd
 * @param key string
 * @param e Object 
 */
  refState(key: string, e: any) {
    let obj: any = {}
    obj[key] = e.target.value;
    this.setState({ ...obj })
  }

  /**
   * 同意用户协议勾选状态
   */
  handleChecked(e: OnChangeParams) {
    let checked = e.target.checked;
    this.setState({ allowRegister: checked })
  }

  public render() {
    const { step,allowSendMsg,time } = this.state;
    return (
      <div className={styles.loginContainer}>
        <div
          style={{ height: step === 0 ? 'auto' : '.14rem' }}
          className={styles.header}
        >
          {
            step === 0 ?
              <React.Fragment>
                <span
                  onClick={() => this.props.history.push('/n')}
                  className={styles.logon}
                >登录</span>
                <span
                  onClick={() => this.props.history.push('/t')}
                  className={styles.enteranceSwitch}
                >教师用户登录入口</span>
              </React.Fragment>
              : ''
          }
        </div>
        <div className={styles.logoWrap}>
          <img src={logo} alt="" />
        </div>

        {
          this.state.step === 0
            ? <div className={styles.loginWrap}>
              <div className={styles.formGroup}>
                {/* 快速登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    onChange={this.refState.bind(this, 'phoneNum')}
                    value={this.state.phoneNum}
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入验证码"
                    onChange={this.refState.bind(this, 'captcha')}
                    value={this.state.captcha}
                  />
                  <button
                    disabled={!allowSendMsg}
                    onClick={this.handleClickSendMsg.bind(this)}
                  >
                    {
                      allowSendMsg
                        ? "获取验证码"
                        : `${time}S`
                    }
                  </button>
                </div>
              </div>
              <div
                style={{
                  height: step === 0 ? 'auto' : 16,
                  // display:'flex',
                  width: '74.4%'
                  // pointerEvents: 'none'
                }}>
                {
                  step === 0 ?
                    <React.Fragment>
                      <Checkbox
                        checked={this.state.allowRegister}
                        onChange={this.handleChecked.bind(this)}
                      />
                      <span>勾选代表您同意</span>
                      <a style={{ color: '#3298DF' }} href="">《注册用户使用协议》</a>
                    </React.Fragment>
                    : ''
                }
              </div>
              <div
                className={styles.btnContainer}
              >
                <Button
                  onClick={this.handleClickNext}
                  type="primary"
                >下一步</Button>
              </div>
            </div>
            : <div className={styles.loginWrap}>
              <div className={styles.formGroup}>
                {/* 快速登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="password"
                    placeholder="请输入密码"
                    onChange={this.refState.bind(this, 'pwd')}
                    value={this.state.pwd}
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="password"
                    placeholder="请再次输入密码"
                    onChange={this.refState.bind(this, 'repPwd')}
                    value={this.state.repPwd}
                  />
                </div>
              </div>
              <div className={styles.btnContainer}>
                <Button
                  onClick={this.handleClickRegister.bind(this)}
                  type="primary"
                >注册</Button>
              </div>
            </div>
        }
      </div>
    );
  }
}
