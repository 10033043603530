import { combineReducers } from 'redux';
import { introduce } from './introduce.reducer';
import { schools } from './school.reducer';
import { teachers } from './teachers.reducer';
import { students } from './students.reducer';
import { consults } from './consult.reducer';
import { course } from './course.reducer';
import { examStore } from './exam.reducer';
import { news } from './news.reducer';
import { user } from './user.reducer';
import { myCenter } from './mycenter.reducer';
import { person } from './person.reducer';
import { myCourse } from './myCourse.resucer';
import { address } from './contact.reducer';
import { addressDetail } from './address.reducer';
import { customer } from './customer.reducer';

export default combineReducers({
  introduce, // 应用介绍信息
  schools, // 学校列表
  teachers, // 教师列表
  students, // 教师列表
  news, // 最新消息列表
  user, // 用户登录数据
  myCenter, // 个人中心
  person, // 个人信息
  consults, // 咨询列表
  course, // 课程信息
  myCourse, // 我的课程
  examStore, // 模拟考试信息信息
  address,
  addressDetail,
  customer
})
