/* 
搜索框组件
 */
import * as React from 'react';
import styles from './search_input.module.scss';
import { Icon } from 'antd-mobile';

interface ISearchInputProps {
  placeholder?: string
  maxLength?: number
  disabled?: boolean
  onSubmit?: (e: any) => void
}


const SearchInput: React.FC<ISearchInputProps> = (props: ISearchInputProps) => {
  const placeholder: string = props.placeholder || '请输入';
  const handleOnSubmit = (e: any) => {
    if (props.onSubmit && e.keyCode === 13) {
      props.onSubmit(e.target.value);
    }
  }

  return (
    <div className={styles.inputWrap}>
      <input className={styles.input} type="text"
        placeholder={placeholder}
        onKeyDown={handleOnSubmit}
        maxLength={props.maxLength}
        disabled={props.disabled} />
      <Icon color="rgba(119, 199, 214, 1)" className={styles.searchIcon} type="search"></Icon>
    </div>
  )
}

export default SearchInput