/**
 * 首页 介绍 推荐 数据
 * 首页 平台介绍 轮播图等
 */

import { Dispatch } from 'redux';
import { Type } from 'typescript';
import $ from './../util/requst';
import { ICourseType } from './course.reducer';
// import { Interface } from 'readline';

const GET_PLATFORM_INTRO = 'GET_PLATFORM_INTRO';
type GET_PLATFORM_INTRO = typeof GET_PLATFORM_INTRO;

const GET_TEACHER_LIST = 'GET_TEACHER_LIST';
type GET_TEACHER_LIST = typeof GET_TEACHER_LIST;

const GET_PASSRATE_LIST = 'GET_PASSRATE_LIST'
type GET_PASSRATE_LIST = typeof GET_PASSRATE_LIST

const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
type GET_ACTIVITY_LIST = typeof GET_ACTIVITY_LIST;

const GET_TEXTBOOK_LIST = 'GET_TEXTBOOK_LIST'
type GET_TEXTBOOK_LIST = typeof GET_TEXTBOOK_LIST

const GET_PARTNER_LIST = 'GET_PARTNER_LIST';
type GET_PARTNER_LIST = typeof GET_PARTNER_LIST;

const GET_STUDENT_LIST = 'GET_STUDENT_LIST';
type GET_STUDENT_LIST = typeof GET_STUDENT_LIST;

const GET_ALL_HOME = 'GET_ALL_HOME';
type GET_ALL_HOME = typeof GET_ALL_HOME;

const GET_COURSE_INTRODUCE = 'GET_COURSE_INTRODUCE';
type GET_COURSE_INTRODUCE = typeof GET_COURSE_INTRODUCE;

const GET_BANNER_IMAGE = 'GET_BANNER_IMAGE';
type GET_BANNER_IMAGE = typeof GET_BANNER_IMAGE;

export interface PlatformIntro {
  url: string,
  id: string
}

export interface ITeacher {
  name: string,
  nick: string,
  id: string,
  head_image_url: string,
  university: string,
  major: string,
  headImageUrl: string
}

export interface IActivity {
  activityImageUrl: string,
  id: string,
  linkUrl: string
}
export interface IPassrate {
  address: string,
  attestation: boolean,
  collegeFaculty: any,
  collegeFacultyArray: any,
  collegeImage: any,
  collegeImageArray: any,
  create_time: string,
  degree_course: string,
  emblem_url: string,
  english_name: string,
  id: number,
  ielts_requires: string,
  internet: string,
  isr: string,
  name: string,
  nature_name: string,
  overview: string,
  pass_rate: string,
  philosophy: string,
  student_num: string,
  toeel_requires: string,
  type_name: string
}

export interface IPartner {
  imageUrl: string,
  id: string,
  linkUrl: string
}

export interface IStudent {
  collegeName: string,
  collegeSubject: string,
  grade: string,
  headImageUrl: string,
  id: string,
  indexComm: boolean,
  interview: boolean,
  interviewArray: any[],
  interviewSet: any[],
  name: string
}
export interface IHomeHistory{
  id:number,
  content:string,
  nianfen:string
}
export interface ITextBook{
  bookDes: string,
  bookImageUrl: string,
  id: string | number,
  name: string
}

export interface InroduceStoreState {
  platformIntro: PlatformIntro[],
  teachers: ITeacher[],
  activitys: IActivity[],
  textBook: ITextBook[],
  passrate: IPassrate[],
  // partners: IPartner[][],
  partners: IPartner[],
  students: IStudent[][],
  courseIntroduce: ICourseType[],
  bannerImages: [],

  homeHege: {
    id: number,
    whychoossecontent: string
    images:string
  },
  homeWhy: {
    id: number,
    images:string,
    whychoossecontent: string
  },

  homePage: {
    id: number
    homepageImage: string
    homepageContent: string
  },

  homeHistoryList: IHomeHistory[][],
  // homeHistoryList: {
  //   id: number,
  //   nianfen: string,
  //   content: string
  // }[]
}

const initState: InroduceStoreState = {
  platformIntro: [],
  teachers: [],
  activitys: [],
  textBook:[],
  passrate:[],
  partners: [],
  students: [],
  homeHege: {
    id: 0,
    whychoossecontent: "",
    images:""
  },
  homeWhy: {
    id: 0,
    images:"",
    whychoossecontent: ""
  },
  homePage: {
    id: 0,
    homepageImage: "",
    homepageContent: "",
  },
  homeHistoryList: [],
  courseIntroduce: [],
  bannerImages: []
}


export function introduce(state = initState, action: any): InroduceStoreState {
  switch (action.type) {
    case GET_PLATFORM_INTRO:
      return { ...state, platformIntro: action.platformIntro }
    case GET_TEACHER_LIST:
      return { ...state, teachers: action.teachers }
    case GET_ACTIVITY_LIST:
      return { ...state, activitys: action.activitys }
    case GET_TEXTBOOK_LIST:
      return {...state, textBook: action.textBook}
    case GET_PASSRATE_LIST:
      return { ...state, passrate: action.passrate }
    case GET_PARTNER_LIST:
      return { ...state, partners: action.partners }
    case GET_STUDENT_LIST:
      return { ...state, students: action.students }
    case GET_ALL_HOME:
      return { ...state, ...action.allIntroduce }
    case GET_COURSE_INTRODUCE:
      return { ...state, courseIntroduce: action.courseIntroduce }
    case GET_BANNER_IMAGE:
      return { ...state, bannerImages: action.bannerImages }
  }
  return state;
}

/**
 * 获取首页的平台介绍背景图
 * 历史沿革、合格率、学院介绍
 */
export function getPlatformIntro() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_home/getHomeIntroduce',
      data: {
        id: 2333
      }
    }).then((res: any) => {
      dispatch({ type: GET_PLATFORM_INTRO, platformIntro: [] })
    })
  }
}

/**
 * 获取全部学校推荐列表
 */
export function getTeacherList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacher/teacherList',
      data: {
        pager: 1,
        size: 100,
        indexComm: 1
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_TEACHER_LIST, teachers: data.list || [] })
      }
    })
  }
}
// 学院合格率
export function Getpassrate() {
  return (dispatch: Dispatch)=>{
    return $.get({
      url:'/back_college/getCollegePassRate',
    }).then((res: any)=> {
      let data = res.data
      if (res.success && data) {
        dispatch({ type: GET_PASSRATE_LIST, passrate: data || [] })
      }
    })
  }
}
/**
 * 获取首页的活动列表
 */
export function getActivityList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_activity/getActivity',
      data: {
        pager: 1,
        size: 100
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ACTIVITY_LIST, activitys: data.list || [] })
      }
    })
  }
}
// 获取原创教科书列表
export function getTextBookList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_textbook/getTextbook',
      data: {
        pager: 1,
        size: 100
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_TEXTBOOK_LIST, textBook: data.list || [] })
      }
    })
  }
}

/**
 * 获取首页的合作伙伴列表
 */
export function getPartnerList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_information/getPartner',
      data: {
      }
    }).then((res: any) => {
      dispatch({ type: GET_PARTNER_LIST, partners: res.data || [] })
      // let data = res.data;
      // if (res.success && data.length) {
      //   let len = data.length;
      //   let size = 1;
      //   let format: any[] = [];
      //   if (len > 2) {
      //     size = Math.ceil(len / 2);
      //   }
      //
      //   for (let index = 0; index < size; index++) {
      //     format.push(data.splice(0, 2))
      //   }
      //
      //   dispatch({ type: GET_PARTNER_LIST, partners: format || [] })
      // }
    })
  }
}


/**
 * 获取首页的优秀学生列表
 */
export function getStudentList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_qualified/getStudentQualifiedList',
      data: {
        pager: 1,
        size: 100,
        indexComm: 1
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data.list.length) {
        let list = data.list
        let len = list.length;
        let size = 1;
        let format: any[] = [];
        if (len > 2) {
          size = Math.ceil(len / 2);
        }

        for (let index = 0; index < size; index++) {
          format.push(list.splice(0, 2))
        }

        dispatch({ type: GET_STUDENT_LIST, students: format || [] })
      }
    })
  }
}

/**
 * 获取首页全部配置信息
 */

export function getAllHome() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_home/getAllHome',
      data: {
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success) {
        if(data.homeHistoryList){
            let list = data.homeHistoryList;
            let len = list.length;
            let size = 1;
            let format: any[] = [];
            if (len > 2) {
              size = Math.ceil(len / 2);
            }
            for (let index = 0; index < size; index++) {
              format.push(list.splice(0, 2));
            }
            data.homeHistoryList = format;
        }
        dispatch({ type: GET_ALL_HOME, allIntroduce: data })
      }
    })
  }
}

/**
 * 获取首页轮播图
 */
export function getBannerImage() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_home/getHomePage',
      data: {
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success) {
        dispatch({ type: GET_BANNER_IMAGE, bannerImages: data })
      }
    })
  }
}
