/**
 * 关于我们
 */

 import * as React from 'react';
 import styles from './../../styles/center/about.module.scss';
 import headerBanner from './../../static/img/个人中心/img3.png';
 
 export interface IAboutProps {
 }
 
 export interface IAboutState {
 }
 
 export default class About extends React.Component<IAboutProps, IAboutState> {
   constructor(props: IAboutProps) {
     super(props);
 
     this.state = {
     }
   }
 
   public render() {
     return (
       <div className={styles.aboutContainer}>
         <header>
           <img src={headerBanner} alt="error"/>
         </header>
         <div className={styles.content}>
           <ul>
             <li className={styles.contact}>
               <p>联系电话</p>
               <p>028-22556965</p>
             </li>
             <li className={styles.address}>
               <p>地址</p>
               <p>東京都新宿区市谷田町2-17八重洲市谷ビル〒169-0075</p>
             </li>
             <li className={styles.contact}>
               <p>QQ客服</p>
               <p>7456221536</p>
             </li>
           </ul>
         </div>
       </div>
     );
   }
 }
 