
/**
 * 咨询解答路由配置
 */
import * as React from 'react';
import { Route, Switch } from "react-router-dom";
import ConsultList from './consult_list';
import ConsultDetails from './consult_details';
import Consulting from './consulting';

const ConsultFrame: React.FC = (props: any) => {
  const pathname: string = props.match.path;
  return (
    <Switch>
      <Route path={`${pathname}`} exact component={ConsultList} />
      <Route path={`${pathname}/consult_details/:consultId`} component={ConsultDetails} />
      <Route path={`${pathname}/consulting/:teacherId`} component={Consulting} />
    </Switch>
  );
}

export default ConsultFrame;