/**
 * 咨询列表
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_ADDRESS_LIST = 'GET_ALL_ADDRESS_LIST';
type GET_ALL_ADDRESS_LIST = typeof GET_ALL_ADDRESS_LIST;


export interface IAddressItem {
  id: string
  name: string
  address: string
  onlinePhone: string
  onlineqq: string
  locationImage: string
  campusImage: string
}

export interface IAddressStoreState {
  addressList: IAddressItem[]
}


const initState: IAddressStoreState = {
  addressList: []
}

export function address(state = initState, action: any): IAddressStoreState {
  switch (action.type) {
    case GET_ALL_ADDRESS_LIST:
      return { ...state, addressList: action.addressList }
  }
  return state;
}

/**
 * 获取全部优质学校列表
 */
export function getAllAddressList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_information/getCampus',
      data: {
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_ADDRESS_LIST, addressList: data || [] })
      }
    })
  }
}

