/**
 * 精品课程
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd-mobile';
import Navigate from '../../components/common/navigate/navigate';
import styles from '../../styles/course/course.module.scss';
import {
  ICourseStoreState,
  getAllCourseTypeList,
  getAllCourseList,
  ICourseType,
  ICourseItem,
  cleanCourseDetails
} from '../../redux/course.reducer';

export interface ICourseProps {
  history: any,
  course: ICourseStoreState,
  getAllCourseTypeList: any,
  getAllCourseList: any,
  cleanCourseDetails: any
}

export interface ICourseState {
  courseTabs: any[],
  courseSubTabs: any[],
  currCourseTabPage: string
  currCourseSubTabPage: string,
  initPage: number,
  smPage: number,

}

class Course extends React.Component<ICourseProps, ICourseState> {
  constructor(props: ICourseProps) {
    super(props);
    this.state = {
      courseTabs: [{
        name: "全部",
        id: "all"
      }],
      currCourseTabPage: "all",
      currCourseSubTabPage: "all",
      courseSubTabs: [{
        name: "全部",
        id: "all"
      }],
      initPage: 0,
      smPage: 0,

    }
  }
  get bigNum() {
    let data = 0
    if(this.props.history.location.state){
    data = this.props.history.location.state.bigNum + 1
  }
    return data
  }
  get smNum() {
    let data = 0
    if(this.props.history.location.state){
      data = this.props.history.location.state.smNum + 1
    }
    return data
  }
  static getDerivedStateFromProps(props: ICourseProps, state: ICourseState) {
    let course = props.course;
    let courseTypeList = course.courseTypeList || [];

    let courseTabs: any[] = courseTypeList.map((item: ICourseType) => {
      return {
        title: <p className={styles.tabTitle}>{item.name}</p>,
        ...item
      }
    })

    courseTabs.unshift({
      title: <p className={styles.tabTitle}>全部</p>,
      name: "全部",
      id: "all",
      children: [{
        id: "all",
        name: "全部",
      }]
    })

    return {
      courseTabs: courseTabs,
    }
  }
  componentDidMount() {
    if (this.props.history.location.state) {
      if(this.props.history.location.state.bigPage && !this.props.history.location.state.smallPage){
        this.setState({
          currCourseTabPage: this.props.history.location.state.bigPage
        })
        setTimeout(() => {
          this.setState({
            currCourseSubTabPage: 'all'
          })
        }, 500);
      this.props.getAllCourseList(this.props.history.location.state.bigPage, '')
    } else {
        this.setState({
          currCourseTabPage: this.props.history.location.state.bigPage,
          currCourseSubTabPage: this.props.history.location.state.smallPage,
        })
      this.props.getAllCourseList(this.props.history.location.state.bigPage, this.props.history.location.state.smallPage)
    }
      
    } else {
      this.props.getAllCourseTypeList()
      this.props.getAllCourseList("", "")
    }

  }

  /**
   * 点击父级tab
   * @param tab 
   * @param index 
   */
  handleClickParentTabs(tab: any, index: number) {
    this.setState({
      currCourseTabPage: tab.id,
      currCourseSubTabPage: "all"
    })
    let parentId = tab.id === "all" ? "" : tab.id;
    if(this.props.history.location.state){
      this.props.history.location.state.smNum = 0
    }else {
      
    }
    
    let subId = ""
    this.props.getAllCourseList(parentId, subId)
  }

  /**
   * 点击子tab
   * @param tab 
   * @param index 
   */
  handleClickSubTab(tab: any, index: number) {
    this.setState({ currCourseSubTabPage: tab.id })
    let parentId = this.state.currCourseTabPage;
    let subId = tab.id === "all" ? "" : tab.id
    this.props.getAllCourseList(parentId, subId)
  }

  /**
   * 点击课程item
   */
  handleCourseItem(id: any) {
    // 进入前先清楚上一次redux的缓存
    this.props.cleanCourseDetails()

    this.props.history.push(`/course/intro/${id}`)
  }

  public render() {
    let { course } = this.props;
    let courseList = course.courseList || []
    return (
      <div className={styles.container}>
        <header>
          <h1>精品课程</h1>
          <h2>講座内容</h2>
        </header>
        <Tabs
          tabs={this.state.courseTabs}
          initialPage={this.bigNum || 0}
          tabBarTextStyle={{
            fontSize: 14,
            fontWeight: 'bold',
          }}
          tabBarInactiveTextColor={"#999"}
          usePaged={true}
          swipeable={false}
          tabBarUnderlineStyle={{
            border: 0,
            height: 0
          }}
          // page={this.state.currCourseTabPage}
          // onChange={(tab, index) => { console.log('onChange', index, tab); }}
          onTabClick={this.handleClickParentTabs.bind(this)}
        >
          {
            this.state.courseTabs.map((item: any) => {
              let courseSubTabs = item.children.map((item: any) => {
                return {
                  title: <p
                    className={styles.tabTitle}
                    key={item.id}
                  >{item.name}</p>,
                  ...item
                }
              })

              if (item.name !== "全部") {
                courseSubTabs.unshift({
                  id: "all",
                  title: <p>全部</p>,
                })
              }

              return <div
                key={item.id}
                className={styles.courseListContainer}
              >
                <Tabs
                  tabBarInactiveTextColor={"#999"}
                  initialPage={this.smNum || 0}
                  tabBarTextStyle={{
                    fontSize: 13,
                  }}

                  usePaged={true}
                  tabBarUnderlineStyle={{
                    border: 0,
                    height: 0
                  }}
                  tabs={courseSubTabs}
                  onTabClick={this.handleClickSubTab.bind(this)}
                >
                  {
                    courseSubTabs.map((tabsItem: any) => {
                      return courseList.length
                        ?
                        <ul key={tabsItem.id}>
                          {
                            courseList.map((courseItem: ICourseItem, index) => {
                              return <li
                                key={courseItem.id}
                                className={styles.listItem}
                                onClick={this.handleCourseItem.bind(this, courseItem.id)}
                              >
                                <div className={styles.pic}>
                                  <img src={courseItem.coverImageUrl} alt="" />
                                </div>
                                <div className={styles.content}>
                                  <h1>{courseItem.title}</h1>
                                  <p>{courseItem.totalLength}回</p>
                                  <div>
                                    <p className={styles.price}>￥{courseItem.price}</p>
                                    <p className={courseItem.status === 0 ? styles.updating : styles.done}>{courseItem.status === 0 ? "更新中" : "已完结"}</p>
                                  </div>
                                </div>
                              </li>
                            })
                          }
                        </ul>
                        :
                        <p style={{ textAlign: "center" }}>暂无数据</p>
                    })
                  }
                </Tabs>
              </div>
            })
          }
        </Tabs>
        <Navigate />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    course: state.course
  }
}

const mapDispatchToProps = {
  getAllCourseTypeList,
  getAllCourseList,
  cleanCourseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(Course);
