/**
 * 普通用户 账户安全
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { List, InputItem, Button,Toast } from 'antd-mobile';
import './../../../styles/center/antd_modify.scss';
import styles from './../../../styles/center/n_modify_phone.module.scss';
import Navigate from './../../../components/common/navigate/navigate';
import {
  updateUserInfo,
  getCaptcha,
  confirmCaptcha,
  studentChangePwd
} from './../../../redux/mycenter.reducer';


const Item: React.ElementType = List.Item;

export interface IN_AccountSafetyProps {
  userInfo: any
  history: any
  updateUserInfo: any
  getCaptcha: any
  confirmCaptcha: any
  studentChangePwd: any
}

export interface IN_AccountSafetyState {
  phoneNumber: string;
  captcha: string;
  step: number;
  newPassword: string;
  newPasswordCheck: string;
  captchaTip: string,
  captchaLock: boolean
}

class N_AccountSafety extends React.Component<IN_AccountSafetyProps, IN_AccountSafetyState> {
  constructor(props: IN_AccountSafetyProps) {
    super(props);

    this.state = {
      phoneNumber: '1396083032',
      captcha: '',
      step: 1,
      newPassword: '',
      newPasswordCheck: '',
      captchaTip: '获取验证码',
      captchaLock: false
    }
  }

  componentDidMount() {
    this.setState({ phoneNumber: this.props.userInfo.phone });
  }

  nextStep = () => {
    let confirmData = {
      phone: this.state.phoneNumber,
      code: this.state.captcha
    };
    this.props.confirmCaptcha(confirmData).then(
      (res: any) => {
        if (res.code === 200) {
          this.setState({
            step: 2
          });
        } else {
          Toast.info('验证码错误');
        }
      }
    );
  }

  getCaptcha = () => {
    if (this.state.captchaLock) {
      return
    }
    this.props.getCaptcha({ phone: this.state.phoneNumber }).then(
      (res: any) => {
        if (res.code === 200) {
          Toast.info('获取成功');
          this.setState({ captchaLock: true });
          let time = 60;
          let timer: NodeJS.Timer | null = setInterval(() => {
            this.setState({ captchaTip: `${time}秒后再试` });
            time -= 1;
            if (time === -1) {
              window.clearTimeout(Number(timer));
              this.setState({ captchaLock: false });
              this.setState({ captchaTip: `获取验证码` });
            }
          }, 1000);
        }
      }
    );
  }

  submitChangePwd() {
    if (this.state.newPassword !== this.state.newPasswordCheck) {
      Toast.info('两次输入不一致')
    } else {
      let data = {
        phone: this.state.phoneNumber,
        newPassword: this.state.newPasswordCheck
      };
      this.props.studentChangePwd(data).then(
        (res: any) => {
          if (res.code === 200) {
            Toast.info('修改成功');
            this.props.history.push('/center');
          }
        }
      );
    }
  }

  public render() {
    return (
      <div className={`${styles.nModifyPhoneContainer} nAccountSafety`}>
        <div className={styles.nickNameWrap}>
          {
            this.state.step === 1 ?
              <List>
                <Item extra={<p className={styles.captchaBtn}><span onClick={this.getCaptcha}>{this.state.captchaTip}</span></p>}>手机账号<span className={styles.phoneNumber}>{this.state.phoneNumber}</span></Item>
                <Item><InputItem className={styles.captchaInput} value={this.state.captcha}
                  placeholder='请输入您的手机验证码'
                  onChange={(value: any) => { this.setState({ captcha: value }) }}></InputItem></Item>
              </List> :
              <List>
                <Item><InputItem className={styles.captchaInput} value={this.state.newPassword}
                  placeholder='请输入新密码'
                  onChange={(value: any) => { this.setState({ newPassword: value }) }}
                ></InputItem></Item>
                <Item><InputItem className={styles.captchaInput} value={this.state.newPasswordCheck}
                  placeholder='请确认新密码'
                  onChange={(value: any) => { this.setState({ newPasswordCheck: value }) }}></InputItem></Item>
              </List>
          }
        </div>
        <div className={styles.buttonWrap}>
          {
            this.state.step === 1 ?
              <Button type='primary' inline className='fullBtn' onClick={() => this.nextStep()}>下一步</Button>
              :
              <Button type='primary' inline className='fullBtn' onClick={() => this.submitChangePwd()}>确定</Button>
          }
        </div>
        <Navigate type='white'></Navigate>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  console.log(state)
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  updateUserInfo,
  confirmCaptcha,
  getCaptcha,
  studentChangePwd
}

export default connect(mapStateToProps, mapDispatchToProps)(N_AccountSafety);
