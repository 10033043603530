import React from 'react'
import styles from '../../styles/address/address.module.scss'
import { getAllcampusList } from '../../redux/address.reducer'
import { connect } from 'react-redux';
import { type } from 'os';
export interface CampusListType {
    address: string | any,
    campusImage: string,
    id: any,
    locationImage: string,
    name: string,
    onlinePhone: string,
    onlineqq: string
}
export interface AddressProp {
    getAllcampusList: any,
    campusList: any,
    location: any
}

class Address extends React.Component<AddressProp, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            campusList: []
        }
    }
    componentDidlMount() {
        const { getAllcampusList } = this.props
        getAllcampusList()
    }
    public render() {
        const { campusList } = this.props
        const { id } = this.props.location.state
        return (
            <div className={styles.container}>
                {
                    campusList.map((item: any, index: number) => {
                        if (id === item.id) {
                            return (
                                <div key={index}>
                                    <div className={styles.title}>{item.name}</div>
                                    <div className={styles.msgBox}>
                                        <div className={styles.msgItem}>地址：{item.address}</div>
                                        <div className={styles.msgItem}>在线咨询：{item.onlineqq}</div>
                                        <div className={styles.msgItem}>咨询电话：{item.onlinePhone}</div>
                                    </div>
                                    <div className={styles.title}>
                                        地图展示
                                </div>
                                    <img src={item.locationImage}></img>
                                </div>
                            )
                        }
                    })
                }


            </div>
        )
    }
}
const mapDispatchToProps = {
    getAllcampusList
}
const mapStateToProps = (state: any) => {
    return {
        campusList: state.addressDetail.campusList
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Address);
