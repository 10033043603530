/**
 * 客服信息
 */

import {Dispatch} from 'redux';
import $ from './../util/requst';

const GET_CUSTOMER = 'GET_CUSTOMER';
type GET_CUSTOMER = typeof GET_CUSTOMER;

const initState: any = {
    customer: {}
}

export function customer(state = initState, action: any): any {
    switch (action.type) {
        case GET_CUSTOMER:
            return {...state, customer: action.customer}
    }
    return state;
}

/**
 * 获取客服信息
 */
export function getCustomer() {
    return (dispatch: Dispatch) => {
        return $.post({
            url: '/back_information/getCustomer',
            data: {
                pager: 1,
                size: 100
            },
            method: "POST"
        }).then((res: any) => {
            let data = res.data;
            if (res.success && data) {
                dispatch({type: GET_CUSTOMER, customer: data})
            }
        })
    }
}
