/**
 * 绑定手机号
 */

import * as React from 'react';
import { Button } from 'antd-mobile';
import styles from './../../styles/login/login.module.scss';
import logo from './../../static/img/logo.png';

export interface IBindingProps {
  history: any
}

interface IBindingState {
}

export default class Binding extends React.Component<IBindingProps, IBindingState> {
  public render() {
    return (
      <div className={styles.loginContainer}>

        <div className={styles.logoWrap} style={{marginTop:'.5rem'}}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.loginWrap}>

          <div className={styles.formGroup}>
            {/* 快速登录 */}
            <div className={styles.formItem}>
              <p>
                <i className="iconfont iconzhanghu"></i>
              </p>
              <input
                type="text"
                placeholder="请输入手机号"
              />
            </div>
            <div className={styles.formItem}>
              <p>
                <i className="iconfont iconmima"></i>
              </p>
              <input
                type="text"
                placeholder="请输入验证码"
              />
              <button>获取验证码</button>
            </div>
          </div>


          <div className={styles.btnContainer}>
            <Button type="primary">绑定</Button>
          </div>
        </div>
      </div>
    );
  }
}
