/**
 * 教师登录页
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Toast } from 'antd-mobile';
import styles from './../../styles/login/login.module.scss';
import { login } from '../../redux/user.reducer';
import logo from './../../static/img/logo.png';

interface IN_LoginState {
  loginType: number,
  account: string,
  pwd: string,
  phoneNum?: number,
  captcha?: string,
}
export interface IT_LoginProps {
  history: any,
  login: any,
  handleLogin: any
}
class T_Login extends React.Component<IT_LoginProps, IN_LoginState> {
  state: IN_LoginState = {
    loginType: 0,
    // account: 'xiaoming',
    // pwd: '123456'
    account: '',
    pwd: ''
  }

  refState(key: string, e: any) {
    let obj: any = {}
    obj[key] = e.target.value;
    this.setState({ ...obj })
  }

  handleLogin = () => {
    sessionStorage.removeItem('_t')
    this.props.login({
      username: this.state.account,
      password: this.state.pwd,
      radio: 2
    }).then((res: any) => {
      if (res.success && res.data) {
        sessionStorage.clear();
        sessionStorage.isTeacher = true;
        sessionStorage._t = res.data.token;
        this.props.history.push('/')
      } else {
        throw res.message;
      }
    }).catch((err:any) => {
      Toast.info(err)
    })
  }

  public render() {

    return (
      <div className={styles.loginContainer}>
        <div className={styles.header}>
          <span
            onClick={() => this.props.history.push('/register')}
            className={styles.logon}
          >注册</span>
          <span
            onClick={() => this.props.history.push('/n')}
            className={styles.enteranceSwitch}
          >普通用户登录入口</span>
        </div>
        <div className={styles.logoWrap} style={{ marginTop: '.4rem', marginBottom: '.54rem' }}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.loginWrap}>

          <div className={styles.formGroup}>
            {/* 普通登录 */}
            <div className={styles.formItem}>
              <p>
                <i className="iconfont iconzhanghu"></i>
              </p>
              <input
                type="text"
                placeholder="请输入账号"
                onChange={this.refState.bind(this, 'account')}
                value={this.state.account}
              />
            </div>
            <div className={styles.formItem}>
              <p>
                <i className="iconfont iconmima"></i>
              </p>
              <input
                type="password"
                placeholder="请输入密码"
                onChange={this.refState.bind(this, 'pwd')}
                value={this.state.pwd}
              />
            </div>
          </div>


          <div className={styles.btnContainer}>
            <Button
              type="primary"
              onClick={this.handleLogin}
            >登录</Button>
          </div>
        </div>
        {/* 忘记密码 和快速登录 toogle*/}
        <div className={styles.forget}>
          <p onClick={() => this.props.history.push('/forget')}>忘记密码</p>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    // teachers: state.teachers
  }
}

const mapDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapDispatchToProps)(T_Login);
