/**
 * 咨询列表
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';

const GET_ALL_CONSULT_LIST = 'GET_ALL_CONSULT_LIST';
type GET_ALL_CONSULT_LIST = typeof GET_ALL_CONSULT_LIST;


const SAVE_CURRENT_CONSULT = 'SAVE_CURRENT_CONSULT';
type SAVE_CURRENT_CONSULT = typeof SAVE_CURRENT_CONSULT;

export interface IConsultItem {
  id: string,
  question: string,
}

export interface IConsultStoreState {
  consultList: IConsultItem[]
  currentConsult: any
}


const initState: IConsultStoreState = {
  consultList: [],
  currentConsult: {}
}

export function consults(state = initState, action: any): IConsultStoreState {
  switch (action.type) {
    case GET_ALL_CONSULT_LIST:
      return { ...state, consultList: action.consultList }
    case SAVE_CURRENT_CONSULT:
      return { ...state, currentConsult: action.currentConsult }
  }
  return state;
}

/**
 * 获取全部优质学校列表
 */
export function getAllConsultList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_consultation/getConsultation',
      data: {
        pager: 1,
        size: 100,
        pushPublic: true,
      }
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        dispatch({ type: GET_ALL_CONSULT_LIST, consultList: data.list || [] })
      }
    })
  }
}

export function saveCurrentConsult(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SAVE_CURRENT_CONSULT, currentConsult: data || {} })
  }
}


