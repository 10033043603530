/**
 * 能力评估
 */

import * as React from "react";
import { connect } from "react-redux";
import "./../../styles/center/antd_modify.scss";
import styles from "./../../styles/center/capacity_assess.module.scss";
import {
  List,
  Picker,
  InputItem,
  TextareaItem,
  Button,
  Toast,
} from "antd-mobile";
import { directive } from "@babel/types";
import {
  capacityAssess,
  getAapacityAssess,
} from "./../../redux/mycenter.reducer";

const Item: React.ElementType = List.Item;

export interface ICapacityAssessProps {
  capacityAssess: any;
  getAapacityAssess: any;
  userInfo: any;
  history: any;
}

export interface ICapacityAssessState {
  ambition: any;
  age: any;
  mingqian: any;
  xiedai: any;
  email: any;
  inIapan: any;
  nowAddress: any;
  comTime: any;
  comeToAddress: any;
  visaType: any;
  lastEducation: any;
  exaFraction: any;
  exa_address: any;
  subject: any;
  japaneseLevel: any;
  englishLevel: any;
  toeflFraction: any;
  ietlsFraction: any;
  department: any;
  toeicFraction: any;
  targetUniversity: any;
  partLeave: any;
  leaveJapaneseFraction: any;
  leaveWzFraction: any;
  leaveLzFraction: any;
  leavemMathfraction: any;
  universityMajor: any;
  gpa: any;
  otherMessage: any;
  content: any;
  sex: any;
  ["key"]?: any;
}

class CapacityAssess extends React.Component<
  ICapacityAssessProps,
  ICapacityAssessState
> {
  // labelFocusInst: any;
  inputField: any;
  labelFocusInst1: any;
  labelFocusInst2: any;
  labelFocusInst3: any;
  labelFocusInst4: any;
  labelFocusInst5: any;
  labelFocusInst6: any;
  labelFocusInst7: any;
  labelFocusInst8: any;
  labelFocusInst9: any;
  labelFocusInst10: any;
  labelFocusInst11: any;
  labelFocusInst12: any;
  labelFocusInst13: any;
  labelFocusInst14: any;
  constructor(props: ICapacityAssessProps) {
    super(props);
    this.state = {
      ambition: "",
      age: "",
      mingqian: "",
      xiedai: "",
      email: "",
      inIapan: "",
      nowAddress: "",
      comTime: "",
      comeToAddress: "",
      visaType: "",
      lastEducation: "",
      exaFraction: "",
      exa_address: "",
      subject: "",
      japaneseLevel: "",
      englishLevel: "",
      toeflFraction: "",
      ietlsFraction: "",
      department: "",
      toeicFraction: "",
      targetUniversity: "",
      partLeave: "",
      leaveJapaneseFraction: "",
      leaveWzFraction: "",
      leaveLzFraction: "",
      leavemMathfraction: "",
      universityMajor: "",
      gpa: "",
      otherMessage: "",
      content: "",
      sex: "",
    };
  }

  componentDidMount() {
    this.props.getAapacityAssess(this.props.userInfo.id).then((res: any) => {
      if (res.code === 200 && res.data.studentAbility) {
        let data = res.data.studentAbility;
        this.setState({
          ambition: [Number(data.ambition)],
          age: [Number(data.ambition)],
          mingqian: data.ambition,
          xiedai: data.xiedai,
          email: data.email,
          inIapan: [data.inIapan],
          nowAddress: [data.nowAddress],
          comTime: [Number(data.comTime)],
          comeToAddress: [data.comeToAddress],
          visaType: [Number(data.visaType)],
          lastEducation: [Number(data.lastEducation)],
          exaFraction: [Number(data.exaFraction)],
          exa_address: data.exa_address,
          subject: [Number(data.subject)],
          japaneseLevel: [Number(data.japaneseLevel)],
          englishLevel: [Number(data.englishLevel)],
          toeflFraction: data.toeflFraction,
          ietlsFraction: data.ietlsFraction,
          department: data.department,
          toeicFraction: data.toeicFraction,
          targetUniversity: [Number(data.targetUniversity)],
          partLeave: [data.partLeave],
          leaveJapaneseFraction: data.leaveJapaneseFraction,
          leaveWzFraction: data.leaveWzFraction,
          leaveLzFraction: data.leaveLzFraction,
          leavemMathfraction: data.leavemMathfraction,
          universityMajor: data.universityMajor,
          gpa: data.gpa,
          content: data.content,
          otherMessage: data.otherMessage,
          sex: "",
        });
        console.log(this.state);
      }
    });
  }

  submit = () => {
    this.props.capacityAssess(this.state).then((res: any) => {
      if (res.code === 200) {
        Toast.info("修改成功");
        this.props.history.push("/center");
      } else {
        Toast.info(res.message);
      }
    });
  };

  public render() {
    const district: any[] = [
      {
        value: 1,
        label: "我想考名牌大学",
      },
      {
        value: 2,
        label: "我想考名牌大学院（硕士)",
      },
      {
        value: 3,
        label: "我想申请研究生（旁听生)",
      },
      {
        value: 4,
        label: "我想上日本语学校",
      },
      {
        value: 5,
        label: "我想来日本工作",
      },
    ];
    const sexList: any[] = [
      {
        value: 2,
        label: "女",
      },
      {
        value: 1,
        label: "男",
      },
    ];
    const ageList: any[] = (() => {
      let ageList = [];
      for (let i = 1; i <= 100; i++) {
        ageList.push({
          value: i,
          label: i.toString(),
        });
      }
      return ageList;
    })();

    const isInJapenList: any[] = [
      { value: 1, label: "是" },
      { value: 2, label: "否" },
    ];

    // const nowAddressList: any[] = [
    //   { value: 1, label: '东京' },
    //   { value: 2, label: '大阪' }
    // ];
    const nowAddressList: any[] = [
      { value: "北海道", label: "北海道" },
      { value: "青森县", label: "青森县" },
      { value: "岩手县", label: "岩手县" },
      { value: "宫城县", label: "宫城县" },
      { value: "秋田县", label: "秋田县" },
      { value: "山形县", label: "山形县" },
      { value: "福岛县", label: "福岛县" },
      { value: "茨城县", label: "茨城县" },
      { value: "栃木县", label: "栃木县" },
      { value: "群马县", label: "群马县" },
      { value: "埼玉县", label: "埼玉县" },
      { value: "千叶县", label: "千叶县" },
      { value: "东京都", label: "东京都" },
      { value: "神奈川县", label: "神奈川县" },
      { value: "新潟县", label: "新潟县" },
      { value: "富山县", label: "富山县" },
      { value: "石川县", label: "石川县" },
      { value: "福井县", label: "福井县" },
      { value: "山梨县", label: "山梨县" },
      { value: "长野县", label: "长野县" },
      { value: "岐阜县", label: "岐阜县" },
      { value: "静冈县", label: "静冈县" },
      { value: "爱知县", label: "爱知县" },
      { value: "三重县", label: "三重县" },
      { value: "滋贺县", label: "滋贺县" },
      { value: "京都府", label: "京都府" },
      { value: "大阪府", label: "大阪府" },
      { value: "兵库县", label: "兵库县" },
      { value: "奈良县", label: "奈良县" },
      { value: "和歌山县", label: "和歌山县" },
      { value: "鸟取县", label: "鸟取县" },
      { value: "岛根县", label: "岛根县" },
      { value: "冈山县", label: "冈山县" },
      { value: "广岛县", label: "广岛县" },
      { value: "山口县", label: "山口县" },
      { value: "德岛县", label: "德岛县" },
      { value: "香川县", label: "香川县" },
      { value: "爱媛县", label: "爱媛县" },
      { value: "高知县", label: "高知县" },
      { value: "福冈县", label: "福冈县" },
      { value: "佐贺县", label: "佐贺县" },
      { value: "长崎县", label: "长崎县" },
      { value: "熊本县", label: "熊本县" },
      { value: "大分县", label: "大分县" },
      { value: "宫崎县", label: "宫崎县" },
      { value: "鹿儿岛县", label: "鹿儿岛县" },
      { value: "冲绳县", label: "冲绳县" },
    ];

    const comTimeList: any[] = [
      { value: 1, label: "三个月内" },
      { value: 2, label: "一年内" },
    ];

    const visaTypeList: any[] = [{ value: 1, label: "留学" }];

    const lastEducationList: any[] = [
      { value: "中专", label: "中专" },
      { value: "高中", label: "高中" },
      { value: "专科", label: "专科" },
      { value: "本科", label: "本科" },
      { value: "硕士", label: "硕士" },
      { value: "博士", label: "博士" },
    ];

    const exaFractionList: any[] = [
      { value: "一本", label: "一本" },
      { value: "二本", label: "二本" },
      { value: "专科", label: "专科" },
    ];

    const subjectList: any[] = [
      { value: 1, label: "文科" },
      { value: 2, label: "理科" },
      { value: 3, label: "艺术类" },
    ];

    const japaneseLevelList: any[] = [
      { value: "N1", label: "N1" },
      { value: "N2", label: "N2" },
      { value: "N3", label: "N3" },
      { value: "N3以下", label: "N3以下" },
    ];

    const englishLevelList: any[] = [
      { value: 1, label: "四级" },
      { value: 2, label: "六级" },
      { value: 3, label: "专四" },
      { value: 4, label: "专八" },

    ];

    const targetUniversityList: any[] = [
      { value: 1, label: "东京大学" },
      { value: 2, label: "京都大学" },
    ];

    const partLeaveList: any[] = [
      { value: true, label: "是" },
      { value: false, label: "否" },
    ];

    return (
      <div
        className={`${styles.capacityAssessContainer} capacityAssessContainer`}
      >
        <div className={styles.header}>
          <p>能力评估</p>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={district}
              cols={1}
              value={this.state.ambition}
              extra=" "
              onChange={(value) => {
                this.setState({ ambition: value });
              }}
            >
              <Item>请选择您的志向</Item>
            </Picker>
            <Picker
              data={sexList}
              cols={1}
              extra=" "
              value={this.state.sex}
              onChange={(value) => {
                this.setState({ sex: value });
              }}
            >
              <Item>请选择性别</Item>
            </Picker>
            <Picker
              data={ageList}
              cols={1}
              extra=" "
              value={this.state.age}
              onChange={(value) => {
                this.setState({ age: value });
              }}
            >
              <Item>请选择年龄</Item>
            </Picker>
            <Item
              className="half"
              onClick={() => this.inputField.focus()}
              extra={
                <InputItem
                  value={this.state.mingqian}
                  ref={(elem: any) => (this.inputField = elem)}
                  onChange={(value) => {
                    this.setState({ mingqian: value });
                  }}
                ></InputItem>
              }
            >
              请输入姓名
            </Item>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst1.focus()}
              extra={
                <InputItem
                  value={this.state.xiedai}
                  ref={(el: any) => (this.labelFocusInst1 = el)}
                  onChange={(value) => {
                    this.setState({ xiedai: value });
                  }}
                ></InputItem>
              }
            >
              请输入手机号码
            </Item>
            <Item
              className="half"
              onClick={() => this.labelFocusInst2.focus()}
              extra={
                <InputItem
                  value={this.state.email}
                  ref={(el: any) => (this.labelFocusInst2 = el)}
                  onChange={(value) => {
                    this.setState({ email: value });
                  }}
                ></InputItem>
              }
            >
              请输入邮箱
            </Item>
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={isInJapenList}
              cols={1}
              value={this.state.inIapan}
              extra=" "
              onChange={(value) => {
                this.setState({ inIapan: value });
              }}
            >
              <Item>您是否在日本</Item>
            </Picker>
            {this.state.inIapan && this.state.inIapan[0] === 1 ? (
              <Picker
                data={nowAddressList}
                cols={1}
                value={this.state.nowAddress}
                extra=" "
                onChange={(value) => {
                  this.setState({ nowAddress: value });
                }}
              >
                <Item>您目前日本所在地</Item>
              </Picker>
            ) : null}
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={comTimeList}
              cols={1}
              value={this.state.comTime}
              extra=" "
              onChange={(value) => {
                this.setState({ comTime: value });
              }}
            >
              <Item>请选择您的来日时间</Item>
            </Picker>
            {this.state.inIapan && this.state.inIapan[0] === 2 && (
              <Picker
                data={nowAddressList}
                cols={1}
                value={this.state.comeToAddress}
                extra=" "
                onChange={(value) => {
                  this.setState({ comeToAddress: value });
                }}
              >
                <Item>请选择您预计来日所在地</Item>
              </Picker>
            )}

            <Picker
              data={visaTypeList}
              cols={1}
              value={this.state.visaType}
              extra=" "
              onChange={(value) => {
                this.setState({ visaType: value });
              }}
            >
              <Item>请选择签证类型</Item>
            </Picker>
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={lastEducationList}
              cols={1}
              extra=" "
              value={this.state.lastEducation}
              onChange={(value) => {
                this.setState({ lastEducation: value });
              }}
            >
              <Item>请选最终学历</Item>
            </Picker>
            <Picker
              data={exaFractionList}
              cols={1}
              extra=" "
              value={this.state.exaFraction}
              onChange={(value) => {
                this.setState({ exaFraction: value });
              }}
            >
              <Item>请选择您的高考分数</Item>
            </Picker>
            <Item
              className="half2"
              onClick={() => this.labelFocusInst3.focus()}
              extra={
                <InputItem
                  value={this.state.exa_address}
                  ref={(el: any) => (this.labelFocusInst3 = el)}
                  onChange={(value) => {
                    this.setState({ exa_address: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的高考所在地
            </Item>
            {/* <Picker data={subjectList} cols={1} extra=" "
              value={this.state.subject}
              onChange={(value) => { this.setState({ subject: value }) }}
            >
              <Item>
                请选择您的文理科目
              </Item>
            </Picker> */}
            <Picker
              data={japaneseLevelList}
              cols={1}
              extra=" "
              value={this.state.japaneseLevel}
              onChange={(value) => {
                this.setState({ japaneseLevel: value });
              }}
            >
              <Item>请选择您的日语水平</Item>
            </Picker>
            <Picker
              data={englishLevelList}
              cols={1}
              extra=" "
              value={this.state.englishLevel}
              onChange={(value) => {
                this.setState({ englishLevel: value });
              }}
            >
              <Item>请选择您的英语水平</Item>
            </Picker>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst4.focus()}
              extra={
                <InputItem
                  value={this.state.toeflFraction}
                  ref={(el: any) => (this.labelFocusInst4 = el)}
                  onChange={(value) => {
                    this.setState({ toeflFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的托福分数
            </Item>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst5.focus()}
              extra={
                <InputItem
                  value={this.state.ietlsFraction}
                  ref={(el: any) => (this.labelFocusInst5 = el)}
                  onChange={(value) => {
                    this.setState({ ietlsFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的雅思分数
            </Item>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst6.focus()}
              extra={
                <InputItem
                  value={this.state.toeicFraction}
                  ref={(el: any) => (this.labelFocusInst6 = el)}
                  onChange={(value) => {
                    this.setState({ toeicFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的托业分数
            </Item>
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={subjectList}
              cols={1}
              extra=" "
              value={this.state.subject}
              onChange={(value: any) => {
                this.setState({ subject: value });
                console.log(value);
                console.log(this.state);
              }}
            >
              <Item>请选择您的课程分类</Item>
            </Picker>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst7.focus()}
              extra={
                <InputItem
                  value={this.state.department}
                  ref={(el: any) => (this.labelFocusInst7 = el)}
                  onChange={(value) => {
                    this.setState({ department: value });
                  }}
                ></InputItem>
              }
            >
              学部专业/研究科
            </Item>
            <Item
              className="half"
              onClick={() => this.labelFocusInst8.focus()}
              extra={
                <InputItem
                  value={this.state.targetUniversity}
                  ref={(el: any) => (this.labelFocusInst8 = el)}
                  onChange={(value) => {
                    this.setState({ targetUniversity: value });
                  }}
                ></InputItem>
              }
            >
              您的目标大学
            </Item>
            {/* <Picker data={targetUniversityList} cols={1} extra=" "
              value={this.state.targetUniversity}
              onChange={(value) => { this.setState({ targetUniversity: value }) }}
            >
              <Item>
                请选择您的目标大学大学
              </Item>
            </Picker> */}
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Picker
              data={partLeaveList}
              cols={1}
              extra=" "
              value={this.state.partLeave}
              onChange={(value) => {
                this.setState({ partLeave: value });
              }}
            >
              <Item>您是否参加留考</Item>
            </Picker>
            {/* <Item extra={
              <div className={styles.radioInputWrap}>
                <span>
                  <input type="radio" id="yes" className={styles.radioInput} name="partLeave"
                    value={this.state.partLeave}
                    onChange={(e => { console.log(e.target) })}
                  />
                  <label htmlFor="yes">是</label>
                </span>
                <span style={{ marginLeft: '14px' }}>
                  <input type="radio" id="no" className={styles.radioInput} name="partLeave"
                    value={this.state.partLeave}

                  />
                  <label htmlFor="no">否</label>
                </span>
              </div>
            }>您是否参加留考</Item> */}
            <Item
              className="half2"
              onClick={() => this.labelFocusInst9.focus()}
              extra={
                <InputItem
                  value={this.state.leaveJapaneseFraction}
                  ref={(el: any) => (this.labelFocusInst9 = el)}
                  onChange={(value) => {
                    this.setState({ leaveJapaneseFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的留考日语分数
            </Item>
            <Item
              className="half2"
              onClick={() => this.labelFocusInst10.focus()}
              extra={
                <InputItem
                  value={this.state.leaveWzFraction}
                  ref={(el: any) => (this.labelFocusInst10 = el)}
                  onChange={(value) => {
                    this.setState({ leaveWzFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的文综留考分数
            </Item>
            <Item
              className="half2"
              onClick={() => this.labelFocusInst11.focus()}
              extra={
                <InputItem
                  value={this.state.leaveLzFraction}
                  ref={(el: any) => (this.labelFocusInst11 = el)}
                  onChange={(value) => {
                    this.setState({ leaveLzFraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的理综留考分数
            </Item>
            <Item
              className="half2"
              onClick={() => this.labelFocusInst12.focus()}
              extra={
                <InputItem
                  value={this.state.leavemMathfraction}
                  ref={(el: any) => (this.labelFocusInst12 = el)}
                  onChange={(value) => {
                    this.setState({ leavemMathfraction: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的数学留考分数
            </Item>
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst13.focus()}
              extra={
                <InputItem
                  value={this.state.universityMajor}
                  ref={(el: any) => (this.labelFocusInst13 = el)}
                  onChange={(value) => {
                    this.setState({ universityMajor: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的大学专业
            </Item>
            <Item
              className="half1"
              onClick={() => this.labelFocusInst14.focus()}
              extra={
                <InputItem
                  value={this.state.gpa}
                  ref={(el: any) => (this.labelFocusInst14 = el)}
                  onChange={(value) => {
                    this.setState({ gpa: value });
                  }}
                ></InputItem>
              }
            >
              请输入您的GPA
            </Item>
          </List>
        </div>
        <div className={styles.infoWrap}>
          <List>
            <TextareaItem
              className={styles.textArea}
              rows={5}
              placeholder="其他（个人情况）"
              value={this.state.otherMessage}
              onChange={(value) => {
                this.setState({ otherMessage: value });
              }}
            />
            <div
              style={{
                height: "9px",
                backgroundColor: "rgba(246, 249, 249, 1)",
              }}
            ></div>
            <TextareaItem
              className={styles.textArea}
              rows={5}
              placeholder="咨询内容"
              value={this.state.content}
              onChange={(value) => {
                this.setState({ content: value });
              }}
            />
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button
            type="primary"
            inline
            className="fullBtn"
            onClick={this.submit}
          >
            确定
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo,
  };
};

const mapDispatchToProps = {
  capacityAssess,
  getAapacityAssess,
};

export default connect(mapStateToProps, mapDispatchToProps)(CapacityAssess);
