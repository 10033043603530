/* 
模拟考试答题结果
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styles from './../../styles/exam/exam_result.module.scss';
import { IExamStoreState, setExamAnwser, IExamTopicItem, examSubmit } from '../../redux/exam.reducer';
// import $ from './../../util/requst';

export interface IExamResultProps extends RouteComponentProps {
  exam: IExamStoreState
}

export interface IExamResultState {
  activeAnswerCard: boolean
}

class ExamResult extends React.Component<IExamResultProps, IExamResultState> {
  constructor(props: IExamResultProps) {
    super(props);

    this.state = {
      activeAnswerCard: false
    }
  }

  public render() {
    const { exam } = this.props;
    const examResult = exam.examTopicResult;
    const {
      total,
      awser,
      notawser,
      ok,
      mistake,
      questions,
      title
    } = examResult;

    return (
      <div className={styles.container}>
        <div className={styles.overview}>
          <h1>{title}</h1>
          <p>答对：<span>{ok}</span> 题</p>
        </div>
        <div className={styles.result}>
          <h1>练习情况</h1>
          <p>共{total}道题，正确<span>{ok}</span>题，错误<b>{mistake}</b>题，未答<i>{notawser}</i>题</p>
        </div>
        <div className={styles.practise}>
          <h1>答题解析</h1>
          <ul>
            {
              questions.map((item: IExamTopicItem, index: number) => {
                return <li
                  onClick={() => this.props.history.push(`/exam/exam_analysis/${index}`)}
                  className={
                    item.submit
                      ? item.answer === item.submit
                        ? styles.submit
                        : styles.errorOption
                      : styles.warningOption
                  }
                  key={index}
                >{index+1}</li>
              })
            }

            <li
              // onClick={() => this.props.history.push('/exam/exam_analysis')}
              className={styles.empty}
            // key={index}
            >1</li>
          </ul>
          <button onClick={() => this.props.history.push('/exam')}>返回模考主页</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    exam: state.examStore,
  }
}

const mapDispatchToProps = {
  setExamAnwser,
  examSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamResult);