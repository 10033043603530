import * as React from 'react';
import Navigate from './../../components/common/navigate/navigate'
import { connect } from 'react-redux';
import styles from './../../styles/trainees/trainessDetail.module.scss';


export interface ITraineesDetailProps {
    history: any,

}
export interface ITraineesDetailState {

}

class TraineesDetail extends React.Component<ITraineesDetailProps, ITraineesDetailState> {
    constructor(props: ITraineesDetailProps) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {

    }
    public render() {
        // const TraineesId  = this.props.location.query.id
        const studentItem = this.props.history.location.state.item
        return (
            <div className={styles.container}>
                <div className={styles.interview}>
                    <div className={styles.interview_title}>合格访谈</div>
                    <div className={styles.interview_content}>
                        {studentItem.interviewSet[0].interview}
                    </div>
                    <div className={styles.interviewPic}>
                        <img src={studentItem.interviewSet[0].imageUrl} alt=""/>
                    </div>
                </div>
                <div className={styles.navigate}>
                  <Navigate />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(TraineesDetail)