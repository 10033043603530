/**
 *  个人中心
 */

import { Dispatch } from 'redux';
import $ from './../util/requst';
// import { type } from 'os';

const GET_USER_INFO = "GET_USER_INFO"
type GET_USER_INFO = "GET_USER_INFO"

const GET_MESSAGE_DETAILS = 'GET_MESSAGE_DETAILS'
type GET_MESSAGE_DETAILS = 'GET_MESSAGE_DETAILS'

const SAVE_CONSULT_DATA = 'SAVE_CONSULT_DATA'
type SAVE_CONSULT_DATA = 'SAVE_CONSULT_DATA'

const SAVE_LESSION_DATA = 'SAVE_LESSION_DATA'
type SAVE_LESSION_DATA = 'SAVE_CONSULT_DATA'

const SAVE_EXAM_DATA = 'SAVE_EXAM_DATA'
type SAVE_EXAM_DATA = 'SAVE_EXAM_DATA'

export interface IUserInfo {
  userName: string,
  phone: string,
  wxCode: string,
  headImageUrl: string,
  nick: string,
  sex: string,
  description: string,
  id: number
}

export interface MyCenterStoreState {
  userInfo: IUserInfo
  message: any
  consult: any
  lession: any
  exam: any
}

const initState: MyCenterStoreState = {
  userInfo: {
    userName: '',
    phone: '',
    wxCode: '',
    headImageUrl: '',
    nick: '',
    sex: '',
    description: '',
    id: -1
  },
  message: {},
  consult: {},
  lession: {},
  exam: {}
}

export function myCenter(state = initState, action: any): MyCenterStoreState {
  switch (action.type) {
    case GET_USER_INFO:
      return { ...state, userInfo: action.userInfo }
    case GET_MESSAGE_DETAILS:
      return { ...state, message: action.message }
    case SAVE_CONSULT_DATA:
      return { ...state, consult: action.data }
    case SAVE_LESSION_DATA:
      return { ...state, lession: action.data }
    case SAVE_EXAM_DATA:
      return { ...state, exam: action.data }
    default:
      return state
  }
}
/**
 * 获取用户信息接口
 */
export function getUserInfo(isTeacher: Boolean) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: isTeacher ? '/back_teacher/getTeacherOwn' : '/back_student/getStudentOwn',
    }).then((res: any) => {
      let data = res.data;
      if (res.success && data) {
        let userInfo = {
          userName: data.name,
          phone: data.phone,
          wxCode: data.wxCode,
          headImageUrl: data.headImageUrl,
          nick: data.nick,
          sex: data.sex,
          description: data.studentAbility,
          university: data.university,
          experience: data.experience,
          says: data.says,
          video_name: data.video_name,
          video_url: data.video_url,
          id: data.id
        };
        dispatch({ type: GET_USER_INFO, userInfo })
      }
      return res;
    })
  }
}
/**
 * 修改电话接口
 */
export function handleModifyPhone() {

}
/* 
获取普通用户我的模考列表
 */
export function getNormalExamList() {
  return (dispatch: Dispatch) => {
    let data = {
      pager: 1,
      size: 99999
    };
    return $.post({
      url: 'myexam/getList',
      data
    }).then(
      (res: any) => {
        return res
      }
    );
  }
}
/* 
获取消息列表数据
 */
export function getMessageList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: 'back_student/getOwnMessage',
    }).then(
      (res: any) => {
        return res
      }
    );
  }
}
/* 
获取消息详情
 */
export function getMessageDetails(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_MESSAGE_DETAILS, message: data })
  }
}
/* 
提交意见反馈
 */
export function submitFeedBack(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/feedback/personFeedBack',
      data
    }).then(
      (res: any) => {
        return res
      }
    );
  }
}
/* 
更新用户数据
 */
export function updateUserInfo(data: any) {
  let isTeacher = sessionStorage.getItem('isTeacher') === 'true';
  return (dispatch: Dispatch) => {
    return $.post({
      url: isTeacher ? '/back_teacher/merge' : '/back_student/updateStudentBase',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
获取验证码
 */
export function getCaptcha(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/sms/smsduanxin',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
验证验证码
 */
export function confirmCaptcha(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/usermanage/codeCheck',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
学生修改密码
 */
export function studentChangePwd(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/usermanage/studentPasswordUpdate',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
教师修改密码
 */
export function teacherChangePwd(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/usermanage/changePassword',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
上传文件
 */
export function uploadFile(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/uploadFile',
      data
    }).then((res: any) => {
      return res;
    })
  }
}

/* 
获取全部咨询
 */
export function getConsultList() {
  return (dispatch: Dispatch) => {
    return $.post({
      url: 'back_teacher/getTeacherConsultation',
      data: {
        pager: 1,
        size: 9999,
      }
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
存咨询数据
 */
export function saveConsultData(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SAVE_CONSULT_DATA, data })
  }
}
/* 
获取咨询详情
 */
export function getConsultDetail(id: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_consultation/getConsultationById',
      data: { id: id }
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
删除咨询
 */
export function deleteConsult(id: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_consultation/delete',
      data: { ids: id }
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
推荐咨询
 */
export function pushConsult(id: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_consultation/pushPublic',
      data: { id: id }
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
推荐咨询
 */
export function replyConsult(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_consultation/askMessage',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
个人能力评估
 */
export function capacityAssess(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_student_ability/mergeOwnAlibity',
      data
    }).then((res: any) => {
      return res;
    })
  }
}/* 
获取个人能力评估
 */
export function getAapacityAssess(id: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_student/getStudentOwn',
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
提交报销
 */
export function postTeacherReim(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacher/teacherReim',
    }).then((res: any) => {
      return res;
    })
  }
}

/* 
获取报销类型
 */
export function getReimType() {
  return (dispatch: Dispatch) => {
    return $.post({
      method: 'GET',
      url: '/teacherReim/getReimbursementType',
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
获取课程列表
 */
export function getLessionsList(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacherCourse/getAllTeacherCourseList',
      data
    }).then((res: any) => {
      return res;
    })
  }
}
/* 
保存课程数据
 */
export function saveLessionData(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SAVE_LESSION_DATA, data: data });
  }
}
/* 
获取点名列表
 */
export function getCourseStudent(id: number) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacherCourse/getCourseStudent',
      data: { teacherCourseId: id }
    }).then((res: any) => {
      return res;
    });
  }
}
/* 
提交点名数据
 */
export function mergeCourseStudeng(data: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacherCourse/mergeCourseStudent',
      data
    }).then((res: any) => {
      return res;
    });
  }
}
/* 
获取教师勤务状态
 */
export function getAllTeacherCourseList(id: number) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacherCourse/getCourseQinwu',
      data: { id: id }
    }).then((res: any) => {
      return res;
    });
  }
}
/* 
勤务管理提交
 */
export function sureCourse(ids: any) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/back_teacherCourse/sureCourse',
      data: {
        courseIds: ids
      }
    }).then((res: any) => {
      return res;
    });
  }
}
/* 
保存考试资料
 */
export function saveExamData(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SAVE_EXAM_DATA, data: data })
  }
}
/* 
获取考试详情
 */
export function getExamDetails(id: number) {
  return (dispatch: Dispatch) => {
    return $.post({
      url: '/myexam/detail',
      data: {
        id: id
      }
    }).then((res: any) => {
      return res;
    });
  }
}