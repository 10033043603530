/**
 * 首页
 * xiaogan
 */
import * as React from 'react';
import {connect} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {
    Pagination,
    Autoplay,
} from 'swiper/core';
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.min.css"
import {
    getActivityList,
    getTextBookList,
    Getpassrate,
    getPlatformIntro,
    getTeacherList,
    getPartnerList,
    getStudentList,
    InroduceStoreState,
    ITeacher,
    IPartner,
    IStudent,
    getAllHome,
    getBannerImage, IHomeHistory
} from './../../redux/introduce.reducer';
import {getAllAddressList, IAddressItem} from './../../redux/contact.reducer';
import {getAllCourseTypeList, ICourseType, ICourseSubType} from './../../redux/course.reducer';
import {getCustomer} from './../../redux/customer.reducer';
import {getAllNewsList, INewsItem} from './../../redux/news.reducer';
import {Carousel, Drawer, NavBar, List} from 'antd-mobile';
import styles from './../../styles/home/home.module.scss';
import Teacher from './../../static/img/defaultTeacher.png';
import Logo from './../../static/img/首页/logo.png';
import Menu from './../../static/img/首页/menu.png';
import Close from './../../static/img/首页/close.png';
import kcpng from './../../static/img/首页/kc.png';
import thpng from './../../static/img/首页/th.png';
import passpng from './../../static/img/首页/pass.png';
import elpng from './../../static/img/首页/el.png';
import ycpng from './../../static/img/首页/yc.png';
import whypng from './../../static/img/首页/why.png';
import newpng from './../../static/img/首页/new.png';
import hdpng from './../../static/img/首页/hd.png';
import rgpng from './../../static/img/首页/rg.png';
import config from '../../config/app.config';
import fzzpng from "../../static/img/首页/fzz.png";
import logs from "../../static/img/首页/logos.png";
import qqpng from "../../static/img/首页/qq.png";
import dzpng from "../../static/img/首页/dz.png";
import telpng from "../../static/img/首页/tel.png";
// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

export interface IHomeProps {
    history: any,
    match: any,
    courseTypeList: ICourseType[],
    newsList: INewsItem[],
    getAllCourseTypeList: any,
    getAllNewsList: any,
    getPlatformIntro: any,
    getTeacherList: any,
    getActivityList: any,
    getTextBookList: any,
    getPartnerList: any,
    getStudentList: any,
    Getpassrate: any,
    getAllAddressList: any,
    getCustomer: any,
    getAllHome: any,
    getBannerImage: any,
    customer: any,
    addressList: IAddressItem[],
    introduce: InroduceStoreState
}

export interface IHomeState {
    currTeacherIndex: number,
    slideIndex: number,
    open: boolean,
    mpng: any,
    ptrs: any,
    homeHistoryList: IHomeHistory[][],
    swiperRef: any,
    swiperRef2: any

}

const DOT_STYLE: object = {
    backgroundColor: '#FFFFFF',
    width: 10,
    height: 10
}

const ACTIVE_STYLE: object = {
    backgroundColor: '#77C7D6',
    width: 10,
    height: 10
}

class Home extends React.Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);

        this.state = {
            currTeacherIndex: 0,
            slideIndex: 0,
            open: false,
            mpng: Menu,
            ptrs: [],
            homeHistoryList: [],
            swiperRef: null,
            swiperRef2: null
        }
    }

    componentDidMount() {
        console.log('初始化数据')
        this.init()
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }
    init() {
        const {
            getActivityList,
            getTextBookList,
            getPlatformIntro,
            getTeacherList,
            getAllAddressList,
            getCustomer,
            Getpassrate,
            getPartnerList,
            getStudentList,
            getAllHome,
            getAllCourseTypeList,
            getAllNewsList,
            getBannerImage
        } = this.props;
        getBannerImage();
        getTextBookList()
        getPlatformIntro();
        getTeacherList();
        getActivityList();
        getPartnerList();
        getStudentList();
        getAllAddressList();
        getCustomer();
        getAllHome();
        getAllCourseTypeList();
        getAllNewsList();
        Getpassrate();
        this.setState({ptrs: this.props.introduce.partners});
        this.setState({homeHistoryList: this.props.introduce.homeHistoryList});
    }

    onOpenChange = () => {
        if (this.state.open !== false) {
            this.setState({mpng: Menu});
        } else {
            this.setState({mpng: Close});
        }
        this.setState({open: !this.state.open});
    }

    public render() {
        const {introduce, addressList, customer, courseTypeList, newsList} = this.props;
        const {
            teachers,
            activitys,
            partners,
            students,
            homeHege,
            homeWhy,
            textBook
        } = introduce;
        const bannerImages = introduce.bannerImages ? introduce.bannerImages : [];
        const isImg = (name: string) => {
            let ext = name.split('.')
            if (ext[1] === 'jpg' || ext[1] === 'jpeg' || ext[1] === 'png') {
                return true;
            }
            return false;
        };
        const sidebar = (
            <List>
                <List.Item className={styles.first_menu} onClick={() => this.props.history.push(`/`)}>首页</List.Item>
                <List.Item onClick={() => this.props.history.push(`/pass_rate`)}>学校推荐</List.Item>
                <List.Item onClick={() => this.props.history.push(`/teachers`)}>师资阵容</List.Item>
                <List.Item onClick={() => this.props.history.push('/trainees')}>合格学员</List.Item>
                <List.Item onClick={() => {
                    // if (!sessionStorage._t) {
                    //     Toast.info("查看考试前请先登录账号", 1, () => {
                    //         this.props.history.push('/n');
                    //     });
                    //     return;
                    // }
                    this.props.history.push('/exam')
                }}>例题展示</List.Item>
                <List.Item onClick={() => this.props.history.push(`/course`)}>精品课程</List.Item>
                <List.Item onClick={() => this.props.history.push(`/consult`)}>咨询解答</List.Item>
                <List.Item onClick={() => this.props.history.push(`/news`)}>最新动态</List.Item>
                <List.Item onClick={() => this.props.history.push(`/contact`)}>联系我们</List.Item>
                <List.Item onClick={() => {
                    if (!sessionStorage._t) {
                        this.props.history.push(`/n`);
                        return;
                    }
                    this.props.history.push(`/center`);
                }
                }>个人中心</List.Item>
            </List>
        );
        return (
            <div className={styles.container}>
                {/*边图*/}
                <div className={styles.rightBGOne}></div>
                <div className={styles.rightBGTwo}></div>
                <div className={styles.rightBGThree}></div>
                <div className={styles.leftBGOne}></div>
                <div className={styles.leftBGTwo}></div>
                <div className={styles.leftBGThree}></div>
                {/* 导航 */}
                <div className={styles.navigate}>
                    <NavBar>
                        <img src={this.state.mpng} onClick={this.onOpenChange}/>
                        <img src={Logo}/>
                        {
                            sessionStorage.getItem('_t')
                                ? null
                                : <a
                                    href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/n')}
                                ></a>
                        }
                    </NavBar>
                    <Drawer
                        position='top'
                        className="menu-drawer"
                        sidebar={sidebar}
                        open={this.state.open}
                        onOpenChange={this.onOpenChange}
                    >
                        <></>
                    </Drawer>
                </div>
                <div
                    className={styles.banner}
                    style={{display: bannerImages.length ? '' : 'none'}}>
                    {bannerImages.length > 0 && (
                        <Swiper
                            pagination={{
                                clickable: true,
                            }}
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={{
                                delay: 3000,
                                // disableOnInteraction: false
                            }}
                            // centeredSlides
                            loop
                            onSwiper={(swiper) => {
                                this.setState({swiperRef: swiper});
                            }}
                            // onSwiper={this.bindSwiperClick}
                        >
                            {
                                bannerImages.map((item: any, index) => {
                                    return (<SwiperSlide key={index}>
                                            {isImg(item.homepageImage) ?
                                                <img key={item.id} className={styles.bannerImage}
                                                     src={`${config.cdnService}${item.homepageImage}`} alt="首页"/> :
                                                <video controls muted key={item.id} className={styles.bannerImage}
                                                       src={`${config.service}/videos/${item.homepageImage}`}
                                                       onPlay={() => {
                                                           this.state.swiperRef.autoplay.pause();
                                                       }}
                                                       onPause={() => {
                                                           this.state.swiperRef.autoplay.start();
                                                       }}
                                                ></video>}
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    )}

                </div>
                {/*精品课程*/}
                <div className={styles.excellentCourse}>
                    <div className={styles.oneCourse}>
                        <img src={kcpng}/>
                        <div>精品课程</div>
                    </div>
                    <div className={styles.twoCourse}>
                        {courseTypeList.length > 0 && (
                            <Swiper
                                slidesPerView={1.465}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                centeredSlides
                                loop
                            >
                                {courseTypeList.map((courseType: ICourseType, index: number) => {
                                    return (<SwiperSlide
                                            key={courseType.id}>
                                            <div style={{width: '2.46rem', height: '1.65rem'}}>
                                                <img style={{width: '2.46rem', height: '1.65rem'}}
                                                     src={courseType.image}
                                                     onClick={() => {
                                                         this.props.history.push(`/course`)
                                                     }}
                                                />
                                            </div>
                                            <div>
                                                <div className='headCo' onClick={() => {
                                                    this.props.history.push('/course');
                                                }}>{courseType.name}</div>
                                                <div className='itemContent'>
                                                    {courseType.children.length > 0 &&
                                                        courseType.children.map((courseItem: ICourseSubType, ind: number) => {
                                                            if (ind < 8) {
                                                                return (
                                                                    <div className='itemS' key={ind} onClick={() => {
                                                                        this.props.history.push({
                                                                            pathname: '/course',
                                                                            state: {
                                                                                bigPage: courseType.id,
                                                                                bigNum: index,
                                                                                smallPage: courseItem.id,
                                                                                smNum: ind
                                                                            }
                                                                        })
                                                                    }}>
                                                                        {courseItem.name}
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        )}
                    </div>
                </div>
                {/* 师资阵容 */}
                <div className={styles.facultyLineup}>
                    <div className={styles.oneTeacher}>
                        <img src={thpng}/>
                        <div>师资力量</div>
                    </div>
                    <Carousel className="space-carousel"
                              frameOverflow="visible"
                              cellSpacing={10}
                              slideWidth={0.35}
                              dotStyle={DOT_STYLE}
                              dotActiveStyle={ACTIVE_STYLE}
                              infinite
                              beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                              afterChange={index => this.setState({slideIndex: index})}
                    >
                        {
                            teachers.map((item: ITeacher, index: number) => {
                                return <div
                                    key={item.name + item.id}
                                    className={styles.carousel}
                                >
                                    <div
                                        className={`${this.state.slideIndex === index ? styles.avatar + ' ' + styles.action : styles.avatar} `}>
                                        <img
                                            src={item.headImageUrl ? item.headImageUrl : Teacher}
                                            alt={item.name}
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: `teachersDetail`,
                                                    state: {
                                                        item: item
                                                    }
                                                })

                                            }}
                                            onError={(e: any) => {
                                                e.target.src = Teacher;
                                            }}
                                        />
                                    </div>
                                    <div className={styles.info}
                                         style={{opacity: this.state.slideIndex === index ? 1 : 0}}>
                                        <h2>{item.nick}</h2>
                                        <div style={{
                                            width: '.22rem',
                                            height: '.03rem',
                                            background: '#89C5D3',
                                            margin: '.1rem 1.56rem .1rem 1.57rem'
                                        }}></div>
                                        <h3>{item.university.replace(/\s+/g, "\n")}</h3>
                                        <h4>{item.major}</h4>
                                    </div>
                                </div>
                            })
                        }
                    </Carousel>
                    <button onClick={() => this.props.history.push('/teachers')}></button>
                </div>
                {/*合格率/合格学员*/}
                <div className={styles.passRate}>
                    <div className={styles.onePass}>
                        <img src={passpng}/>
                        <div>合格率/合格学员</div>
                    </div>
                    <div className={styles.twoPass}>
                        {homeHege.images && (
                            <Carousel
                                autoplay={false}
                                infinite
                                dotStyle={DOT_STYLE}
                                dotActiveStyle={ACTIVE_STYLE}
                                beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                                afterChange={index => console.log('slide to', index)}
                            >
                                {
                                    homeHege.images.split(',').map((item, index) => {
                                        return (<div
                                            className={styles.imgPass}
                                            key={index}
                                        >
                                            <img src={config.cdnService + item}/>
                                        </div>)
                                    })
                                }
                            </Carousel>
                        )}
                        {/*<div className={styles.imgPass}></div>*/}
                        <div className={styles.textPass}
                             dangerouslySetInnerHTML={{
                                 __html: homeHege.whychoossecontent
                             }}
                        ></div>
                    </div>
                    <div className={styles.thrPass}>
                        <Carousel
                            autoplay={false}
                            infinite
                            dotStyle={DOT_STYLE}
                            dotActiveStyle={ACTIVE_STYLE}
                            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                            afterChange={index => console.log('slide to', index)}
                        >
                            {
                                students.slice(0, 3).map((itemArr, index) => {
                                    return <div
                                        className={styles.carousel}
                                        key={index}
                                    >
                                        {
                                            itemArr.map((stuItem: IStudent) => {
                                                return <div
                                                    className={styles.stuItem}
                                                    key={stuItem.id}
                                                >
                                                    <img src={config.cdnService + stuItem.headImageUrl}
                                                         alt="学生图片"/>
                                                    <div>
                                                        <div className={styles.intro}>{stuItem.name}</div>
                                                        <div style={{
                                                            width: '.26rem',
                                                            height: '.03rem',
                                                            background: '#77C7D6'
                                                        }}></div>
                                                        <div className={styles.school}>{stuItem.collegeName}</div>
                                                        <div
                                                            className={styles.profession}>{stuItem.collegeSubject}</div>
                                                        <div className={styles.button}
                                                             onClick={() => this.props.history.push(`/trainees/${stuItem.id}`)}>
                                                            <div>学员访谈</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </Carousel>
                        <button
                            className={styles.more}
                            onClick={() => this.props.history.push('/trainees')}
                        ></button>
                    </div>
                </div>
                {/*特色服务*/}
                <div className={styles.historicalEvolution}>
                    <div className={styles.oneEvolution}>
                        <img src={elpng}/>
                        <div>特色服务</div>
                    </div>
                    <div className={styles.twoEvolution}>
                        {this.state.homeHistoryList.length > 0 && (
                            <Carousel
                                autoplay={false}
                                infinite
                                dotStyle={DOT_STYLE}
                                dotActiveStyle={ACTIVE_STYLE}
                            >
                                {
                                    this.state.homeHistoryList.map((itemArr, index) => {
                                        return <div
                                            className={styles.carousel}
                                            key={index}
                                        >
                                            {
                                                itemArr.map((history, ind) => {
                                                    return <div
                                                        className={styles.hisItem}
                                                        key={history.id}
                                                    >
                                                        <div className={styles.hisLeft}>
                                                            <img
                                                                src={require(`./../../static/img/首页/tf${index}-${ind}.png`)}
                                                                alt={history.nianfen}/>
                                                            <div style={{
                                                                width: '.3rem',
                                                                height: '.03rem',
                                                                background: '#5ACAD8',
                                                                margin: '.1rem .39rem .11rem .39rem'
                                                            }}></div>
                                                            <div className={styles.hisName}>{history.nianfen}</div>
                                                        </div>
                                                        <div className={styles.hisRight}>{history.content}</div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </Carousel>
                        )}
                    </div>
                </div>
                {/* 原创教科书 */}
                <div
                    className={styles.latestActivities}
                    style={{display: activitys.length ? '' : 'none'}}>
                    <div className={styles.oneActive}>
                        <img src={ycpng}/>
                        <div>原创教科书</div>
                    </div>
                    <Carousel
                        autoplay={false}
                        infinite
                        dotStyle={DOT_STYLE}
                        dotActiveStyle={ACTIVE_STYLE}
                        beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        afterChange={index => console.log('slide to', index)}
                    >
                        {
                            textBook.map((item: any) => {
                                return (<div
                                    className={styles.carousel}
                                    key={item.id}
                                >
                                    <img
                                        src={item.bookImageUrl}
                                        alt={item.name}
                                    />
                                </div>)
                            })
                        }
                    </Carousel>
                </div>
                {/*为什么选择东稻学院*/}
                <div className={styles.whyChoose}>
                    <div className={styles.oneWhy}>
                        <img src={whypng}/>
                        <div>为什么选择东稻学院</div>
                    </div>
                    {homeWhy.images && (
                        <Carousel
                            autoplay={false}
                            infinite
                            dotStyle={DOT_STYLE}
                            dotActiveStyle={ACTIVE_STYLE}
                            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                            afterChange={index => console.log('slide to', index)}
                        >
                            {
                                homeWhy.images.split(',').map((item, index) => {
                                    return (<div
                                        className={styles.carousel}
                                        key={index}
                                    >
                                        <img src={config.cdnService + item}/>
                                    </div>)
                                })
                            }
                        </Carousel>
                    )}
                </div>
                {/* 最新活动 */}
                <div className={styles.newActivities}
                     style={{display: activitys.length ? '' : 'none'}}>
                    <div className={styles.oneNew}>
                        <img src={newpng}/>
                        <div>最新活动</div>
                    </div>
                    <Carousel
                        autoplay={false}
                        infinite
                        dotStyle={DOT_STYLE}
                        dotActiveStyle={ACTIVE_STYLE}
                        beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        afterChange={index => console.log('slide to', index)}
                    >
                        {
                            newsList.map((item) => {
                                return (<div
                                    className={styles.carousel}
                                    key={item.id}
                                >
                                    <img
                                        src={item.dynamicImage}
                                        alt="活动"
                                        onClick={() => {
                                            this.props.history.push(`/news/${item.id}`)
                                        }}
                                    />
                                    <div className={styles.title} onClick={() => {
                                        this.props.history.push(`/news/${item.id}`)
                                    }}>{item.title}</div>
                                </div>)
                            })
                        }
                    </Carousel>
                </div>
                {/* 校区地址 */}
                <div className={styles.businessOutlets}>
                    <div className={styles.oneBusiness}>
                        <img src={hdpng}/>
                        <div>校区地址</div>
                    </div>
                    <Swiper
                        slidesPerView={1.242}
                        spaceBetween={19}
                        pagination={{
                            clickable: true,
                        }}
                        centeredSlides
                        loop
                    >
                        {
                            addressList.map((item: IAddressItem) => {
                                return <SwiperSlide
                                    key={item.id}
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/contact',
                                            state: {
                                                id: item.id
                                            }
                                        })
                                    }}
                                >
                                    <img style={{width: '2.98rem', height: '1.33rem'}} src={item.campusImage}
                                         alt="合作地点"/>
                                    <div className={styles.content}>
                                        <div style={{
                                            width: '.05rem',
                                            height: '.19rem',
                                            background: '#77C7D6',
                                            float: 'left'
                                        }}></div>
                                        <div className={styles.title}>
                                            <div className={styles.yuanxiao}>{item.name}</div>
                                            <div className={styles.address}>{item.address}</div>
                                            <div className={styles.tel}>咨询电话：{item.onlinePhone}</div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                </div>
                {/* 合作伙伴 */}
                <div className={styles.cooperativePartner}>
                    <div className={styles.onePartner}>
                        <div className={styles.one}>合作伙伴</div>
                        <div className={styles.two}>パートナー</div>
                    </div>
                    <div className={styles.twoPartner}>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={3}
                            onSwiper={(swiper) => {
                                this.setState({swiperRef2: swiper});
                            }}
                            loop
                        >
                            {this.state.ptrs.length > 0 && this.state.ptrs.map((item: IPartner) => {
                                return <SwiperSlide key={item.id}>
                                    <img src={config.cdnService + item.imageUrl} alt="合作伙伴图片"/>
                                </SwiperSlide>
                            })}
                        </Swiper>
                        {/*{this.state.ptrs.length > 0 && this.state.ptrs.map((item: IPartner) => {*/}
                        {/*    return <div className={styles.pic} key={item.id} onTouchMove={() => {*/}
                        {/*        let tmp = partners.shift() as IPartner;*/}
                        {/*        partners.push(tmp);*/}
                        {/*        this.setState({ptrs: partners});*/}
                        {/*    }}>*/}
                        {/*        <img src={config.cdnService + item.imageUrl} alt="合作伙伴图片"/>*/}
                        {/*    </div>*/}
                        {/*})}*/}
                    </div>
                    <div className={styles.thrPartner}>
                        <div className={styles.bp}
                             onClick={() => {
                                 this.state.swiperRef2.slideNext();
                                 // let tmp = partners.shift() as IPartner;
                                 // partners.push(tmp);
                                 // this.setState({ptrs: partners});
                             }}
                        ><img src={rgpng}/></div>
                    </div>
                </div>
                <div style={{
                    width: '3.75rem',
                    height: '.09rem',
                    background: '#EEF7FF'
                }}></div>
                {/*底部*/}
                <div className={styles.footer}>
                    <img className={styles.fzzpng} src={fzzpng}/>
                    <div className={styles.title}>
                        <img style={{position: 'relative'}} src={logs}/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.right}><img src={qqpng}/>
                            <div>{customer.qq}</div>
                        </div>
                        <div className={styles.right}><img src={dzpng}/>
                            <div>{customer.customerPhone}</div>
                        </div>
                        <div className={styles.right}><img src={telpng}/>
                            <div>{customer.address}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        introduce: state.introduce,
        addressList: state.address.addressList,
        courseTypeList: state.course.courseTypeList,
        newsList: state.news.newsList,
        customer: state.customer.customer
    }
}

const mapDispatchToProps = {
    getPlatformIntro,
    getAllCourseTypeList,
    getAllNewsList,
    getAllHome,
    getCustomer,
    getBannerImage,
    getAllAddressList,
    getActivityList,
    getTextBookList,
    getPartnerList,
    getStudentList,
    getTeacherList,
    Getpassrate
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
