/**
 * 个人中心首页
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/center/center.module.scss';
import logo from './../../static/img/logo.png';
import { List, Button } from 'antd-mobile';
import './../../styles/center/antd_modify.scss';
import Navigate from './../../components/common/navigate/navigate';
import {
  getUserInfo,
  IUserInfo
} from './../../redux/mycenter.reducer';
import { user } from '../../redux/user.reducer';


const Item: React.ElementType = List.Item;

export interface IMyCenterProps {
  history: any,
  getUserInfo: any
  store: any,
  userInfo: IUserInfo
}

export interface IMyCenterState {
  name: number,
  tableData: ITableListItem[],
  userInfo: IUserInfo
}


interface ITableListItem {
  icon: string;
  content: string;
  color: string;
  path: string;
  onClick(e?: Event): void;
}

const tableDataGeneral: ITableListItem[] = [
  {
    icon: 'iconpinggu',
    content: '能力评估',
    color: '#ECC12A',
    path: '/center/capacity_assess',
    onClick() {
    }
  },
  {
    icon: 'iconkechengguanli',
    content: '我的课程',
    color: '#5A77F2',
    path: '/center/my_course',
    onClick() { }
  },
  {
    icon: 'iconxueyuan-kaoshi1-copy',
    content: '我的模考',
    color: '#F2815A',
    path: '/center/my_exam',
    onClick() { }
  },

  {
    icon: 'icontubiao313',
    content: '我的消息',
    color: '#F36E6B',
    path: '/center/msg_list',
    onClick() { }
  },
  {
    icon: 'icondrxx66',
    content: '意见反馈',
    color: '#7EC4FF',
    path: '/center/feedback',
    onClick() { }
  },
  {
    icon: 'iconiconfontanquan',
    content: '账户安全',
    color: '#60C991',
    path: '/center/n_as',
    onClick() { }
  },
  {
    icon: 'iconmoban',
    content: '关于我们',
    color: '#F36E6B',
    path: '/contact',
    onClick() { }
  },
  {
    icon: 'iconweixin',
    content: '微信公众号',
    color: '#6AA468',
    path: '/center/weixin',
    onClick() { }
  },
];

const tableDataTeacher: ITableListItem[] = [
  {
    icon: 'iconkechengguanli1',
    content: '课程管理',
    color: 'rgba(77, 182, 255, 1)',
    path: '/center/course_management',
    onClick() {
    }
  },
  {
    icon: 'iconkaoqin',
    content: '勤务管理',
    color: 'rgba(243, 110, 107, 1)',
    path: '/center/service_management',
    onClick() { }
  },
  {
    icon: 'iconxueyuan-kaoshi1-copy',
    content: '费用报销',
    color: 'rgba(242, 129, 90, 1)',
    path: '/center/reimbursement',
    onClick() { }
  },

  {
    icon: 'iconchat',
    content: '我的咨询',
    color: 'rgba(96, 201, 145, 1)',
    path: '/center/consult_list',
    onClick() { }
  },
  {
    icon: 'icontubiao313',
    content: '我的消息',
    color: 'rgba(243, 110, 107, 1)',
    path: '/center/msg_list',
    onClick() { }
  },
  {
    icon: 'icondrxx66',
    content: '意见反馈',
    color: 'rgba(126, 196, 255, 1)',
    path: '/center/feedback',
    onClick() { }
  },
  {
    icon: 'iconiconfontanquan',
    content: '账户安全',
    color: 'rgba(96, 201, 145, 1)',
    path: '/center/t_as',
    onClick() { }
  },
  {
    icon: 'iconmoban',
    content: '关于我们',
    color: 'rgba(243, 110, 107, 1)',
    path: '/contact',
    onClick() { }
  },
  {
    icon: 'iconweixin',
    content: '微信公众号',
    color: '#6AA468',
    path: '/center/weixin',
    onClick() { }
  },
];

class MyCenter extends React.Component<IMyCenterProps, IMyCenterState> {
  constructor(props: IMyCenterProps) {
    super(props);

    this.state = {
      name: 123,
      tableData: [],
      userInfo: {
        userName: '',
        phone: '',
        wxCode: '',
        headImageUrl: logo,
        nick: '',
        sex: '',
        description: '',
        id: -1
      }
    };
  }

  componentDidMount() {
    const IS_TEACHER: Boolean = sessionStorage.getItem('isTeacher') === 'true';
    if (IS_TEACHER) {
      this.setState({ tableData: tableDataTeacher });
    } else {
      this.setState({ tableData: tableDataGeneral });
    }
    this.props.getUserInfo(IS_TEACHER).then(
      (res: any) => {
        const USER_INFO: IUserInfo = {
          userName: res.data.name ? res.data.name : '用户',
          phone: res.data.phone ? res.data.phone : '未绑定',
          wxCode: res.data.wxCode ? res.data.wxCode : '未绑定',
          headImageUrl: res.data.headImageUrl ? res.data.headImageUrl : logo,
          nick: res.data.nick ? res.data.nick : '',
          sex: res.data.sex === 1 ? '男' : res.data.sex === 2 ? '女' : '',
          description: res.data.says ? res.data.says : '',
          id: res.data.id,
        };
        if (res.success && res.data) {
          this.setState({
            userInfo: USER_INFO
          });
        }
      }
    );
  }

  goPersonalConfig = () => {
    const IS_TEACHER: Boolean = sessionStorage.getItem('isTeacher') === 'true';
    if (IS_TEACHER) {
      this.props.history.push('/center/t_person');
    } else {
      this.props.history.push('/center/personal');
    }
  }

  public render() {
    const tableList: React.ReactElement[] = this.state.tableData.map((tableItem, indexe) => {
      return (
        <Item arrow="horizontal" key={indexe.toString()} onClick={() => this.props.history.push(tableItem.path)}>
          <i className={`iconfont ${tableItem.icon}`} style={{ color: tableItem.color }}></i><span className='listIeam'>{tableItem.content}</span>
        </Item>
      )
    });

    const { userInfo } = this.props;

    return (
      <div className={`${styles.myCenterContainer} myCenterContainer`}>
        <div className={styles.header}>
          <div className={styles.avatarWrap}>
            <img src={this.state.userInfo.headImageUrl} alt='加载失败' />
          </div>
          <div className={styles.userInfo} onClick={() => { this.goPersonalConfig() }}>
            <p className={styles.userName}>{userInfo.nick}<i className={`iconfont ${this.state.userInfo.sex === '男'?`iconxingbie-nan ${styles.sexMale}`:`iconxingbie-nv ${styles.sexFemale}`} ${styles.sexIcon}`}></i></p>
            <p className={styles.movingCommunication}>
              <i className='iconfont iconxingbie-nan' style={{ color: '#7ECAD8' }} />
              &nbsp;&nbsp;
              <span>{userInfo.phone}</span>
              &nbsp;&nbsp;
              {typeof this.state.userInfo.wxCode !== 'string' ? '' : <i className='iconfont iconweixin1' style={{ color: '#50b674' }} />}
              &nbsp;&nbsp;
              <span className={styles.wxCode}>{this.state.userInfo.wxCode}</span>
            </p>
          </div>
        </div>
        <div className={`${styles.functionList} functionList`}>
          <List>
            {tableList}
          </List>
        </div>
        <div className={styles.buttonWrap}>
          <Button type='primary' inline className='fullBtn' onClick={() => { this.props.history.push('/n');sessionStorage.clear()}}>退出登录</Button>
        </div>
        <div className={styles.navigate}>
          <Navigate type='white'></Navigate>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCenter);