/**
 * 快速练习解析 
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/exam/practise_analysis.module.scss';
import './../../styles/center/antd_modify.scss';
import { Button } from 'antd-mobile';
import { RouteComponentProps } from 'react-router-dom';
import { IExamStoreState, setPractiseAnwser, IPractiseTopicItem, practiseSubmit } from '../../redux/exam.reducer';

export interface IPracitseAnalysisProps extends RouteComponentProps {
  match: any,
  exam: IExamStoreState
}

export interface IPracitseAnalysisState {
  activeAnswerCard: boolean,
  currentQuestionIndex: number
}

class PracitseAnalysis extends React.Component<IPracitseAnalysisProps, IPracitseAnalysisState> {
  private questionIndex: number;
  constructor(props: IPracitseAnalysisProps) {
    super(props);
    this.questionIndex = props.match.params.questionIndex;
    this.state = {
      activeAnswerCard: false,
      currentQuestionIndex: this.questionIndex
    }
  }

  componentDidMount() {
    if (!this.props.exam.practiseTopicResult.questions.length) {
      this.props.history.goBack()
    }
  }

  public render() {
    const questions = this.props.exam.practiseTopicResult.questions;
    const { currentQuestionIndex } = this.state;
    const answer = questions[currentQuestionIndex].answer
    const submit = questions[currentQuestionIndex].submit

    console.log(answer === "A" && submit === "A" ? styles.rightOption : submit === "A" ? styles.errorOption : answer === "A" ? styles.wrongOption : "")
    return (
      <div className={styles.examAnalyseContainer}>
        <div className={styles.title}>
          <p>(单选题)</p>
          <p><span className={styles.currentQuestionNumber}>{Number(currentQuestionIndex)+1}</span>/{questions.length}</p>
        </div>
        <div className={styles.question}>
          <p className={styles.questionContent}>{questions[currentQuestionIndex].stem}</p>
          <div className={styles.options}>
            <ul>
              <li className={`${styles.option} ${answer === "A" && submit === "A" ? styles.rightOption : submit === "A" ? styles.errorOption : answer === "A" ? styles.wrongOption : ""}`}>
                <p className={styles.optionSign}>A</p>
                <p className={styles.optionContent}>{questions[currentQuestionIndex].optiona}</p>
              </li>
              <li className={`${styles.option} ${answer === "B" && submit === "B" ? styles.rightOption : submit === "B" ? styles.errorOption : answer === "B" ? styles.wrongOption : ""}`}>
                <p className={styles.optionSign}>B</p>
                <p className={styles.optionContent}>{questions[currentQuestionIndex].optionb}</p>
              </li>
              <li className={`${styles.option} ${answer === "C" && submit === "C" ? styles.rightOption : submit === "C" ? styles.errorOption : answer === "C" ? styles.wrongOption : ""}`}>
                <p className={styles.optionSign}>C</p>
                <p className={styles.optionContent}>{questions[currentQuestionIndex].optionc}</p>
              </li>
              <li className={`${styles.option} ${answer === "D" && submit === "D" ? styles.rightOption : submit === "D" ? styles.errorOption : answer === "D" ? styles.wrongOption : ""}`}>
                <p className={styles.optionSign}>D</p>
                <p className={styles.optionContent}>{questions[currentQuestionIndex].optiond}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.separatorLine}></div>
        <div className={styles.answer}>
          <p style={{ paddingBottom: '.20rem' }}>正确答案：<span>{questions[this.questionIndex].answer}</span></p>
          <p>答案解析：<span>{questions[this.questionIndex].analysis}</span></p>
        </div>
        <div className={styles.handle}>
          <Button className="ghostButton" type="ghost" inline onClick={() => this.setState({ activeAnswerCard: true })}>答题卡</Button>
          <Button className="ghostButton" type="ghost" inline onClick={() => window.history.back()}>返回查看答题结果</Button>
        </div>
        <div className={`${styles.answerCardWrap} ${this.state.activeAnswerCard ? styles.active : ''}`}>
          <div className={styles.whiteBlock} onClick={() => this.setState({ activeAnswerCard: false })}></div>
          <div className={styles.answerCard}>
            <h3>答题卡</h3>
            <ul className={styles.opsiontsWrap}>
              {
                questions.map((item: IPractiseTopicItem, index: number) => {
                  return <li
                    key={item.id}
                    onClick={() => {
                      this.setState({
                        currentQuestionIndex: index,
                        activeAnswerCard: false
                      })
                    }}
                    className={styles.options}
                  >
                    <p
                      className={
                        item.submit
                          ? item.answer === item.submit
                            ? styles.submit
                            : styles.errorOption
                          : styles.warningOption
                      }
                    >{index + 1}</p>
                  </li>
                })
              }
            </ul>
            {/* <ul className={styles.opsiontsWrap}>
              <li className={styles.options}>
                <p className={styles.errorOption}>1</p>
              </li>
              <li className={styles.options}>
                <p className={styles.warningOption}>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
              <li className={styles.options}>
                <p>1</p>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    exam: state.examStore,
  }
}

const mapDispatchToProps = {
  setPractiseAnwser,
  practiseSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(PracitseAnalysis);