/**
 * 联系我们
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../styles/contact/contact.module.scss';
import { IAddressItem } from '../../redux/contact.reducer';
export interface ISchoolItem {
  address: string
  id: string
  locationImage: string
  name: string
  onlinePhone: string
  onlineqq: string
}
export interface IContactProps {
  addressList:IAddressItem[]
}

export interface IContactState {
  currSelectedSchoolIndex: number,
  schoolAddresses: ISchoolItem[]
}
class Contact extends React.Component<IContactProps, IContactState> {
  constructor(props: IContactProps) {
    super(props);
    console.log(props)
    this.state = {
      currSelectedSchoolIndex: 0,
      schoolAddresses: props.addressList,
    }
  }

  componentDidMount() {
    // this.getAddresses()
  }

  public render() {
    const { currSelectedSchoolIndex, schoolAddresses } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>联系我们</h1>
          <h2>お問い合わせ</h2>
        </div>
        <ul className={styles.addrList}>
          {
            schoolAddresses.map((item: ISchoolItem, index: number) => {
              return <li
                key={item.id}
                onClick={() => this.setState({ currSelectedSchoolIndex: index })}
                className={index === currSelectedSchoolIndex ? styles.active : ''}
              >{item.name}</li>
            })
          }
        </ul>
        {
          schoolAddresses.length
            ? <div className={styles.contactContent}>
              <div className={styles.contact}>
                <div>
                  <i className="iconfont iconweizhi"></i>
                  <p>{schoolAddresses[currSelectedSchoolIndex].address}</p>
                </div>
                <div>
                  <i className="iconfont icondianhua"></i>
                  <p>{schoolAddresses[currSelectedSchoolIndex].onlinePhone}</p>
                </div>
                <div>
                  <i className="iconfont iconqq"></i>
                  <p>{schoolAddresses[currSelectedSchoolIndex].onlineqq}</p>
                </div>
              </div>
              <div className={styles.mapBox}>
                <div className={styles.map}>
                  <img
                    src={schoolAddresses[currSelectedSchoolIndex].locationImage}
                    alt={schoolAddresses[currSelectedSchoolIndex].name}
                  />
                </div>
              </div>
            </div>
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state:any)=>{
  return {
    addressList:state.address.addressList
  }
}

const matDispatchToProps = {

}


export default connect(mapStateToProps,matDispatchToProps)(Contact);
