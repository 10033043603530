/**
 * 忘记密码
 */

import * as React from 'react';
import { Button } from 'antd-mobile';
import styles from './../../styles/login/login.module.scss';
import logo from './../../static/img/logo.png';

export interface IForgetProps {
  history: any
}

export interface IForgetState {
  step: number
}

export default class Forget extends React.Component<IForgetProps, IForgetState> {
  state: IForgetState = {
    step: 0
  }

  handleClickNext = () => {
    this.setState({
      step: 1
    })
  }

  public render() {
    const { step } = this.state;
    return (
      <div className={styles.loginContainer}>
        <div
          style={{ height: step === 0 ? 'auto' : '.14rem' }}
          className={styles.header}
        >
          {
            step === 0 ?
              <React.Fragment>
                <span
                  onClick={() => this.props.history.push('/')}
                  className={styles.logon}
                >登录</span>
                <span
                  onClick={() => this.props.history.push('/t')}
                  className={styles.enteranceSwitch}
                >教师用户登录入口</span>
              </React.Fragment>
              : ''
          }
        </div>
        <div className={styles.logoWrap}>
          <img src={logo} alt="" />
        </div>

        {
          step === 0
            ? <div className={styles.loginWrap}>
              <div className={styles.formGroup}>
                {/* 快速登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="text"
                    placeholder="请输入验证码"
                  />
                  <button>获取验证码</button>
                </div>
              </div>

              <div className={styles.btnContainer}>
                <Button
                  onClick={this.handleClickNext}
                  type="primary"
                >下一步</Button>
              </div>
            </div>
            : <div className={styles.loginWrap}>
              <div className={styles.formGroup}>
                {/* 快速登录 */}
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconzhanghu"></i>
                  </p>
                  <input
                    type="password"
                    placeholder="请输入密码"
                  />
                </div>
                <div className={styles.formItem}>
                  <p>
                    <i className="iconfont iconmima"></i>
                  </p>
                  <input
                    type="password"
                    placeholder="请再次输入密码"
                  />
                </div>
              </div>

              <div className={styles.btnContainer}>
                <Button type="primary">确认</Button>
              </div>
            </div>
        }
      </div>
    );
  }
}
