/* 
快速练习答题结果
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styles from './../../styles/exam/practise_result.module.scss';
import { IExamStoreState, setPractiseAnwser, IPractiseTopicItem, practiseSubmit } from '../../redux/exam.reducer';
// import $ from './../../util/requst';

export interface IPractiseResultProps extends RouteComponentProps {
  exam: IExamStoreState
}

export interface IPractiseResultState {
  activeAnswerCard: boolean
}

class PractiseAnalyse extends React.Component<IPractiseResultProps, IPractiseResultState> {
  constructor(props: IPractiseResultProps) {
    super(props);

    this.state = {
      activeAnswerCard: false
    }
  }

  public render() {
    const { exam } = this.props;
    const practiseResult = exam.practiseTopicResult;
    const {
      total,
      awser,
      notawser,
      ok,
      mistake,
      questions,
      title
    } = practiseResult;
    return (
      <div className={styles.container}>
        <div className={styles.overview}>
          <h1>{title}</h1>
          <p>答对：<span>{ok}</span> 题</p>
        </div>
        <div className={styles.result}>
          <h1>练习情况</h1>
          <p>共{total}道题，正确<span>{ok}</span>题，错误<b>{mistake}</b>题，未答<i>{notawser}</i>题</p>
        </div>
        <div className={styles.practise}>
          <h1>答题解析</h1>
          <ul>
            {
              questions.map((item: IPractiseTopicItem, index: number) => {
                return <li
                  onClick={() => this.props.history.push(`/exam/practise_analysis/${index}`)}
                  className={
                    item.submit
                      ? item.answer === item.submit
                        ? styles.submit
                        : styles.errorOption
                      : styles.warningOption
                  }
                  key={index}
                >{index+1}</li>
              })
            }

            <li
              // onClick={() => this.props.history.push('/exam/practise_analysis')}
              className={styles.empty}
            // key={index}
            >1</li>
          </ul>
          <button onClick={() => this.props.history.push('/exam')}>返回模考主页</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    exam: state.examStore,
  }
}

const mapDispatchToProps = {
  setPractiseAnwser,
  practiseSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(PractiseAnalyse);