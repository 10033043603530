/**
 * 个人信息
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { List, Toast, Picker } from 'antd-mobile';
import styles from './../../../styles/center/t_person.module.scss';
import logo from './../../../static/img/logo.png';
import './../../../styles/center/antd_modify.scss';
import Navigate from './../../../components/common/navigate/navigate';
import {
  getUserInfo,
  IUserInfo,
  uploadFile,
  updateUserInfo
} from './../../../redux/mycenter.reducer';
import config from './../../../config/app.config';

const Item: React.ElementType = List.Item;



export interface IPersonProps {
  isTeacher: boolean;
  history: any,
  getUserInfo: any,
  uploadFile: any
  updateUserInfo: any
  userInfo: any
}

export interface IPersonState {
  userInfo: IUserInfo
  sex: any
}

class TeacherPerson extends React.Component<IPersonProps, IPersonState> {
  constructor(props: IPersonProps) {
    super(props);
    this.state = {
      userInfo: {
        userName: '',
        phone: '',
        wxCode: '',
        headImageUrl: logo,
        nick: '',
        sex: '',
        description: '',
        id: -1
      },
      sex: []
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const isTeacher = sessionStorage.getItem('isTeacher') === 'true';
    this.props.getUserInfo(isTeacher).then(
      (res: any) => {
        if (res.success && res.data) {
          let userInfo = res.data;
          this.setState({
            userInfo: {
              userName: userInfo.userName ? userInfo.userName : '用户',
              phone: userInfo.phone ? userInfo.phone : '未绑定',
              wxCode: userInfo.wxcode ? userInfo.wxcode : '未绑定',
              headImageUrl: userInfo.headImageUrl ? userInfo.headImageUrl : logo,
              nick: userInfo.nick ? userInfo.nick : '',
              sex: userInfo.sex === 1 ? '男' : userInfo.sex === 2 ? '女' : '',
              description: userInfo.says ? userInfo.says : '',
              id: userInfo.id
            },
            sex: [`${userInfo.sex}`],
          });
          console.log(this.state.userInfo.sex)
        }
      }
    );
  }

  handleUploadHeadImage = (e: any) => {
    let data = {
      file: e.target.files[0]
    };
    this.props.uploadFile(data).then(
      (res: any) => {
        if (res.code === 200) {
          let userInfo = this.props.userInfo;
          userInfo.headImageUrl = `${config.cdnService}${res.data}`;
          this.props.updateUserInfo(userInfo).then(
            (res: any) => {
              if (res.success) {
                Toast.info('上传成功');
                this.init();
              } else {
                Toast.info(res.message);
              }
            }
          );
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  handleChangeSex = (v: any) => {
    this.setState({ sex: v });
    let userInfo = this.props.userInfo;
    userInfo.sex = v[0];
    this.props.updateUserInfo(userInfo).then(
      (res: any) => {
        if (res.success) {
          Toast.info('修改成功');
          this.init();
        } else {
          Toast.info(res.message);
        }
      }
    );
  }

  getTeacherIntro = () => {

  }

  public render() {
    let introOrWechat: React.ReactElement;
    if (this.props.isTeacher) {
      introOrWechat = <Item arrow='horizontal' onClick={this.getTeacherIntro()}>简介</Item>
    } else {
      introOrWechat = <Item extra={this.state.userInfo.wxCode} onClick={() => this.props.history.push('/center/modify_wx')}>微信账号</Item>;
    }
    const SEX_LSIT: any[] = [
      {
        label: '女',
        value: '2',
      },
      {
        label: '男',
        value: '1',
      },
    ]
    return (
      <div className={`${styles.personContainer} personContainer`}>
        <div className={styles.header}>
          <p>个人信息</p>
        </div>
        <div className={styles.functionList}>
          <List>
            <Item extra={<div><input className={styles.headImageUpload}
              accept='image/*' type="file"
              onChange={(e) => { this.handleUploadHeadImage(e) }}
            /><img className='personAvatra' src={this.state.userInfo.headImageUrl} alt='error' /></div>}>个人头像</Item>
            <Item extra={this.state.userInfo.nick} onClick={() => this.props.history.push('/center/modify_name')}>姓名</Item>
            {/* <Item extra={this.state.userInfo.sex}>性别</Item> */}
            <Picker extra={this.state.userInfo.sex}
              data={SEX_LSIT}
              title="性别"
              cols={1}
              value={this.state.sex}
              onChange={v => this.handleChangeSex(v)}
            >
              <List.Item arrow="down">性别</List.Item>
            </Picker>
            <Item extra={this.state.userInfo.phone} onClick={() => this.props.history.push('/center/t_mp')}>手机号</Item>
            {introOrWechat}
            <Item arrow='horizontal' onClick={() => this.props.history.push('/center/teacher_intros')}>简介</Item>
            <Item arrow='horizontal' onClick={() => this.props.history.push('/center/t_as')}>账号安全</Item>
          </List>
        </div>
        <div className={styles.navigate}>
          <Navigate type='white'></Navigate>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.myCenter.userInfo
  }
}

const mapDispatchToProps = {
  getUserInfo,
  uploadFile,
  updateUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherPerson);
