/**
 * 我的消息 列表
 */

import * as React from 'react';
import { connect } from 'react-redux';
import styles from './../../../styles/center/msg_list.module.scss';
import {
  getMessageList,
  getMessageDetails
} from './../../../redux/mycenter.reducer';

interface ImessageByData {
  [key: string]: string
}
export interface IMsgListProps {
  history: any,
  getMessageList: any
  getMessageDetails: any
}

export interface IMsgListState {
  messageList: [],
  messagByDate: any
}



class MsgList extends React.Component<IMsgListProps, IMsgListState> {
  constructor(props: IMsgListProps) {
    super(props);

    this.state = {
      messageList: [],
      messagByDate: {}
    }
  }

  componentDidMount() {
    this.props.getMessageList().then(
      (res: any) => {
        let data = res.data;
        if (res.success && data) {
          this.setState({ messageList: res.data });
          let messageByDate: any = {};
          data.map((item: any) => {
            item.date = item.pushDate.split(' ')[0];
            messageByDate[item.date] = [];
          });
          data.map((item: any) => {
            messageByDate[item.date].push(item);
          });
          this.setState({ messagByDate: messageByDate });
        }
      }
    );
  }

  getMessageDetail = (message: any) => {
    this.props.getMessageDetails(message);
    this.props.history.push('/center/msg_details')
  }

  public render() {
    return (
      <div className={styles.msgListContainer}>
        <div className={styles.header}>
          <h1>我的消息</h1>
        </div>
        <div className={styles.content}>
          <ul>
            {
              Object.keys(this.state.messagByDate).map(
                (item: any, index: number) => {
                  return (<li className={styles.msgDateWrap} key={index}>
                    <div className={styles.msgDate}>
                      <p>{item}</p>
                    </div>
                    <ul>
                      {
                        this.state.messagByDate[item].map(
                          (queryItem: any) => {
                            return <li className={styles.msg} key={queryItem.id}>
                              <div className={styles.msgIconWrap}>
                                <i className="iconfont iconlingdangdaiquan"></i>
                              </div>
                              <div className={styles.msgInfoWrap} onClick={() => { this.getMessageDetail(queryItem) }}>
                                <h3>{queryItem.title}</h3>
                                <p>{queryItem.message}</p>
                              </div>
                            </li>
                          }
                        )
                      }
                    </ul>
                  </li>)
                }
              )}
          </ul>
          {/* <ul>
            <li className={styles.msgDateWrap}>
              <div className={styles.msgDate}>
                <p>2019.06.11</p>
              </div>
              <ul>
                <li className={styles.msg}>
                  <div className={styles.msgIconWrap}>
                    <i className="iconfont iconlingdangdaiquan"></i>
                  </div>
                  <div className={styles.msgInfoWrap} onClick={() => this.props.history.push('/center/msg_details')}>
                    <h3>[订单消息] 消息题目XXXXXXXXXXXX</h3>
                    <p>通知内容：尊敬的用户您好，618活动课程，全场7折起！全场7折起！</p>
                  </div>
                </li>
              </ul>
            </li>
            <li className={styles.msgDateWrap}>
              <div className={styles.msgDate}>
                <p>2019.06.11</p>
              </div>
              <ul>
                <li className={styles.msg}>
                  <div className={styles.msgIconWrap}>
                    <i className="iconfont iconlingdangdaiquan"></i>
                  </div>
                  <div className={styles.msgInfoWrap} onClick={() => this.props.history.push('/center/msg_details')}>
                    <h3>[订单消息] 消息题目XXXXXXXXXXXX</h3>
                    <p>通知内容：尊敬的用户您好，618活动课程，全场7折起！全场7折起！</p>
                  </div>
                </li>
                <li className={styles.msg}>
                  <div className={styles.msgIconWrap}>
                    <i className="iconfont iconlingdangdaiquan"></i>
                  </div>
                  <div className={styles.msgInfoWrap} onClick={() => this.props.history.push('/center/msg_details')}>
                    <h3>[订单消息] 消息题目XXXXXXXXXXXX</h3>
                    <p>通知内容：尊敬的用户您好，618活动课程，全场7折起！全场7折起！</p>
                  </div>
                </li>
                <li className={styles.msg}>
                  <div className={styles.msgIconWrap}>
                    <i className="iconfont iconlingdangdaiquan"></i>
                  </div>
                  <div className={styles.msgInfoWrap} onClick={() => this.props.history.push('/center/msg_details')}>
                    <h3>[订单消息] 消息题目XXXXXXXXXXXX</h3>
                    <p>通知内容：尊敬的用户您好，618活动课程，全场7折起！全场7折起！</p>
                  </div>
                </li>
              </ul>
            </li>
          </ul> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
  }
}

const mapDispatchToProps = {
  getMessageList,
  getMessageDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(MsgList);
