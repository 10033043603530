/**
 * 咨询列表
 */
import * as React from 'react';
import styles from './../../../styles/center/faqs_details.module.scss';
import { Button } from 'antd-mobile';

export interface IFaqsDetailsProps {
}

export interface IFaqsDetailsState {
}

export default class ConsultList extends React.Component<IFaqsDetailsProps, IFaqsDetailsState> {
  constructor(props: IFaqsDetailsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className={styles.faqsDetailsContainer}>
        <div className={styles.faq}>
          <div className={styles.question}>
            <h1>[咨询消息] 消息标题</h1>
            <p className={styles.date}>2019-06-11&nbsp;&nbsp;17:00:00</p>
            <p className={styles.questionInfo}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic</p>
          </div>
          <div className={styles.answer}>
            <p>回答内容：</p>
            <textarea className={styles.textarea} placeholder="请输入咨询回答内容" />
          </div>
          <div className={styles.buttonWrap}>
            <Button type='primary' inline className='fullBtn'>确认回复</Button>
          </div>
        </div>
      </div>
    );
  }
}
